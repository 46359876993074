<template>
  <div>
    <vs-row vs-type="flex" vs-justify="center" vs-align="center">
      <vs-col vs-w="2"> </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-align="center" vs-justify="center">
      </vs-col>
      <vs-col vs-w="2" vs-type="flex" vs-align="center" vs-justify="center">
        <div class="mx-1" v-if="isEditing">
          <vs-button
            icon="edit"
            type="relief"
            color="warning"
            size="small"
            @click="submitForm()"
          >
            Salvar
          </vs-button>
        </div>
        <div class="mx-1" v-else>
          <vs-button
            icon="add"
            type="relief"
            color="success"
            size="small"
            @click="submitForm()"
          >
            Adicionar
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex" vs-align="center" vs-justify="flex-start" vs-w="12">
      <vs-col vs-w="3" vs-type="flex" vs-align="center" vs-justify="center">
        <div class="mx-3 w-full">
          <vs-row>
            <vs-col vs-w="12">
              <label style="color: black; font-size: 0.85rem" class="pl-2">
                Tipo do Dispositivo
              </label>
              <el-select
                v-model="device.id_dispositivo_tipo"
                v-validate="'required'"
                filterable
                :popper-append-to-body="false"
                name="id_device_type"
                class="w-full"
                @change="setConsumedOptionValue()"
              >
                <el-option
                  v-for="deviceType in deviceTypes"
                  :key="deviceType.id"
                  :label="deviceType.nome"
                  :value="deviceType.id"
                >
                </el-option>
              </el-select>
            </vs-col>
            <vs-col vs-w="12">
              <span
                class="text-danger text-sm"
                v-show="errors.has('id_device_type')"
              >
                {{ $validators.empty }}
              </span>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <vs-col vs-w="3" vs-type="flex" vs-align="center" vs-justify="center">
        <div class="mx-3 w-full">
          <vs-row vs-type="flex" vs-align="center" vs-justify="center">
            <vs-col vs-w="12">
              <label style="color: black; font-size: 0.85rem" class="pl-2">
                Área
              </label>
              <el-select
                v-model="device.id_area"
                v-validate="'required'"
                name="id_area"
                class="w-full"
                :popper-append-to-body="false"
                filterable
              >
                <el-option
                  v-for="area in areas"
                  :key="area.id"
                  :label="area.nome"
                  :value="area.id"
                >
                </el-option>
              </el-select>
            </vs-col>
            <vs-col vs-w="12">
              <span class="text-danger text-sm" v-show="errors.has('id_area')">
                {{ $validators.empty }}
              </span>
            </vs-col>
          </vs-row>
        </div>
        <br />
      </vs-col>

      <vs-col
        vs-w="3"
        vs-type="flex"
        vs-align="center"
        vs-justify="center"
        v-if="!isEditing"
      >
        <vs-input
          class="mx-3 w-full"
          color="success"
          v-validate="'required|numeric'"
          label="Quantidade"
          min="1"
          type="number"
          v-model="device.quantidade"
          name="deviceQuantity"
        />
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  props: {
    device: Object,
    areas: Array,
    deviceTypes: Array,
    isEditing: Boolean
  },

  data() {
    return {
      usedOpts: [
        { value: 1, label: "Sim" },
        { value: 0, label: "Não" }
      ]
    };
  },

  async mounted() {},

  methods: {
    async submitForm() {
      return new Promise(() => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.save();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },

    setConsumedOptionValue() {
      if (!this.device.id_dispositivo_tipo) {
        return false;
      }

      const deviceType = this.deviceTypes.find(
        dt => dt.id == this.device.id_dispositivo_tipo
      );

      this.device.informar_qtd = !deviceType.deConsumo;
    },

    async save() {
      if (this.isEditing) {
        this.$emit("edit", this.device);
      } else {
        this.$emit("add", this.device);
      }
    }
  }
};
</script>
