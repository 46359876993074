<template>
  <div>
    <!-- @cancel="abrirModal('ModalNotaAutomatica')" -->
    <!-- @confirm="abrirModal('ModalNotaManual')" -->
    <el-popconfirm
      confirmButtonText="Manual"
      cancelButtonText="Automática"
      icon="el-icon-info"
      cancelButtonType="warning"
      @cancel="abrirModal('ModalNotaAutomatica')"
      @confirm="abrirModal('ModalNotaManual')"
      confirmButtonType="success"
      iconColor="green"
      title="Cadastrar Nota: "
    >
      <vs-button
        slot="reference"
        color="success"
        size="small"
        radius
        type="relief"
        :disabled="
          !$atividades.permissoes.includes(5) ||
          (fatura.status_notas != null &&
            fatura.lixeira == 0 &&
            fatura.status_notas != 4)
        "
        icon="note"
      ></vs-button>
    </el-popconfirm>
    <component
      @update="update"
      v-bind:is="modal"
      :key="reRenderModal"
      :fatura="fatura"
      :contrato="contrato"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import ModalNotaAutomatica from "./components/notaAutomatica";
import ModalNotaManual from "./components/notaManual";
export default {
  props: {
    fatura: null,
    contrato: {},
  },
  data() {
    return {
      modal: "",
      reRenderModal: 0,
    };
  },
  methods: {
    async abrirModal(modal) {
      this.modal = modal;
      this.reRenderModal += 1;
    },
    async update() {
      await this.$emit("update");
    },
  },
  components: {
    ...components,
    ModalNotaAutomatica,
    ModalNotaManual,
  },
};
</script>
<style>
.el-popconfirm__main {
  font-size: 14px !important;
}
.el-button--warning {
  background-color: #0f4084 !important;
  border-color: #0f4084 !important;
}
.el-button--success {
  background-color: #00bba2 !important;
  border-color: #00bba2 !important;
}
</style>
