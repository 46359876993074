<template>
  <vs-popup
    :title="'Renovar ' + contrato.estagio + ' Nº.' + contrato.id"
    :active.sync="modalRenovar"
  >
    <div class="w-full vs-con-loading__container" id="loadingRenovar">
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="px-3 pb-3"
        >
          <div class="w-full">
            <label style="color: black; font-size: 0.85rem"
              >Tipo de Faturamento</label
            >
            <el-select
              filterable
              size
              class="w-full"
              :popper-append-to-body="false"
              placeholder="Tipo do Faturamento"
              v-model="contrato.id_tipo_faturamento"
            >
              <el-option
                v-for="tipo in tipo_faturamento"
                :value="tipo.id"
                :label="tipo.nome"
                :key="tipo.id"
              ></el-option>
            </el-select>
          </div>
        </vs-col>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="px-3"
        >
          <vs-textarea height="100" v-model="obs" label="Observação" />
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-1"
        >
          <vx-card class="renovar overflow-hidden" @click="renovar()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class
                  >
                    <span class="py-1" style="font-size: 20px"
                      >CONFIRMAR RENOVAÇÃO</span
                    >
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
  </vs-popup>
</template>
<script>
export default {
  props: {
    contrato: {
      require: true,
    },
  },
  data() {
    return {
      obj: {},
      obs: "",
      modalRenovar: true,
      tipo_faturamento: {},
    };
  },
  methods: {
    async renovar() {
      await this.$vs.loading({ container: "#loadingRenovar", scale: 0.6 });
      try {
        this.contrato.dataHoje = this.$formartData.dataHoje();
        this.contrato.dataVencimento = await this.$formartData.somarDias(
          new Date(),
          30
        );
        this.contrato.id_colaborador = localStorage.getItem("id");
        let res = await this.$http.post("renovarContrato", [this.contrato]);
        await this.logAssunto(res);
        this.modalRenovar = false;
        await this.$vs.notify(this.$notify.Success);
        await this.$emit("redirect", res.id);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#loadingRenovar > .con-vs-loading");
      }
    },
    async logAssunto(res) {
      try {
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Renovação do contrato N°" + this.contrato.id,
        // };
        // await this.$logger(logData);
        await this.$http.post("assuntoCliente", {
          id_assuntos: 6,
          id_cliente: res.id_cliente,
          id_contrato: res.id,
          id_estagio: res.id_estagio,
          status: 2,
          tipo: this.contrato.id_tipo_contato,
          id_colaborador: window.localStorage.getItem("id"),
          obs: res.obs,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getTipoFaturamento() {
      try {
        this.tipo_faturamento = await this.$http.get(`tipo_faturamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  async mounted() {
    this.modalRenovar = await true;
    await this.getTipoFaturamento();
  },
};
</script>
<style lang="scss" scoped>
.renovar {
  transition-duration: 50ms;
  background-color: #0f4084;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #0f4084;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
