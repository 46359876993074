<template>
  <div class="w-full">
    <vs-row vs-justify="center" vs-align="center" v-if="onlyPests">
      <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
        <vx-tooltip
          color="warning"
          :text="!!device.deConsumo ? 'Status de consumo': 'Pragas do dispositivo'"
          position="left"
        >
          <vs-button
            color="warning"
            icon="bug_report"
            size="small"
            v-if="!device.deConsumo"
            @click="$emit('device-pests')"
            :disabled="disableActions || device.lixeira || !hasPermission"
            type="relief"
          ></vs-button>
          <vs-button
            color="success"
            icon="keyboard_return"
            size="small"
            v-else
            @click="consumeDevice()"
            :disabled="disableActions || device.lixeira || !hasPermission"
            type="relief"
          ></vs-button>
        </vx-tooltip>
      </vs-col>
    </vs-row>

    <vs-row vs-justify="center" vs-align="center" v-else>
      <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
        <vx-tooltip color="warning" text="Baixar QRCode" position="left">
          <vs-button
            color="warning"
            icon="file_download"
            size="small"
            @click="$emit('generate-qrcode')"
            :disabled="disableActions || device.lixeira || !hasPermission"
            type="relief"
          ></vs-button>
        </vx-tooltip>
      </vs-col>
      <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
        <vx-tooltip color="warning" text="Editar Dispositivo" position="left">
          <vs-button
            color="warning"
            icon="edit"
            size="small"
            @click="$emit('edit')"
            :disabled="disableActions || device.lixeira || !hasPermission"
            type="relief"
          ></vs-button>
        </vx-tooltip>
      </vs-col>
      <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
        <vx-tooltip
          color="danger"
          text="Excluir dispositivo"
          position="left"
          v-if="!device.lixeira"
        >
          <vs-button
            color="danger"
            icon="delete"
            size="small"
            @click="$emit('remove')"
            :disabled="disableActions || !hasPermission"
            type="relief"
          ></vs-button>
        </vx-tooltip>
        <vx-tooltip
          color="warning"
          text="Restaurar dispositivo"
          position="left"
          v-else
        >
          <vs-button
            color="warning"
            icon="undo"
            size="small"
            @click="$emit('restore')"
            :disabled="disableActions || !hasPermission"
            type="relief"
          ></vs-button>
        </vx-tooltip>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
export default {
  props: {
    device: Object,
    disableActions: Boolean,
    onlyPests: Boolean
  },

  data() {
    return {
      hasPermission: false
    };
  },

  async mounted() {
    this.hasPermission =
      this.$atividades.permissoes.includes(3) &&
      this.$atividades.permissoes.includes(9);
  },

  methods: {
    async consumeDevice() {
      if (this.device.devicePests) {
        this.device.devicePests = [
          ...this.device.devicePests,
          !this.device.devicePests[0].consumido
        ];
      } else {
        this.device.devicePests = [
          {
            consumido: !this.device.consumido
          }
        ];
      }
      this.$emit("save-pests", this.device);
    }
  }
};
</script>
