<template>
  <div
    id="loader_edit_blueprint"
    class="vs-con-loading__container blueprint--container"
  >
    <vs-row vs-type="flex" vs-justify="flex-end" vs-align="center" class="mt-8">
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        class="gap"
      >
        <div>
          <b>{{ lastDeviceNumber }}</b> de {{ numberOfDevices }} dispositivos.
        </div>
        <div>
          Proximo dispositivo/area: <b>{{ nomeDosDispositivos[this.lastDeviceNumber + 1].dispositivo_area }}</b>
        </div>
        <vs-button
          icon="cancel"
          size="small"
          color="danger"
          type="relief"
          @click="$emit('cancel')"
        >
          Cancelar Edição
        </vs-button>

        <span for="corArco">Cor do arco</span>
        <input id="corArco" type="color" v-model="corArco" />

        <span for="corTexto">Cor do texto</span>
        <input id="corTexto" type="color" v-model="corTexto" />

        <vs-button
          icon="undo"
          size="small"
          color="warning"
          type="relief"
          @click="undo"
        >
          Voltar
        </vs-button>
        <vs-button
          icon="save"
          size="small"
          type="relief"
          color="success"
          @click="output"
        >
          Salvar
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center">
        <canvas
          id="canvas-img"
          class="border-canvas"
          @mousedown="handleMouseDown"
        ></canvas>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
export default {
  props: {
    imageUrl: String,
    imageData: Object,
    numberOfDevices: Number,
    nomeDosDispositivos: Array,
  },

  data() {
    return {
      loaderContainer: "#loader_edit_blueprint",
      blueprint: {
        arquivo_url: "",
      },

      lastDeviceNumber: 0,
      bg: null,
      bgctx: null,
      offsetX: null,
      offsetY: null,
      canvasWidth: null,
      canvasHeight: null,
      isDragging: false,
      sealSize: 30,

      defaultCanvas: null,

      drawPositions: [],
      corArco: "#2980b9",
      corTexto: "#000000",

    };
  },
  methods: {
    buildCanvas() {
      const canvasImg = document.getElementById("canvas-img");
      const ctx = canvasImg.getContext("2d");

      const image = new Image();
      image.drawPositions = this.drawPositions;
      image.canvasDrawPosition = this.canvasDrawPosition;
      image.onload = function () {
        canvasImg.width = this.width;
        canvasImg.height = this.height;
        const canvas = ctx.canvas;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(this, 0, 0, this.width, this.height);

        for (const position of this.drawPositions) {
          this.canvasDrawPosition(
            position.event,
            position.number,
            position.color
          );
        }
      };
      image.src = this.imageUrl;
    },

    handleMouseDown(event) {
      if (this.lastDeviceNumber >= this.numberOfDevices) {
        return;
      }
      this.lastDeviceNumber += 1;
      const nomeDispositivo = this.nomeDosDispositivos[this.lastDeviceNumber].dispositivo_area
      this.canvasDrawPosition(event, this.lastDeviceNumber, this.corArco, nomeDispositivo);
      this.drawPositions.push({
        event,
        number: this.lastDeviceNumber,
        corArco: this.corArco,
        corTexto: this.corTexto,
      });
    },

    async canvasDrawPosition(event, number, color, nome) {
      const canvas = document.getElementById("canvas-img");
      const ctx = canvas.getContext("2d");
      const pos = this.getCursorPosition(canvas, event);
      const clickX = pos.x;
      const clickY = pos.y;

      ctx.beginPath();
      ctx.arc(clickX, clickY, 15, 0, 2 * Math.PI);
      ctx.lineWidth = 3;
      ctx.strokeStyle = color;
      ctx.stroke();

      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = this.corTexto;
      ctx.font = "bold 8pt Arial"

      ctx.fillText(nome, clickX, clickY + 30)
      ctx.fillText(number, clickX, clickY);
    },

    async undo() {
      this.drawPositions.pop();
      this.lastDeviceNumber = this.drawPositions.length;
      const canvas = document.getElementById("canvas-img");
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      this.buildCanvas();
    },

    getCursorPosition(canvas, e) {
      const rect = canvas.getBoundingClientRect();

      return {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
    },

    output() {
      this.imageData.edited_source = document
        .getElementById("canvas-img")
        .toDataURL();

      this.$emit("save");
    },
  },
  async mounted() {
    if (this.imageUrl) {
      this.buildCanvas();
    }
  },
};
</script>
<style scoped lang="scss">
.border-canvas {
  border: 1px solid black;
  border-radius: 3px;
  padding: 5px;
  margin: 5px;
}
.img-blueprint {
  height: 100%;
  width: auto;
  object-fit: contain;
}
.gap {
  gap: 16px;
}
</style>
