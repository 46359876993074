<template>
  <vs-col
    vs-w="2.8"
    class="p-0"
    vs-type="flex"
    vs-justify="flex-end"
    vs-align="center"
  >
    <SideBar
      :title="'Agendar Servicos para ' + endereco.nome"
      @hide="modalServicosAgenda = false"
      :active="modalServicosAgenda"
      size="huger"
      @success="validacao == true ? submitForm() : verificarAgenda()"
      :textSucces="validacao == true ? 'Agendar' : 'Validar'"
      :visualizar="!buttonStatus"
    >
      <div
        class="w-full p-4 vs-con-loading__container"
        slot="body"
        id="servicosAgendar"
      >
        <vs-col vs-w="5" class="mr-10">
          <div class="w-full">
            <vs-col vs-w="12"> <Agenda class="m-4"></Agenda> </vs-col
            ><vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <vs-button
                color="warning"
                :disabled="!$atividades.permissoes.includes(17)"
                @click="submitForm()"
                type="relief"
                >Agendar Sem Bloqueios</vs-button
              >
            </vs-col>
          </div>
        </vs-col>
        <vs-col vs-w="6.5">
          <vs-divider class="mt-0" border-style="dotted"
            >Informações gerais dos serviços</vs-divider
          >
          <vs-row class="mt-4 mx-4 mb-0" vs-type="flex" vs-w="12">
            <vs-col vs-w="4">
              <vs-row>
                <div style="display: inline-block; margin-right: 20px">
                  <label style="color: black; font-size: 13px"
                    >Dias dos serviços</label
                  >
                </div>
              </vs-row>
              <div class="w-full">
                <el-select
                  style="width: 200px"
                  v-model="diaSelect"
                  @change="removeValidacao()"
                  multiple
                  clearable
                  v-validate="'required'"
                  collapse-tags
                  name="dias"
                  :popper-append-to-body="false"
                  filterable
                >
                  <el-option
                    v-for="dia in dias"
                    :key="dia.value"
                    :label="dia.nome"
                    :value="dia.value"
                  ></el-option>
                </el-select>
                <vs-row>
                  <span
                    class="text-danger text-sm mt-1"
                    v-show="errors.has('dias')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
              <vs-row></vs-row>
            </vs-col>
            <vs-col
              vs-w="4"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div>
                <label style="color: black; font-size: 13px">Turno</label>
                <br />
                <el-select
                  filterable
                  clearable
                  @change="removeValidacao()"
                  class="select-info"
                  placeholder="Selecione..."
                  v-model="agenda.turno"
                  name="turnos"
                  v-validate="agenda.horario == null ? 'required' : false"
                  style="width: 200px"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="turno in turnos"
                    class="select-info"
                    :value="turno.id"
                    :label="turno.nome"
                    :key="turno.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col
              vs-w="3"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-input
                label="Hora marcada"
                name="hora"
                @change="removeValidacao()"
                v-validate="agenda.turno == null ? 'required' : false"
                v-model="agenda.horario"
                v-mask="'##:00'"
                max="24"
                type="datetime"
                placeholder="00:00"
                class="inputx w-full"
              />
            </vs-col>
          </vs-row>
          <div class="mx-5 mt-5">
            Forca inicial necessaria:
            {{ contrato.horas_iniciais * contrato.tecnicos_iniciais }}
            <span class="mx-10">
              Forca Manutencao necessaria:
              {{ contrato.horas_manutencao * contrato.tecnicos_manutencao }}
            </span>
            <span class="mx-10">Carros: {{ contrato.carros }}</span>
          </div>
          <vs-divider border-style="dotted">Data dos serviços</vs-divider>
          <div id="head_modal_agenda" class="vs-con-loading__container">
            <VuePerfectScrollbar
              class="scroll-servicos-agenda"
              :settings="settings"
            >
              <div v-for="(data, index) in servicos" :key="index">
                <vs-row>
                  <vs-col vs-w="4" class="mb-5">
                    <vs-input
                      :label="'Data do  ' + (index + 1) + '° serviço'"
                      type="date"
                      color="dark"
                      @change="
                        removeValidacaoIndex(index);
                        index == 0 ? changeFirstDate(index) : 0;
                      "
                      v-validate="'required'"
                      v-model="servicos[index].data"
                    />
                    <h6 class="mx-2 mt-1">Dia: {{ diasSemana[index] }}</h6>
                  </vs-col>
                  <vs-col vs-w="5">
                    <div>
                      <vs-input
                        class="mt-3 w-full"
                        id="status"
                        :label="servicos[index].status"
                        style="border-radius: 0px"
                        :color="servicos[index].color"
                        size="small"
                        readonly
                        :value="
                          servicos[index].disponivel == 1
                            ? 'Melhor Data: ' + servicos[index].data
                            : servicos[index].disponivel == 0
                            ? 'Última Data Verificada: ' +
                              servicos[index].ultimaData
                            : 'Aguardando validação'
                        "
                      />
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </VuePerfectScrollbar>
          </div>
        </vs-col>
      </div>
    </SideBar>
  </vs-col>
</template>
<script>
import components from "@/components/default/exports.js";
import Agenda from "../../../agenda/Agenda.vue";

export default {
  props: {
    cliente: { require: true },
    contrato: { require: true },
    endereco: { nome: "Carregando", servicos: 0 },
    servicoEndereco: null
  },
  data() {
    return {
      diasSemana: [],
      semana: [
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
        "Domingo"
      ],
      buttonStatus: true,
      validacao: false,
      modalServicosAgenda: false,
      dias: [
        { value: 1, nome: "Segunda" },
        { value: 2, nome: "Terça" },
        { value: 3, nome: "Quarta" },
        { value: 4, nome: "Quinta" },
        { value: 5, nome: "Sexta" },
        { value: 6, nome: "Sábado" },
        { value: 0, nome: "Domingo" }
      ],
      diaSelect: [1, 2, 3, 4, 5],
      turnos: [
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" }
      ],
      agenda: { horario: null, turno: null },
      servicos: [],
      settings: {
        maxScrollbarLength: 200,
        wheelSpeed: 0.6,
        height: 1000
      },
      horasNecessariasInicial: 0,
      horasNecessariasManutencao: 0
    };
  },
  methods: {
    async getInputText(servico) {
      return servico.disponivel == 1
        ? "Melhor Data: " + servico.data
        : servico.disponivel == 0
        ? "Ultima Data Verificada: " + servico.ultimaData
        : "Aguardando validação";
    },
    async changeFirstDate(index) {
      await this.updateMapServices(this.servicos[index].data);
    },
    async verificarAgenda() {
      this.buttonStatus = false;
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.validacao = null;
          let horasNecessarias = 0;
          let horasExec = 0;
          let tecsExec = 0;
          let i = 1;
          let inicial = false;
          await this.$vs.loading({
            container: "#head_modal_agenda",
            scale: 0.9
          });
          this.servicos = await this.servicos.map(item => {
            item.disponivel = null;
            return item;
          });
          let ii = 0;
          for (let servico of this.servicos) {
            if (i == 1) {
              inicial = true;
              horasExec = this.contrato.horas_iniciais;
              tecsExec = this.contrato.tecnicos_iniciais;
            } else {
              inicial = false;
              horasExec = this.contrato.horas_manutencao;
              tecsExec = this.contrato.tecnicos_manutencao;
            }
            i = i + 1;
            let date = this.$formartData.dataHoje();
            try {
              let resService = await this.$http.post("verificarForca", {
                data: servico.data,
                id_turno: this.agenda.turno,
                horaMarcada: this.agenda.horario,
                // horasNecessarias: horasNecessarias,
                horas_exec: horasExec,
                tecnicos_exec: tecsExec,
                dias: this.diaSelect,
                inicial: inicial,
                carros: this.contrato.carros,
                hoje: date,
                periodoManutencao: this.contrato.periodo_manutencao,
                datas: this.servicos
              });
              servico.status = resService.status;
              servico.disponivel = resService.disponivel;
              if (servico.disponivel == 1) {
                servico.data = resService.data;
                this.diasSemana[ii] = await this.getDiaDaSemana(
                  resService.data
                );
                servico.color = "success";
              } else {
                servico.ultimaData = resService.data;
                this.validacao = false;
                servico.color = "danger";
              }
            } catch (err) {
              this.validacao = false;
              const error = this.$httpErrors(err);
              this.$vs.notify(error);
            } finally {
              await this.verificarServicos();
            }
            ii++;
          }
          await this.$vs.loading.close("#head_modal_agenda > .con-vs-loading");
          this.buttonStatus = true;
        } else {
          this.$vs.notify({
            title: "Erro",
            text: "Escolhe o turno",
            color: "danger"
          });
          this.buttonStatus = true;
        }
      });
    },
    async verificarServicos() {
      if (this.validacao != false) {
        this.validacao = true;
      }
    },
    async removeValidacao() {
      this.validacao = false;
    },
    async removeValidacaoIndex(index) {
      this.validacao = false;
      // await this.diasServices();
      this.diasSemana.splice(index, 1);
      this.diasSemana.splice(
        index,
        0,
        await this.getDiaDaSemana(this.servicos[index].data)
      );
    },
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.agendar();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },
    async agendar() {
      await this.$vs.loading({ container: "#servicosAgendar", scale: 0.6 });
      let object = {
        obs: this.contrato.obs_tecnica,
        id_endereco: this.endereco.id_endereco,
        id_cliente: this.contrato.id_cliente,
        id_contrato: this.contrato.id,
        horas_manutencao: this.contrato.horas_manutencao,
        horas_iniciais: this.contrato.horas_iniciais,
        tecnicos_manutencao: this.contrato.tecnicos_manutencao,
        tecnicos_iniciais: this.contrato.tecnicos_iniciais,
        id_turno: this.agenda.turno,
        hora_marcada: this.agenda.horario,
        fog: this.contrato.fog,
        fog_inicial: this.contrato.fog_inicial,
        escada: this.contrato.escada,
        escada_inicial: this.contrato.escada_inicial,
        carros: this.contrato.carros
      };
      try {
        await this.$http.post("os", {
          object: object,
          servicos: this.servicos
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "Servico",
          ip: window.localStorage.getItem("ip"),
          texto: "Agendar servicos para o contrato N°" + this.contrato.id
        };
        await this.$logger(logData);
        await this.$vs.notify({
          title: "Successo",
          text: "Agendado com successo",
          color: "success"
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#servicosAgendar > .con-vs-loading");
        this.$emit("update");
        this.modalServicosAgenda = false;
      }
    },
    async mapServices() {
      this.servicos = [];
      this.diasSemana = [];

      let date = this.$formartData.dataHoje();
      for (let i = 1; i <= parseInt(this.endereco.servicos); i++) {
        let dataFormatada = await this.$formartData.formatar(new Date(date), 1);
        this.servicos.push({
          data: dataFormatada,
          status: "Aguardando validacao"
        });
        this.diasSemana.push(await this.getDiaDaSemana(dataFormatada));
        date = await this.$formartData.somarDias(
          new Date(date),
          parseInt(this.contrato.periodo_manutencao)
        );
      }
    },
    async diasServices() {
      this.diasSemana = this.servicos.map(x => this.getDiaDaSemana(x.data));
    },
    async updateMapServices(date) {
      date = new Date(date);
      this.servicos = [];
      this.diasSemana = [];
      for (let i = 1; i <= parseInt(this.endereco.servicos); i++) {
        let dataFormatada = await this.$formartData.formatar(date, 1);
        this.servicos.push({
          data: dataFormatada,
          status: "Aguardando validacao"
        });
        this.diasSemana.push(await this.getDiaDaSemana(dataFormatada));

        date = await this.$formartData.somarDias(
          date,
          parseInt(this.contrato.periodo_manutencao)
        );
      }
    },
    async getDiaDaSemana(data) {
      data = new Date(data);
      let dia = data.getUTCDay();
      return this.semana[dia - 1] ? this.semana[dia - 1] : "Domingo";
    }
  },
  async mounted() {
    this.modalServicosAgenda = true;
    await this.mapServices();
    // await this.getQuantidadeServicosEndereco()
  },
  components: { ...components, Agenda }
};
</script>
<style lang="scss">
.scroll-servicos-agenda {
  height: 50vh !important;
}
#status {
  border-radius: 20px !important;
}
.vs-input-success #status {
  border: 1px solid rgba(var(--vs-success), 1) !important;
}
.vs-input-danger #status {
  border: 1px solid rgba(var(--vs-danger), 1) !important;
}
.vs-input-warning #status {
  border: 1px solid rgba(var(--vs-warning), 1) !important;
}
</style>
