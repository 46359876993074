<template>
  <vs-row vs-type="flex" vs-justify="center" vs-align="center" v-if="embedUrl">
    <span class="mb-2 block font-bold">
      {{ title }}
    </span>
    <vs-col vs-w="12">
      <div class="mx-3">
        <a :href="embedUrl" target="_blank">
          <input type="image" class="image-blueprint-size" :src="embedUrl" />
        </a>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  props: {
    title: String,
    embedUrl: String,
  },
};
</script>

<style scoped>
.image-blueprint-size {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border: 1px dashed #999;
  border-radius: 5px;
  padding: 5px;
}
</style>