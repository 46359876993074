<template>
  <vx-card class="p-0 mt-3">
    <vx-card
      no-shadow
      style="background-color: #ffca00; padding: 0% !important"
    >
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class
        >
          <span style="font-size: 12px; color: black" class="font-weight-bold"
            >E-CONTROLE</span
          >
          <span
            style="font-size: 12px; color: black"
            class="ml-8 font-weight-bold"
            >{{ contrato.estagio }} Nº. {{ contrato.id }}</span
          >
          <span
            style="font-size: 12px; color: black"
            class="ml-8 font-weight-bold"
            >Data: {{ contrato.data_documento }}</span
          >
          <span
            style="font-size: 12px; color: black"
            class="ml-8 font-weight-bold"
            v-if="contrato.vencimento && contrato.id_estagio == 3"
            >Vencimento: {{ contrato.vencimento }}</span
          >
        </vs-col>
      </vs-row>
    </vx-card>
    <div class="mt-5 h">
      <div
        class="p-1"
        style="border-style: dotted; border-width: 1px; border-radius: 5px"
      >
        <vs-row>
          <h5 class="mb-3" style="color: black">
            <b>CONTRATANTE / CLIENTE</b>
          </h5>
          <vs-col
            vs-type
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <h6 class="my-1" style="color: black; font-size: 12px">
              <b>
                <span style="text-transform: uppercase">
                  {{ cliente.nome_fantasia }}
                </span>
              </b>
            </h6>
            <vs-divider
              border-style="solid"
              color="dark"
              class="py-0 my-1"
            ></vs-divider>
          </vs-col>
          <vs-col
            vs-type
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <h6 class="my-1" style="color: black; font-size: 12px">
              <b>Email:</b>
              {{ cliente.email != null ? cliente.email : "Sem email" }} /
              <b>Telefone:</b>
              {{ cliente.telefone != null ? cliente.telefone : "Sem telefone" }}
            </h6>
            <vs-divider
              border-style="solid"
              color="dark"
              class="py-0 my-1"
            ></vs-divider>
          </vs-col>
          <vs-col
            vs-type
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <h6 class="my-1" style="color: black; font-size: 12px">
              <b>CPF/CNPJ:</b>
              {{
                cliente.cpf_cnpj != null ? cliente.cpf_cnpj : "Não informado"
              }}
            </h6>
            <vs-divider
              border-style="solid"
              color="dark"
              class="py-0 my-1"
            ></vs-divider>
          </vs-col>
          <vs-col
            vs-type
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <h6 class="my-1" style="color: black; font-size: 12px">
              <b>Endereco(s):</b>
            </h6>
            <div v-if="enderecos != undefined && enderecos.length > 0">
              <h6
                v-for="endereco in enderecos"
                :key="endereco.id"
                class="my-0"
                style="text-align: justify; color: black; font-size: 11px"
              >
                <div
                  class="w-full"
                  v-if="endereco.showPrint == 1 || endereco.faturamento == 1"
                >
                  <b>&nbsp;-&nbsp;{{ endereco.nome }}:</b>
                  {{ endereco.logradouro }}, {{ endereco.nomeBairro }},
                  <span v-if="endereco.numero > 0"
                    >Nº.{{ endereco.numero }},</span
                  >
                  <span v-if="endereco.complemento"
                    >{{ endereco.complemento }},</span
                  >
                  <b>{{ endereco.cep }}</b>
                  , Segmento:
                  {{ endereco.nomeSegmento }}.
                  <span
                    v-if="
                      endereco.contatos != undefined &&
                      endereco.contatos.length > 0
                    "
                    >CONTATOS:&nbsp;</span
                  >
                  <span v-for="contato in endereco.contatos" :key="contato">
                    <b>{{ contato.nome }}</b>
                    : {{ contato.contato }} |
                  </span>
                </div>
              </h6>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <div
        class="mt-3 p-1"
        style="border-style: dotted; border-width: 1px; border-radius: 5px"
      >
        <h5 class="mb-2" style="color: black">
          <b>CONDIÇÕES GERAIS</b>
        </h5>
        <vs-row>
          <vs-col
            v-if="controle.nome != null"
            vs-type
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <h6 class="my-1" style="color: black; font-size: 12px">
              <vs-icon icon="done" color="success"></vs-icon>
              Servico de {{ controle.nome }}
            </h6>
          </vs-col>
          <vs-divider
            border-style="solid"
            color="dark"
            class="py-0 my-1"
          ></vs-divider>
          <vs-col
            vs-type
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <h6 class="my-1" style="color: black; font-size: 12px">
              <vs-icon icon="done" color="success"></vs-icon>
              {{ plano.garantia }} Meses de Garantia
            </h6>
          </vs-col>
          <vs-divider
            border-style="solid"
            color="dark"
            class="py-0 my-1"
          ></vs-divider>
          <vs-col
            vs-type
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <h6 class="my-1" style="color: black; font-size: 12px">
              <vs-icon icon="done" color="success"></vs-icon>
              {{ plano.aplicacoes }} Aplicações sendo uma a cada
              {{ plano.periodo_manutencao }} dias
            </h6>
          </vs-col>
          <vs-divider
            v-if="
              plano.tipo_faturamento != undefined && plano.obs_tipo_faturamento
            "
            border-style="solid"
            color="dark"
            class="py-0 my-1"
          ></vs-divider>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
            v-if="
              plano.tipo_faturamento != undefined && plano.obs_tipo_faturamento
            "
          >
            <vs-icon
              v-if="plano.obs_tipo_faturamento"
              icon="info"
              class="my-1"
              color="warning"
              size="15px"
            ></vs-icon>
            <label
              class="my-0 mx-1 font-semibold"
              v-if="
                plano.obs_tipo_faturamento &&
                plano.obs_tipo_faturamento != undefined
              "
              style="font-size: 13px; color: black; text-align: justify"
              >{{ plano.obs_tipo_faturamento }}</label
            >
          </vs-col>
          <vs-divider
            v-if="contrato.obs != null"
            border-style="solid"
            color="dark"
            class="py-0 my-1"
          ></vs-divider>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
            v-if="contrato.obs != null"
          >
            <vs-icon
              icon="info"
              class="my-1"
              color="warning"
              size="15px"
            ></vs-icon>
            <label
              class="my-0 mx-1 font-semibold"
              style="font-size: 13px; color: black; text-align: justify"
            >
              <h6
                v-if="contrato.obs"
                class="my-1"
                style="color: black; font-size: 12px"
              >
                <span>{{ contrato.obs }}</span>
              </h6>
            </label>
          </vs-col>
          <vs-divider
            v-if="vendedor && vendedor.nome != undefined"
            border-style="solid"
            color="dark"
            class="py-0 my-0"
          ></vs-divider>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
            class="mt-2"
          >
            <label class="my-0" style="font-size: 12px; color: black">
              Vendedor:
              <b>{{ vendedor.nome }}</b>
              |
              <b>{{ vendedor.email }}</b
              >|
              <b>{{ vendedor.telefone }}</b>
            </label>
          </vs-col>
          <vs-divider
            v-if="orcamentista && orcamentista.id > 0"
            border-style="solid"
            color="dark"
            class="py-0 my-0"
          ></vs-divider>
          <vs-col
            vs-type="flex"
            v-if="orcamentista && orcamentista.id > 0"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
            class="mt-2"
          >
            <label class="my-0" style="font-size: 12px; color: black">
              Orçamentista:
              <b>{{ orcamentista.nome }}</b>
            </label>
          </vs-col>
        </vs-row>
      </div>
      <div
        v-if="areas.length > 0 || contrato.obs_tecnica"
        class="mt-3 p-1"
        style="border-style: dotted; border-width: 1px; border-radius: 5px"
      >
        <h5 class="mb-2" style="color: black">
          <b>LEVANTAMENTO</b>
        </h5>
        <vs-row>
          <vs-col
            vs-type
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <h6
              class="my-1"
              v-if="areas.length > 0"
              style="color: black; font-size: 12px"
            >
              <strong>AREAS:</strong>
              <span v-for="(area, index) in areas" :key="area.id">
                {{ index > 0 ? "," : "" }} {{ area.qtd }} {{ area.nome }}(s)
              </span>
            </h6>

            <h6
              v-if="contrato.obs_tecnica"
              class="my-1"
              style="color: black; font-size: 12px"
            >
              <strong>OBSERVAÇÃO TÉCNICA:&nbsp;</strong>
              <span>{{ contrato.obs_tecnica }}</span>
            </h6>
            <h6 class="my-1" style="color: black; font-size: 12px">
              <span v-if="contrato.fog || contrato.escada">
                LEVAR
                <b>{{ contrato.fog ? "FOG" : "" }}</b>
                {{ contrato.fog && contrato.escada ? " e " : "" }}
                <b>{{ contrato.escada ? "ESCADA" : "" }}</b>
              </span>
            </h6>
          </vs-col>
        </vs-row>
      </div>
      <div
        v-if="plano.valor"
        class="mt-3 p-1"
        style="border-style: dotted; border-width: 1px; border-radius: 5px"
      >
        <vs-row class>
          <h5 class="mb-2" style="color: black">
            <b>FORMA DE PAGAMENTO</b>
          </h5>

          <vs-col vs-type vs-justify="center" vs-align="center" vs-w="12">
            <h6 class="my-0" style="color: black; font-size: 16px">
              <span
                v-if="plano.entrada > 0 && plano.id_forma_pagamento_entrada > 0"
                class="pr-1"
              >
                <b>Entrada</b> de <b>{{ $currency(plano.entrada) }}</b> no
                <b>{{ pagamento.entrada }}</b>
              </span>
              <span
                v-if="
                  plano.parcelas > 0 && plano.id_forma_pagamento_parcela > 0
                "
                class="pr-1"
              >
                <span
                  v-if="
                    plano.entrada > 0 && plano.id_forma_pagamento_entrada > 0
                  "
                >
                  mais
                </span>
                <b>{{ plano.parcelas }} Parcela(s)</b> de
                <span v-if="plano.desconto > 0">
                  <span
                    style="
                      text-decoration: line-through;
                      font-size: 14px;
                      color: red;
                    "
                    class="pr-1"
                    >{{
                      $currency(
                        parseFloat(
                          (plano.valor - plano.entrada) / plano.parcelas
                        )
                      )
                    }}</span
                  >
                </span>

                <span style="font-size: 16px; color: green" class="font-bold">
                  <b>
                    {{
                      $currency(
                        parseFloat(
                          (plano.valor -
                            plano.valor * (plano.desconto / 100) -
                            plano.entrada) /
                            plano.parcelas
                        )
                      )
                    }}
                  </b>
                </span>
                no
                <b style="font-size: 14px">{{ pagamento.parcela }}</b>
              </span>
            </h6>
          </vs-col>
          <vs-col
            vs-type
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <h6 class="my-0" style="color: black">
              <span v-if="plano.desconto > 0">
                <span
                  style="
                    text-decoration: line-through;
                    font-size: 12px;
                    color: black;
                  "
                  class="pr-1"
                  >{{ $currency(plano.valor) }}</span
                >
                com
                <b>{{ plano.desconto }}%</b> de desconto
                <span style="font-size: 14px; color: black" class="font-bold">
                  {{
                    $currency(
                      parseFloat(
                        plano.valor - plano.valor * (plano.desconto / 100)
                      )
                    )
                  }}
                </span>
              </span>
              <span v-else>
                <span style="font-size: 14px; color: black" class="font-bold">
                  {{ $currency(plano.valor) }}
                </span>
              </span>
            </h6>
          </vs-col>
        </vs-row>
      </div>
      <div
        v-if="contrato.id_estagio == 3"
        class="mt-3 p-1"
        style="border-style: dotted; border-width: 1px; border-radius: 5px"
      >
        <h5 class="mb-2" style="color: black">
          <b>SERVIÇOS</b>
        </h5>
        <vs-row type="flex" vs-justify="flex-start">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            :key="index"
            vs-w="12"
            class="my-0 p-0"
            v-for="(data, index) in enderecos"
          >
            <div class="w-full m-0 p-0" v-if="data.showPrint == 1">
              <vs-col vs-w="12" class="my-0 mx-2">
                <label style="font-size: 11px; color: black">
                  Endereco:
                  <b>{{ data.nome }}</b>
                </label>
              </vs-col>
              <vs-row
                vs-justify="flex-start"
                vs-align="center"
                class="p-0 m-0"
                vs-type="flex"
              >
                <vs-col
                  vs-w="3"
                  class="my-1 mx-0"
                  :key="index2"
                  v-for="(os, index2) in servicosEndereco[index]"
                >
                  <div class="mx-1">
                    <vx-card class="m-0 p-0 card-border" no-shadow>
                      <vs-row
                        vs-w="12"
                        vs-justify="center"
                        vs-align="center"
                        vs-type="flex"
                      >
                        <vs-col
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                          vs-type="flex"
                        >
                          <label class="m-0 p-0">
                            <strong
                              style="font-size: 11px; color: black"
                              class="mb-0"
                              >{{ os.tipo }}: OS {{ os.id }}</strong
                            >
                          </label>
                        </vs-col>
                      </vs-row>
                      <vs-row>
                        <vs-col
                          vs-w="12"
                          vs-justify="center"
                          vs-align="center"
                          vs-type="flex"
                          class="m-0 p-0"
                        >
                          <label
                            class="m-0 p-0"
                            style="color: black; font-size: 10px"
                          >
                            <strong>
                              {{ os.data_servico_format }}
                              -
                              {{
                                os.hora_marcada != null
                                  ? os.hora_marcada
                                  : os.nomeTurno
                              }}
                            </strong>
                          </label>
                        </vs-col>
                      </vs-row>
                    </vx-card>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <div
      v-if="clausulas != undefined && clausulas.length > 0"
      class="mt-3 p-1"
      style="border-style: dotted; border-width: 1px; border-radius: 5px"
    >
      <h5 class="mb-3" style="color: black">
        <b>CLÁUSULAS</b>
      </h5>
      <vs-row>
        <vs-col vs-type vs-justify="flex-start" vs-align="flex-start" vs-w="12">
          <span v-for="data in clausulas" :key="data.id">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <span
                  class="my-2"
                  style="font-size: 12px; color: black; text-align: justify"
                >
                  <b>{{ data.numero }}°</b>
                  {{ data.clausula }}
                </span>
              </vs-col>
            </vs-row>
          </span>
        </vs-col>
      </vs-row>
    </div>
  </vx-card>
</template>
<script>
export default {
  props: {
    contrato: null,
    cliente: null,
    enderecos: {},
    areas: null,
    servicosEndereco: null,
    vendedor: null,
    orcamentista: null,
    controle: null,
    clausulas: null,
    plano: null,
    pagamento: null
  },
  mounted () { }
};
</script>
<style>
.card-border {
  background-color: white;
  border-style: solid;
  border-radius: 4px;
  border-color: #ccc;
  border-width: 1px;
}
</style>
