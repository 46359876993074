<template>
  <vs-col
    vs-w="2.8"
    class="p-0"
    vs-type="flex"
    vs-justify="flex-end"
    vs-align="center"
  >
    <SideBar
      :title="'Remarcar OS N° ' + osSelecionado.id"
      @hide="modalOs = false"
      :active="modalOs"
      size="huger"
      @success="validacao == true ? submitForm() : verificarAgenda()"
      :textSucces="validacao == true ? 'Regendar servico' : 'Validar'"
      :visualizar="!buttonStatus"
    >
      <div
        class="w-full p-4 vs-con-loading__container"
        slot="body"
        id="remarcar"
      >
        <vs-col vs-w="5" class="mr-10">
          <div class="w-full">
            <vs-col vs-w="12"> <Agenda class="m-4"></Agenda> </vs-col
            ><vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <vs-button
                color="warning"
                :disabled="!$atividades.permissoes.includes(17)"
                @click="submitForm()"
                type="relief"
                >Agendar Sem Bloqueios</vs-button
              >
            </vs-col>
          </div>
        </vs-col>
        <vs-col vs-w="6.5">
          <vs-divider class="mt-0" border-style="dotted"
            >Reagendamento de OS</vs-divider
          >
          <vs-row
            class="mt-4 mx-4 mb-0"
            vs-justify="flex-start"
            vs-type="flex"
            vs-w="12"
          >
            <div id="head_modal_reagendar" class="vs-con-loading__container">
              <vs-col vs-w="4">
                <div class="w-full">
                  <vs-input
                    label="Data da remarcação"
                    type="date"
                    color="dark"
                    @change="removeValidacao()"
                    name="data"
                    v-validate="'required'"
                    v-model="os.data_remarcacao"
                  />
                </div>
              </vs-col>
              <vs-col vs-w="6" class="ml-20">
                <div>
                  <vs-input
                    class="mt-3 w-full"
                    id="status"
                    :label="servico.status"
                    style="border-radius: 0px"
                    :color="servico.color"
                    size="small"
                    readonly
                    :value="
                      servico.disponivel == 1
                        ? 'Melhor Data: ' + servico.data
                        : servico.disponivel == 0
                        ? 'Última Data Verificada: ' + servico.ultimaData
                        : 'Aguardando validação'
                    "
                  />
                </div>
              </vs-col>
            </div>
          </vs-row>
          <vs-row
            class="mt-4 mx-4 mb-0"
            vs-justify="space-between"
            vs-type="flex"
            vs-w="12"
          >
            <vs-col
              vs-w="4"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div>
                <label style="color: black; font-size: 13px">Turno</label>
                <br />
                <el-select
                  filterable
                  @change="removeValidacao()"
                  clearable
                  class="select-info"
                  placeholder="Selecione..."
                  v-model="os.id_turno"
                  name="turnos"
                  v-validate="os.hora_marcada == null ? 'required' : false"
                  style="width: 200px"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="turno in turnos"
                    class="select-info"
                    :value="turno.id"
                    :label="turno.nome"
                    :key="turno.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col
              vs-w="4"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full">
                <vs-input
                  label="Hora marcada"
                  name="hora_marcada"
                  v-validate="os.id_turno == null ? 'required' : false"
                  v-model="os.hora_marcada"
                  v-mask="'##:00'"
                  max="24"
                  @change="removeValidacao()"
                  type="datetime"
                  placeholder="00:00"
                  class="inputx w-full ml-3"
                />
              </div>
            </vs-col>
            <vs-col
              vs-w="2.5"
              class="mx-3"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
            >
              <div class="w-full">
                <vs-input
                  class="inputx w-full"
                  color="success"
                  v-validate="'required|numeric'"
                  label="Carros."
                  min="0"
                  type="number"
                  v-model="os.carros"
                  name="carros"
                  @change="removeValidacao()"
                />
              </div>
            </vs-col>
          </vs-row>

          <vs-row
            class="mt-1 mx-5 p-0"
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          ></vs-row>
          <vs-row class="mt-3">
            <vs-col vs-w="12">
              <div
                class="w-full p-3"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <vs-textarea
                  v-model="os.obs"
                  class="p-0 m-0"
                  name="obs"
                  label="Motivo da alteração"
                  v-validate="'required'"
                />
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
      </div>
    </SideBar>
  </vs-col>
</template>


<script>
import components from "@/components/default/exports.js"
import Agenda from "../../../agenda/Agenda.vue"
export default {
  props: {
    osSelecionado: {},
    contrato: null,
  },
  data () {
    return {
      buttonStatus: true,
      diaSelect: [1, 2, 3, 4, 5, 6, 7],
      validacao: false,
      turnos: [
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],
      modalOs: false,
      servico: { status: "Aguardando validação", obs: "Aguardando validação" },
      os: {
        id_turno: null,
        obs: null,
        hora_marcada: null,
        data_remarcacao: null,
        carros: 0,
      },
    }
  },
  methods: {
    async verificarAgenda () {
      if (
        (this.os.id_turno != null || this.os.hora_marcada != null) &&
        this.os.obs != null &&
        this.os.data_remarcacao != null
      ) {
        this.buttonStatus = false
        this.validacao = null
        let horasExec = null
        let tecsExec = null
        let inicial = false
        this.$vs.loading({ container: "#head_modal_reagendar", scale: 0.9 })
        if (this.osSelecionado.id_tipo == 1) {
          inicial = true
          horasExec = this.contrato.horas_iniciais
          tecsExec = this.contrato.tecnicos_iniciais
        } else {
          horasExec = this.contrato.horas_manutencao
          tecsExec = this.contrato.tecnicos_manutencao
        }
        let date = this.$formartData.dataHoje()
        try {
          this.servico = await this.$http.post("verificarForca", {
            data: this.os.data_remarcacao,
            id_turno: this.os.id_turno,
            horaMarcada: this.os.hora_marcada,
            // horasNecessarias: horasNecessarias,
            horas_exec: horasExec,
            tecnicos_exec: tecsExec,
            dias: this.diaSelect,
            inicial: inicial,
            carros: this.os.carros,
            hoje: date,
            periodoManutencao: this.contrato.periodo_manutencao,
            datas: [],
          })
          if (this.servico.disponivel == 1) {
            this.os.data_remarcacao = this.servico.data
            this.servico.color = "success"
          } else {
            this.servico.ultimaData = this.servico.data
            this.validacao = false
            this.servico.color = "danger"
          }
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        }
        this.$vs.loading.close("#head_modal_reagendar > .con-vs-loading")
        this.verificarServicos()
        this.buttonStatus = true
      } else {
        this.$vs.notify({
          title: "Erro",
          text: "Preencha os campos",
          color: "danger",
        })
        this.buttonStatus = true
      }
    },
    async verificarServicos () {
      if (this.validacao != false) {
        this.validacao = true
      }
    },
    async removeValidacao () {
      this.validacao = false
    },
    async osRemarcar () {
      await this.$vs.loading({ container: "#remarcar", scale: 0.6 })
      try {
        let os = {}
        os.data_remarcacao = this.os.data_remarcacao
        os.obs = this.os.obs
        os.remarcado = 1
        os.confirmado = 0
        os.hora_marcada = this.os.hora_marcada
        os.id_turno = this.os.id_turno
        os.carros = this.os.carros
        const res = await this.$http.put("remarcarOs/" + this.osSelecionado.id, os)
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Remarcação da OS N°" + res.id,
        // }
        // await this.$logger(logData)
        this.$vs.notify({
          title: "",
          text: "Reagendado com successo",
          color: "success",
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      await this.$emit("update")
      await this.$vs.loading.close("#remarcar > .con-vs-loading")
      this.modalOs = await false
      this.obs = ""
    },
    async submitForm () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.osRemarcar()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            })
            reject("error")
          }
        })
      })
    },
  },
  async mounted () {
    this.modalOs = await true
  },
  components: {
    ...components,
    Agenda,
  },
};
</script>

<style>
#status {
  border-radius: 20px !important;
}
.vs-input-success #status {
  border: 1px solid rgba(var(--vs-success), 1) !important;
}
.vs-input-danger #status {
  border: 1px solid rgba(var(--vs-danger), 1) !important;
}
</style>
