<template>
  <div class="w-full">
    <vs-popup :title="'Editar Nota'" v-if="open" :active.sync="modalEditar">
      <div class="w-full vs-con-loading__container" :id="'editNota' + nota.id">
        <vs-row vs-type="flex" vs-justify="space-between">
          <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center">
            <div class="w-full">
              <vs-input
                label="Número da Nota"
                type="number"
                name="numero"
                :description-text="nota.numero == 'SN' ? 'NOTA S/N' : ''"
                color="dark"
                v-model="notaEdit.numero"
                class="px-1 w-full"
              />
            </div>
          </vs-col>
          <vs-col
            vs-w="6"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <vs-input
              label="RPS"
              :disabled="false"
              name="rps"
              type="number"
              color="dark"
              v-model="notaEdit.rps"
              class="px-1 w-full"
            />
          </vs-col>
        </vs-row>
        <vs-row class="mt-1" vs-type="flex" vs-align="center">
          <vs-col
            vs-w="6"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <vs-input
              label="Valor Bruto"
              name="valor_bruto"
              icon="R$"
              icon-after="true"
              :disabled="true"
              color="dark"
              v-model="notaEdit.valor_bruto"
              class="px-1 w-full"
            />
          </vs-col>
          <vs-col
            vs-w="6"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <vs-input
              label="Valor Líquido"
              name="valor_liquido"
              icon="R$"
              icon-after="true"
              :disabled="false"
              color="dark"
              v-model="notaEdit.valor_liquido"
              class="px-1 w-full"
            />
          </vs-col>
        </vs-row>
        <vs-row class="mt-1" vs-w="12" vs-type="flex" vs-align="center">
          <vs-col
            vs-w="6"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <vs-input
              type="date"
              label="Data de Emissão"
              name="data_emissao"
              :disabled="true"
              v-model="notaEdit.data_emissao"
              class="px-1 w-full"
            />
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mt-1 mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-1 w-full"
              type="text"
              label="Link da nota fiscal"
              v-model="notaEdit.link"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mt-1 mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-1 w-fulll"
              style="width: 600px"
              type="text"
              label="Observação"
              v-model="notaEdit.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-button
          class="w-full mt-3"
          color="warning"
          icon="edit"
          size="sm"
          type="relief"
          @click="submitForm()"
          >Editar</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    nota: {},
    blockButton: false
  },
  data () {
    return {
      modalEditar: false,
      open: false,
      notaEdit: {
        valor_liquido_origin: null
      }
    }
  },
  methods: {
    async openModal () {
      this.open = await true
      this.notaEdit = Object.assign({}, this.nota)
      this.notaEdit.valor_liquido_origin = this.nota.valor_liquido
      this.modalEditar = await true
    },
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.Editar()
        } else {
          this.error = true
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    async Editar () {
      await this.$vs.loading({
        container: "#editNota" + this.nota.id,
        scale: 0.6
      })
      try {
        delete this.notaEdit.status
        delete this.notaEdit.hasError
        delete this.notaEdit.emissao
        const res = await this.$http.post("updateNota", this.notaEdit)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição da Nota N°" + res.id
        }
        await this.$logger(logData)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.modalEditar = await false
        await this.$emit("update")
        await this.$vs.loading.close(
          "#editNota" + this.nota.id + " > .con-vs-loading"
        )
      }
    }
  },
  async mounted () {
    await this.openModal()
  }
};
</script>
