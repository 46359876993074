<template>
  <div>
    <vs-button
      class="mx-1"
      color="#388e3c"
      type="relief"
      icon="assignment"
      size="small"
      :disabled="
        contrato.notaExists == 1 ||
        result.validation == false ||
        contrato.id_status == 3 ||
        !$atividades.permissoes.includes(2)
      "
      @click="modalSyncFaturar = true"
      >{{ contrato.faturado == 1 ? "Refaturar" : "Faturar" }}
    </vs-button>
    <vs-popup
      :title="
        'Gerar Plano de Faturamento do ' +
        contrato.estagio +
        ' Nº.' +
        contrato.id
      "
      :active.sync="modalSyncFaturar"
    >
      <div class="w-full vs-con-loading__container" id="popupGerarPlano">
        <div>
          <vs-row vs-type="flex" vs-align="center">
            <vs-col
              vs-w="5"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              class="px-3"
            >
              <vs-input
                label="Data Faturamento"
                v-model="contrato.data_faturamento"
                type="date"
                color="dark"
                min="1"
              />
            </vs-col>
          </vs-row>
          <vs-row class="mt-5" vs-type="flex" vs-align="center">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
            >
              <vs-button
                class="mx-2 mt-2 faturar"
                type="success"
                icon="assignment"
                size="small"
                @click="validarData()"
                style="font-size: 16px"
              >
                {{
                  contrato.faturado == 1
                    ? "GERAR NOVO FATURAMENTO"
                    : "GERAR PLANO DE FATURAMENTO"
                }}
              </vs-button>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    contrato: {
      require: true,
    },
    result: { validation: false },
  },
  data() {
    return {
      modalSyncFaturar: false,
      assunto: {},
    };
  },
  methods: {
    async validarData() {
      if (
        !this.contrato.data_faturamento ||
        this.contrato.data_faturamento == null
      ) {
        this.$vs.notify({
          title: "Data de Faturamento é necessária",
          text: "Preencha o campo!",
          color: "danger",
        });
        return false;
      } else {
        let date = new Date(this.contrato.data_faturamento);
        date = parseInt(date.getUTCDate());
        if (date > 28) {
          this.$vs.notify({
            title: "Data de Faturamento Inválida",
            text: "Dia não pode ser maior que 28!",
            color: "danger",
          });
          return false;
        } else {
          await this.faturarContrato();
        }
      }
    },
    async faturarContrato() {
      await this.$vs.loading({
        container: "#popupGerarPlano",
        scale: 0.6,
      });
      try {
        delete this.contrato.tipo_faturamento;
        this.contrato.id_colaborador = await localStorage.getItem("id");
        await this.$http.post("faturamento", this.contrato);
        await this.logAssunto();
        this.modalSyncFaturar = false;
        await this.$vs.loading.close();
        await this.$vs.notify({
          title: "Successo",
          text: "Plano gerado com Sucesso",
          color: "success",
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
        await this.$vs.loading.close();
      } finally {
        await this.$emit("update");
        await this.$vs.loading.close("#popupGerarPlano > .con-vs-loading");
      }
    },
    async logAssunto() {
      try {
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "cadastrar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Faturamento do contrato N°" + this.contrato.id,
        // };
        // await this.$logger(logData);
        await this.$http.post("assuntoCliente", {
          id_assuntos: 15,
          id_cliente: this.contrato.id_cliente,
          id_contrato: this.contrato.id,
          id_estagio: this.contrato.id_estagio,
          status: 2,
          tipo: 5,
          id_colaborador: window.localStorage.getItem("id"),
          obs: "Faturamento do contrato N°" + this.contrato.id,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  components: {
    ...components,
  },
};
</script>
<style lang="scss">
.faturar {
  transition-duration: 50ms;
  background-color: green;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: green;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
