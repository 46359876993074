<template>
  <vs-col
    vs-w="2.8"
    class="p-0"
    vs-type="flex"
    vs-justify="flex-end"
    vs-align="center"
  >
    <SideBar
      title=""
      @hide="activeModal = false"
      :active="activeModal"
      size="huger"
      textSucces="Concluir"
      @success="activeModal = !activeModal"
    >
      <div class="w-full px-4" slot="body">
        <VuePerfectScrollbar
          class="scroll-manage-devices-main"
          :settings="settings"
        >
          <vs-tabs
            position="top"
            color="dark"
            alignment="center"
            id="address-devices"
          >
            <vs-tab icon-pack="feather" icon="icon-shield" label="Dispositivos">
              <devices-tab-content
                :client="client"
                :contract="contract"
                :address="address"
                :os="os"
                :onlyActives="onlyActives"
                :onlyPests="onlyPests"
              >
              </devices-tab-content>
            </vs-tab>

            <vs-tab icon-pack="feather" icon="icon-grid" label="Planta">
              <blueprint-tab-content
                :client="client"
                :contract="contract"
                :address="address"
              >
              </blueprint-tab-content>
            </vs-tab>
          </vs-tabs>
        </VuePerfectScrollbar>
      </div>
    </SideBar>
  </vs-col>
</template>
<script>
import components from "@/components/default/exports.js";
import BlueprintTabContent from "./blueprint/tabContent.vue";
import DevicesTabContent from "./device/tabContent.vue";

export default {
  props: {
    client: Object,
    contract: Object,
    address: Object,
    os: Object,
    onlyActives: Boolean,
    onlyPests: Boolean,
  },

  components: {
    ...components,
    BlueprintTabContent,
    DevicesTabContent,
  },

  data() {
    return {
      activeModal: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
    };
  },

  mounted() {
    this.activeModal = true;
  },
};
</script>
<style>
.scroll-manage-devices-main {
  max-height: 82vh !important;
}
</style>