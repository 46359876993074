<template>
  <vs-row class="my-3" vs-justify="center" vs-align="center" vs-type="flex">
    <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
      <vx-card class="mr-1" no-shadow card-border>
        <vs-col
          vs-w="12"
          class="mt-1"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <label
            style="font-size: 14px; color: black; text-transform: uppercase"
            class="font-semibold mb-1"
            >PRESTADOR</label
          >
        </vs-col>
        <div class="w-full mr-2">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <label style="font-size: 13px; color: black" class="mb-1">
              <b>Razão Social:</b>
              {{ empresa.razao_social }}
            </label>
          </vs-col>
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <label style="font-size: 13px; color: black" class="mb-1">
              <b>Nome Fantasia:</b>
              {{ empresa.nome_fantasia }}
            </label>
          </vs-col>

          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <label style="font-size: 13px; color: black" class="mb-1">
              <b>CNPJ:</b>
              {{ empresa.cnpj ? empresa.cnpj : "Não Informado" }}
            </label>
          </vs-col>
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <label style="font-size: 13px; color: black" class="mb-1">
              <b>Inscrição Municipal:</b>
              {{
                empresa.inscricao_municipal
                  ? empresa.inscricao_municipal
                  : "Não Informado"
              }}
            </label>
          </vs-col>
        </div>
      </vx-card>
    </vs-col>
  </vs-row>
</template>
<script>
export default {
  props: {
    empresa: {}
  }
}
</script>
