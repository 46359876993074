<template>
  <vs-col
    vs-w="2.8"
    class="p-0"
    vs-type="flex"
    vs-justify="flex-end"
    vs-align="center"
  >
    <SideBar
      :title="'Editar Agenda: ' + endereco.nome"
      @hide="modalServicosAgenda = false"
      :active="modalServicosAgenda"
      size="huger"
      textSucces="Salvar"
      @success="submitForm()"
      :visualizar="!buttonStatus"
    >
      <div
        class="w-full p-4 vs-con-loading__container"
        slot="body"
        id="editarAgenda"
      >
        <vs-col vs-w="4">
          <div class="w-full">
            <vs-col vs-w="12" vs-type="flex">
              <Agenda class="m-4"></Agenda>
            </vs-col>
          </div>
        </vs-col>
        <vs-col vs-w="8">
          <vs-divider border-style="dotted">Data dos serviços</vs-divider>
          <div id="head_modal_agenda" class="vs-con-loading__container">
            <VuePerfectScrollbar
              class="scroll-servicos-editar-agenda"
              :settings="settings"
            >
              <div v-for="(servico, index) in servicos" :key="index">
                <vs-row
                  vs-w="12"
                  vs-type="flex"
                  vs-align="center"
                  vs-justify="flex-start"
                >
                  <vs-col vs-w="3" vs-type="flex">
                    <div class="w-full mx-2">
                      <vs-input
                        :label="`OS N°${servico.id}`"
                        type="date"
                        color="dark"
                        disabled
                        v-model="servicos[index].data_servico"
                      />
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="3"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="w-full mx-2" v-if="servico.executado < 1">
                      <vs-input
                        :key="key"
                        @change="key++"
                        label="Data para remarcação"
                        :name="'os' + index"
                        type="date"
                        color="dark"
                        v-validate="'required'"
                        v-model="servicos[index].data_remarcacao_servico"
                      />
                    </div>
                    <label v-else size="small" class="font-bold"
                      >OS com retorno!</label
                    >
                  </vs-col>
                  <vs-col vs-w="3" vs-type="flex" v-if="servico.executado < 1">
                    <div class="w-full mx-2">
                      <vs-input
                        label="Hora marcada"
                        :name="'horario' + index"
                        v-validate="
                          servicos[index].id_turno == null ||
                          !servicos[index].id_turno
                            ? 'required'
                            : false
                        "
                        v-model="servicos[index].hora_marcada"
                        v-mask="'##:00'"
                        max="24"
                        type="datetime"
                        placeholder="00:00"
                        class="inputx w-full"
                      />
                    </div>
                  </vs-col>
                  <vs-col vs-w="3" vs-type="flex" v-if="servico.executado < 1">
                    <div class="w-full mx-2">
                      <label style="color: black; font-size: 0.85rem"
                        >Turno</label
                      >
                      <br />
                      <el-select
                        filterable
                        clearable
                        placeholder="Selecione..."
                        v-model="servicos[index].id_turno"
                        :name="'turno' + index"
                        v-validate="
                          servicos[index].hora_marcada == null ||
                          !servicos[index].hora_marcada
                            ? 'required'
                            : false
                        "
                        :popper-append-to-body="false"
                      >
                        <el-option
                          v-for="turno in turnos"
                          :value="turno.id"
                          :label="turno.nome"
                          :key="turno.id"
                        ></el-option>
                      </el-select>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </VuePerfectScrollbar>
          </div>
          <div class="w-full mt-3">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <vs-col vs-w="12" vs-type="flex">
                <div class="w-full mx-2">
                  <vs-textarea
                    v-model="obsRemarcacao"
                    class="w-full"
                    name="obs"
                    color="dark"
                    label="Motivo da alteração"
                    v-validate="'required'"
                  />
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </div>
    </SideBar>
  </vs-col>
</template>
<script>
import components from "@/components/default/exports.js";
import Agenda from "../../../agenda/Agenda.vue";

export default {
  props: {
    cliente: { require: true },
    contrato: { require: true },
    endereco: { nome: "Carregando", servicos: 0 },
    servicoEndereco: null,
  },
  data() {
    return {
      key: 0,
      buttonStatus: true,
      modalServicosAgenda: false,
      turnos: [
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],
      servicos: [],
      settings: {
        maxScrollbarLength: 200,
        wheelSpeed: 0.6,
        height: 1000,
      },
      obsRemarcacao: "",
    };
  },
  methods: {
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.reagendarServicos();
            console.log(this.servicos);
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            });
          }
        });
      });
    },
    async reagendarServicos() {
      await this.$vs.loading({
        container: "#editarAgenda",
        scale: 0.6,
      });
      try {
        await this.$http.post("editarAgenda", {
          servicos: this.servicos,
          contrato: this.contrato,
          endereco: this.endereco,
          obs: this.obsRemarcacao,
        });
        await this.$emit("update");
        this.modalServicosAgenda = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#editarAgenda > .con-vs-loading");
      }
    },
    async getServicosEndereco() {
      await this.$vs.loading({
        container: "#editarAgenda",
        scale: 0.6,
      });
      try {
        const res = await this.$http.post("getServicosEndereco", {
          id_endereco: this.endereco.id_endereco,
          id_contrato: this.contrato.id,
        });
        this.servicos = res.servicos;

        for (let x of this.servicos) {
          x.data_remarcacao_servico = x.data_servico;
        }

        this.key++;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#editarAgenda > .con-vs-loading");
      }
    },
  },
  async mounted() {
    this.modalServicosAgenda = true;
    await this.getServicosEndereco();
  },
  components: { ...components, Agenda },
};
</script>
<style lang="scss">
.scroll-servicos-editar-agenda {
  max-height: 50vh !important;
}
</style>
