<template>
  <div>
    <vs-button
      class="mx-1"
      type="relief"
      color="grey"
      icon="settings"
      style="color: white"
      size="small"
      @click="openModal"
      v-if="contrato.have2Bill"
    >
      <b>Calcular novo valor</b>
    </vs-button>
    <vs-popup
      title="Alterar Datas dos Faturamentos"
      :active.sync="modalSyncAlterarDatas"
    >
      <div class="w-full vs-con-loading__container" id="popupAlterarDatas">
        <div>
          <VuePerfectScrollbar
            class="scroll-alterar-datas"
            :settings="settings"
          >
            <vs-row
              class="mb-2"
              vs-align="center"
              vs-justify="center"
              vs-type="flex"
              vs-w="12"
            >
              <vs-col
                vs-align="center"
                vs-justify="center"
                vs-type="flex"
                vs-w="12"
              >
                <div class="w-full mx-2">
                  <vs-row
                    vs-align="center"
                    vs-justify="center"
                    vs-type="flex"
                    vs-w="12"
                  >
                    <vs-col
                      vs-align="center"
                      vs-justify="center"
                      vs-type="flex"
                      vs-w="4"
                    >
                      <div class="w-full my-1 mx-2">
                        <vs-input
                          :label="'Valor nova parcela'"
                          type="text"
                          class="w-full"
                          color="dark"
                          v-model="valorNovaParcela"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      vs-align="center"
                      vs-justify="center"
                      vs-type="flex"
                      vs-w="4"
                    >
                      <div class="w-full my-1 mx-2">
                        <vs-input
                          :label="'Valor antiga parcela'"
                          type="text"
                          class="w-full"
                          color="dark"
                          v-model="valorAntigaParcela"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      vs-align="center"
                      vs-justify="center"
                      vs-type="flex"
                      vs-w="4"
                    >
                      <div class="w-full my-1 mx-2">
                        <vs-input
                          :label="'Desconto'"
                          type="text"
                          class="w-full"
                          color="dark"
                          v-model="valorDesconto"
                        />
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row
                    vs-align="center"
                    vs-justify="center"
                    vs-type="flex"
                    vs-w="12"
                  >
                    <vs-col
                      vs-align="center"
                      vs-justify="center"
                      vs-type="flex"
                      vs-w="4"
                    >
                      <div class="w-full my-1 mx-2">
                        <vs-input
                          :label="'Qtd nova parcela'"
                          type="text"
                          class="w-full"
                          color="dark"
                          v-model="qtdNovaParcela"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      vs-align="center"
                      vs-justify="center"
                      vs-type="flex"
                      vs-w="4"
                    >
                      <div class="w-full my-1 mx-2">
                        <vs-input
                          :label="'Qtd antiga parcela'"
                          type="text"
                          class="w-full"
                          color="dark"
                          v-model="qtdAntigaParcela"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      vs-align="center"
                      vs-justify="center"
                      vs-type="flex"
                      vs-w="4"
                    >
                      <div class="w-full my-1 mx-2"></div>
                    </vs-col>
                  </vs-row>
                  <vs-col
                    vs-align="center"
                    vs-justify="center"
                    vs-type="flex"
                    vs-w="12"
                  >
                    <div class="mt-4 mx-2">
                      <vs-button
                        class="mx-1"
                        type="relief"
                        color="warning"
                        icon="settings"
                        style="color: white"
                        size="small"
                        @click="cal"
                      >
                        <b>Calcular</b>
                      </vs-button>
                    </div>
                  </vs-col>
                  <vs-col
                    vs-align="center"
                    vs-justify="center"
                    vs-type="flex"
                    vs-w="12"
                  >
                    <div class="mt-10 mx-2">
                      <h1>
                        Novo valor do contrato:
                        {{ $currency(valorNovoContrato) }}
                      </h1>
                    </div>
                  </vs-col>
                </div>
              </vs-col>
            </vs-row>
          </VuePerfectScrollbar>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    contrato: {
      require: true,
    },
    result: { validation: false },
  },
  data() {
    return {
      modalSyncAlterarDatas: false,
      faturasAlterarDatas: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      valorNovaParcela: 0,
      valorAntigaParcela: 0,
      qtdNovaParcela: 0,
      qtdAntigaParcela: 0,
      valorDesconto: 0,
      valorNovoContrato: 0,
    };
  },
  methods: {
    async openModal() {
      this.modalSyncAlterarDatas = true;
      await this.getFaturasAlterarDatas();
    },
    async cal() {
      let newDocVal = 0,
        newVal = 0,
        oldVal = 0;
      newVal =
        (this.valorNovaParcela / ((100 - this.valorDesconto) / 100)) *
        this.qtdNovaParcela;
      oldVal =
        (this.valorAntigaParcela / ((100 - this.valorDesconto) / 100)) *
        this.qtdAntigaParcela;
      newDocVal = newVal + oldVal;
      this.valorNovoContrato = newDocVal;
    },
  },
  async mounted() {},
  components: {
    ...components,
  },
};
</script>

<style lang="scss">
.scroll-alterar-datas {
  min-height: 20vh !important;
  max-height: 50vh !important;
}
</style>
