<template>
  <div id="loader_manage_devices" class="vs-con-loading__container">
    <add-devices
      v-if="!isEditingDevicePests && !onlyPests"
      :device="device"
      :areas="areas"
      :device-types="deviceTypes"
      :is-editing="isEditingDevice"
      @add="add"
      @edit="update"
    >
    </add-devices>

    <add-pests
      v-if="isEditingDevicePests"
      :device="device"
      :pests="pests"
      :os="os"
      @save="savePests"
    >
    </add-pests>

    <vs-progress
      v-if="isEditingDevicePests || !onlyPests"
      :height="6"
      :percent="100"
      color="primary"
    >
    </vs-progress>

    <list-devices
      :devices="devices"
      :list-opts="listOpts"
      :only-actives="onlyActives"
      :only-pests="onlyPests"
      :disable-actions="isEditingDevice || isEditingDevicePests"
      @list="list(true)"
      @generate-qrcode="generateDeviceQrCode"
      @generate-multiples-qrcode="generateMultiplesQRCodes"
      @edit="activeEditDevice"
      @device-pests="activeEditDevicePests"
      @remove="remove"
      @restore="restore"
      @save-pests="savePests"
    >
    </list-devices>
  </div>
</template>
<script>
import ListDevices from "./listDevices.vue";
import AddDevices from "./addDevices.vue";
import AddPests from "./addPests.vue";

export default {
  props: {
    client: Object,
    contract: Object,
    address: Object,
    os: Object,
    onlyActives: Boolean,
    onlyPests: Boolean,
  },

  components: {
    ListDevices,
    AddDevices,
    AddPests,
  },

  data() {
    return {
      device: {},
      devices: [],
      areas: [],
      deviceTypes: [],
      pests: [],
      isEditingDevice: false,
      isEditingDevicePests: false,
      loaderContainer: "#loader_manage_devices",
      listOpts: {
        showDeleted: false,
      },
    };
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6,
      });
      try {
        this.device = this.getNewObject();

        if (this.onlyActives) {
          this.listOpts.showDeleted = false;
        }

        await this.list();
        await this.getAreas();
        await this.getDevicesTypes();
        await this.getPests();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    },

    getNewObject() {
      return {
        quantidade: 1,
        informar_qtd: 1,
        consumido: null,
      };
    },

    async getAreas() {
      this.areas = await this.$http.get("area");
    },

    async getDevicesTypes() {
      this.deviceTypes = await this.$http.get("device-types");
    },

    async getPests() {
      this.pests = await this.$http.get("praga");
    },

    async list(activeLoad = false) {
      activeLoad
        ? await this.$vs.loading({
            container: this.loaderContainer,
            scale: 0.6,
          })
        : 0;
      try {
        const requestData = {
          ...this.listOpts,
          id_cliente: this.client.id,
          id_contrato: this.contract.id,
          id_endereco: this.address.id_endereco,
          id_os: this.os ? this.os.id : null,
        };

        this.devices = await this.$http.post("list-devices", requestData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        activeLoad
          ? await this.$vs.loading.close(
              `${this.loaderContainer} > .con-vs-loading`
            )
          : 0;
      }
    },

    async add(device) {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6,
      });
      try {
        const requestData = {
          ...device,
          id_cliente: this.client.id,
          id_contrato: this.contract.id,
          id_endereco: this.address.id_endereco,
        };

        const createdEntity = await this.$http.post(
          "associate-device",
          requestData
        );
        await this.addedDevice(createdEntity);
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    },

    async update(device) {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6,
      });
      try {
        const updatedEntity = await this.$http.put(
          `device/${device.id}`,
          device
        );
        await this.updatedDevice(updatedEntity);
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    },

    async savePests(device) {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6,
      });
      try {
        await this.$http.put(`update-device-pests`, {
          ...device,
          id_os: this.os.id,
        });
        await this.updatedDevice(device);
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    },

    async remove(device) {
      device.lixeira = 1;
      await this.update(device);
    },

    async restore(device) {
      device.lixeira = 0;
      await this.update(device);
    },

    async addedDevice(device) {
      const texto = `Criação do dispositivo ${device.id} do contrato ${device.id_contrato}`;
      await this.createLog(texto, "cadastrar");
      this.device = this.getNewObject();
      await this.list();
    },

    async updatedDevice(device) {
      const texto = `Atualização ${
        this.isEditingDevicePests ? "das pragas " : ""
      }do dispositivo ${device.id} do contrato ${device.id_contrato}`;

      await this.createLog(texto, "atualizar");
      this.isEditingDevice = false;
      this.isEditingDevicePests = false;
      this.device = this.getNewObject();
      await this.list();
    },

    async createLog(texto, funcao) {
      let logData = {
        id_colaborador: localStorage.getItem("id"),
        funcao,
        setor: "comercial",
        ip: window.localStorage.getItem("ip"),
        texto,
      };
      await this.$logger(logData);
    },

    async activeEditDevice(device) {
      this.isEditingDevice = true;
      this.device = Object.assign({}, device);
    },

    async activeEditDevicePests(device) {
      this.isEditingDevicePests = true;
      this.device = Object.assign({}, device);
    },

    async generateDeviceQrCode(device) {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6,
      });
      try {
        const result = await this.$http.get(
          `generate-device-qrcode/${device.id}`
        );
        const fileName = `dispositivo-${device.sequencia}.png`;
        this.downloadQRCode(fileName, result);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    },

    async generateMultiplesQRCodes() {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6,
      });
      try {
        const result = await this.$http.post("generate-multiples-devices-qrcode", {
          id_endereco: this.address.id_endereco,
          id_contrato: this.contract.id
        });
        const fileName = `qrcodes-${this.address.id_endereco}-${this.contract.id}.pdf`;
        this.downloadQRCode(fileName, result);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    },

    downloadQRCode(fileName, base64Data) {
      const a = document.createElement("a");
      a.href = "data:image/png;base64," + base64Data;
      a.download = fileName;
      a.click();
    },
  },
};
</script>