<template>
  <div class="w-full m-0 p-0">
    <div
      v-if="notas.length > 0 && fatura.id"
      class="vs-con-loading__container"
      :id="'notaLista' + fatura.id"
    >
      <span v-for="(nota, index) in notas" :key="index">
        <vs-row vs-w="12" vs-align="center" vs-type="flex">
          <vx-card
            no-shadow
            :style="
              nota.id_status == 1 && nota.lixeira == 0 && nota.hasError == 0
                ? 'border-color:#0f4084 !important'
                : nota.id_status == 2 && nota.lixeira == 0 && nota.hasError == 0
                ? 'border-color:#00BBA2 !important'
                : 'border-color:#C0564B !important'
            "
            class="border ml-5 mb-2 p-0"
          >
            <vs-list class="my-0 p-0" id="lista">
              <vs-list-item
                class="mt-0 p-0"
                :style="
                  nota.id_status == 1 && nota.lixeira == 0 && nota.hasError == 0
                    ? 'color:#0f4084'
                    : nota.id_status == 2 &&
                      nota.lixeira == 0 &&
                      nota.hasError == 0
                    ? 'color:#00BBA2'
                    : 'color:#C0564B'
                "
              >
                <template slot="avatar">
                  <div class="list-titles">
                    <div class="vs-list--icon">
                      <vs-icon class="mx-1" icon="description"></vs-icon>
                      <div
                        class="vs-list--title"
                        style="cursor: pointer"
                        @click="editarNota(nota)"
                      >
                        <vx-tooltip color="warning" text="Editar Nota">
                          {{
                            nota.hasError == 1
                              ? "Nota com Erro" +
                                (nota.lixeira > 0 ? " - Cancelada" : "")
                              : nota.numero == "SN"
                              ? "Nota S/N" +
                                (nota.lixeira > 0 ? " - Cancelada" : "")
                              : nota.id_status == 1 && nota.lixeira == 0
                              ? nota.status
                              : "Nota " +
                                (nota.numero
                                  ? " N° " + nota.numero + " - "
                                  : "") +
                                (nota.lixeira > 0 ? " Cancelada" : nota.status)
                          }}
                        </vx-tooltip>
                      </div>
                    </div>
                  </div>
                </template>
                <vs-row
                  vs-justify="space-between"
                  vs-align="center"
                  class="mb-1"
                >
                  <vx-tooltip
                    v-if="nota.hasError == 1"
                    color="danger"
                    text="Erros na Nota"
                  >
                    <vs-button
                      icon="warning"
                      radius
                      @click="verErros(nota)"
                      :disabled="
                        !$atividades.permissoes.includes(5) || blockButton
                      "
                      size="small"
                      class="mx-1"
                      color="danger"
                    />
                  </vx-tooltip>
                  <vx-tooltip
                    v-if="nota.lixeira == 0 && nota.id_status != 3"
                    color="danger"
                    text="Cancelar Nota"
                  >
                    <el-popconfirm
                      confirmButtonText="Confirmar"
                      cancelButtonText="Cancelar"
                      icon="el-icon-circle-close"
                      @confirm="cancelarNota(nota)"
                      iconColor="red"
                      title="Deseja cancelar esta nota?"
                    >
                      <vs-button
                        slot="reference"
                        icon="close"
                        :disabled="
                          !$atividades.permissoes.includes(6) || blockButton
                        "
                        size="small"
                        class="mx-1"
                        color="danger"
                      />
                    </el-popconfirm>
                  </vx-tooltip>
                  <vx-tooltip v-else color="warning" text="Restaurar Nota">
                    <el-popconfirm
                      confirmButtonText="Confirmar"
                      cancelButtonText="Cancelar"
                      icon="el-icon-circle-close"
                      @confirm="restaurarNota(nota)"
                      iconColor="#0f4084"
                      title="Deseja restaurar esta nota?"
                    >
                      <vs-button
                        slot="reference"
                        icon="reply"
                        :disabled="
                          !$atividades.permissoes.includes(5) || blockButton
                        "
                        size="small"
                        class="mx-1"
                        color="#0f4084"
                      />
                    </el-popconfirm>
                  </vx-tooltip>
                  <vx-tooltip
                    color="success"
                    text="Processar Nota"
                    v-if="
                      nota.numero != 'SN' &&
                      nota.id_status == 1 &&
                      nota.hasError == 0
                    "
                  >
                    <el-popconfirm
                      confirmButtonText="Processar"
                      cancelButtonText="Cancelar"
                      icon="el-icon-circle-check"
                      @confirm="consultarProtocolo(nota)"
                      iconColor="#00BBA2"
                      title="Deseja processar esta nota?"
                    >
                      <vs-button
                        slot="reference"
                        icon="done"
                        size="small"
                        :disabled="
                          !$atividades.permissoes.includes(5) ||
                          nota.lixeira > 0 ||
                          blockButton
                        "
                        class="mx-1"
                        color="success"
                      />
                    </el-popconfirm>
                  </vx-tooltip>
                  <vx-tooltip
                    color="warning"
                    text="Reenviar Email"
                    v-if="nota.id_status == 2"
                  >
                    <el-popconfirm
                      confirmButtonText="Reenviar"
                      cancelButtonText="Cancelar"
                      icon="el-icon-circle-check"
                      @confirm="reeeviarEmails(nota)"
                      iconColor="#0f4084"
                      title="Deseja reenviar os emails para o cliente?"
                    >
                      <vs-button
                        slot="reference"
                        icon="mark_email_read"
                        size="small"
                        :disabled="
                          !$atividades.permissoes.includes(5) ||
                          nota.lixeira > 0 ||
                          blockButton
                        "
                        class="mx-1"
                        color="warning"
                      />
                    </el-popconfirm>
                  </vx-tooltip>
                  <vx-tooltip
                    color="success"
                    text="Visualizar Nota"
                    v-if="nota.id_status == 2"
                  >
                    <vs-button
                      @click="redirectBlank(nota)"
                      icon="remove_red_eye"
                      size="small"
                      :disabled="
                        !$atividades.permissoes.includes(5) ||
                        nota.lixeira > 0 ||
                        blockButton
                      "
                      class="mx-1"
                      color="success"
                    />
                  </vx-tooltip>
                  <vx-tooltip
                    color="warning"
                    text="Reenviar RPS"
                    v-else-if="nota.hasError == 1"
                  >
                    <vs-button
                      icon="send"
                      size="small"
                      @click="reenviarRps(nota)"
                      :disabled="
                        !$atividades.permissoes.includes(5) ||
                        nota.lixeira > 0 ||
                        blockButton
                      "
                      class="mx-1"
                      color="warning"
                    />
                  </vx-tooltip>
                </vs-row>
              </vs-list-item>
              <div class="list-border">
                <vs-row vs-w="12" vs-align="center" vs-justify="space-between">
                  <span class="mx-2">
                    RPS:
                    <strong>{{ nota.rps }}</strong>
                  </span>
                  <span class="mx-2">
                    Valor Bruto:
                    <strong>{{ $currency(nota.valor_bruto) }}</strong>
                  </span>
                  <span class="mx-2">
                    Valor Líquido:
                    <strong>{{ $currency(nota.valor_liquido) }}</strong>
                  </span>
                  <span class="mx-2">
                    Emissão:
                    <strong>
                      {{ nota.emissao ? nota.emissao : "Sem data" }}
                    </strong>
                  </span>
                </vs-row>
              </div>
              <vs-list-item
                icon="error_outline"
                class="mt-1 p-0 list"
                :title="nota.obs ? nota.obs : 'Sem Observação'"
              />
            </vs-list>
          </vx-card>
        </vs-row>
      </span>
    </div>
    <div v-else class="vs-con-loading__container" :id="'notaLista' + fatura.id">
      <div class="con-colors">
        <ul>
          <span
            class="pt-2"
            style="color: grey !important; font-weight: 1000 !important"
          >
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
            </vs-col>
            <vs-col
              class="mt-2"
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <span>NÃO HÁ NOTAS</span>
            </vs-col>
          </span>
        </ul>
      </div>
    </div>
    <component
      v-bind:is="modalEditarNota"
      :key="reRenderModalEditarNota"
      :blockButton="0"
      :nota="notaSelected"
      @update="close()"
    />
    <component
      v-bind:is="modalVerErrosNota"
      :key="reRenderModalVerErrosNota"
      :nota="notaSelected"
      titulo="Ver Erros Consulta de RPS"
      :json="null"
      @update="close()"
    />
    <component
      v-bind:is="modalReenviarRps"
      :key="reRenderModalReenviarRps"
      :nota="notaSelected"
      :contrato="contrato"
      :fatura="fatura"
      @update="close()"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import EditarNota from "./components/editNota";
import verErros from "./components/verErros";
import ReenviarRps from "./components/reenviarRps";
export default {
  props: {
    fatura: null,
    empresa: null,
    cliente: null,
    contrato: null,
  },
  data() {
    return {
      notas: [],
      confScroll: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
      },
      blockButton: false,
      notaSelected: {},
      modalEditarNota: "",
      modalVerErrosNota: "",
      modalReenviarRps: "",
      reRenderModalEditarNota: 0,
      reRenderModalVerErrosNota: 0,
      reRenderModalReenviarRps: 0,
    };
  },
  methods: {
    async verErros(nota) {
      this.notaSelected = await nota;
      this.modalVerErrosNota = await "verErros";
      this.reRenderModalVerErrosNota += await 1;
    },
    async getNotasByFatura() {
      try {
        this.notas = await this.$http.get(
          `getContasByFatura/` + this.fatura.id
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async editarNota(nota) {
      this.notaSelected = await nota;
      this.modalEditarNota = await "EditarNota";
      this.reRenderModalEditarNota += await 1;
    },
    async restaurarNota(nota) {
      this.blockButton = await true;
      await this.$vs.loading({
        container: "#notaLista" + this.fatura.id,
        scale: 0.6,
      });
      try {
        await this.$http.post(`changeNotaContaStatus/` + nota.id, {
          id_status: 1,
          lixeira: 0,
        });
        this.faturamento = await this.$http.put(
          "faturamento/" + this.fatura.id,
          { data_faturamento: nota.data_emissao, id_status: 3 }
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Restauração da Nota N°" + nota.id,
        };
        await this.$logger(logData);
        this.$vs.notify({
          title: "Nota restaurada com sucesso!",
          color: "success",
        });
        await this.$emit("update");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.close();
        this.blockButton = await false;
        await this.$vs.loading.close(
          "#notaLista" + this.fatura.id + " > .con-vs-loading"
        );
      }
    },
    async cancelarNota(nota) {
      this.blockButton = await true;
      await this.$vs.loading({
        container: "#notaLista" + this.fatura.id,
        scale: 0.6,
      });
      try {
        await this.$http.post(`changeNotaContaStatus/` + nota.id, {
          lixeira: 1,
        });
        this.faturamento = await this.$http.put(
          "faturamento/" + this.fatura.id,
          { data_faturamento: null, id_status: 1 }
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Cancelamento da Nota N°" + nota.id,
        };
        await this.$logger(logData);
        this.$vs.notify({
          title: "Nota cancelada com sucesso!",
          color: "success",
        });
        await this.$emit("update");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.close();
        this.blockButton = await false;
        await this.$vs.loading.close(
          "#notaLista" + this.fatura.id + " > .con-vs-loading"
        );
      }
    },
    async reenviarRps(nota) {
      this.notaSelected = nota;
      this.modalReenviarRps = "ReenviarRps";
      this.reRenderModalReenviarRps += 1;
    },
    async consultarProtocolo(nota) {
      this.blockButton = await true;
      await this.$vs.loading({
        container: "#notaLista" + this.fatura.id,
        scale: 0.6,
      });
      try {
        const objNota = await this.objConsultarProtocolo(nota);
        await this.$http.post("consultarProtocolo", {
          data: objNota,
          id_nota: nota.id,
          rps: nota.rps,
        });
        this.$vs.notify({
          title: "Consulta realizada!",
          text: "Verifique se há erros na consulta!",
          color: "success",
        });
        await this.$emit("update");
      } catch (err) {
        if (err.response != undefined) {
          this.$vs.notify({
            title: "Erros na consulta do RPS e da Nota!",
            text: "Verifique os erros na consulta e tente novamente!",
            color: "danger",
          });
        } else {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        }
      } finally {
        await this.close();
        this.blockButton = await false;
        await this.$vs.loading.close(
          "#notaLista" + this.fatura.id + " > .con-vs-loading"
        );
      }
    },
    async redirectBlank(nota) {
      const link = nota.link;
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = link;
      a.click();
    },
    async objConsultarProtocolo(nota) {
      let obj = {
        identificacaoRps: {
          ambiente: process.env.NODE_ENV,
          passphrase: "1234",
          protocolo: nota.protocolo,
        },
        prestador: {
          cnpjPrestador: await this.$removerMascara.remove(
            `${this.empresa.cnpj}`
          ),
          ccmPrestador: await this.$removerMascara.remove(
            `${this.empresa.inscricao_municipal}`
          ),
        },
      };
      return obj;
    },
    async reeeviarEmails(nota) {
      await this.$vs.loading({
        container: "#notaLista" + this.fatura.id,
        scale: 0.6,
      });
      try {
        await this.$http.post(`reenviarEmailNota`, nota);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Reenvio dos email da Nota N°" + nota.id,
        };
        await this.$logger(logData);
        this.$vs.notify({
          title: "Emails reenviados com sucesso!",
          color: "success",
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#notaLista" + this.fatura.id + " > .con-vs-loading"
        );
      }
    },
    async close() {
      await this.$emit("update");
    },
  },
  async mounted() {
    await this.$vs.loading({
      container: "#notaLista" + this.fatura.id,
      scale: 0.6,
    });
    await this.getNotasByFatura();
    await this.$vs.loading.close(
      "#notaLista" + this.fatura.id + " > .con-vs-loading"
    );
  },
  components: { ...components, EditarNota, verErros, ReenviarRps },
};
</script>
<style lang="scss" scoped>
#lista * {
  font-size: 15px !important;
}
.border {
  box-shadow: none !important;
  border-radius: 5px !important;
}
.list-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 5px;
}
.scroll-notas {
  height: 20vh !important;
}
.el-popconfirm__main {
  font-size: 12px !important;
}
</style>
