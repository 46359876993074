<template>
  <div
    class="w-full vs-con-loading__container"
    :id="'servicosEndereco' + index"
  >
    <div v-if="servicos.length > 0">
      <span class="my-0 py-0" :key="index" v-for="(os, index) in servicos">
        <vs-col vs-w="3">
          <div class="mx-2">
            <vx-card
              card-border
              class="card-servico overflow-hidden my-2"
              :card-background="
                os.executado == 1
                  ? 'success'
                  : os.executado == 2
                  ? 'danger'
                  : ''
              "
            >
              <vs-row
                vs-type="flex"
                vs-justify="flex-end"
                :style="
                  os.executado == 1
                    ? 'color: white;'
                    : os.executado == 2
                    ? 'color: white;'
                    : ''
                "
              >
                <vs-col
                  vs-w="9"
                  vs-type
                  vs-justify="flex-end"
                  vs-align="flex-end"
                >
                  <span style="font-size: 14px">
                    {{ os.tipo }}:
                    <strong>OS {{ os.id }}</strong>
                  </span>
                  <br />
                  <span style="font-size: 12px">
                    N°{{ os.numero }}
                    -
                    <strong>
                      {{ os.data_servico_format }}
                      -
                      {{ os.hora_marcada != null ? os.hora_marcada : os.turno }}
                    </strong>
                  </span>
                </vs-col>
                <vs-col
                  vs-w="1.5"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                >
                  <vs-button
                    class="px-0 py-0 m-0"
                    icon="edit"
                    color="warning"
                    size="small"
                    :disabled="
                      os.executado == 1 ||
                      (!$atividades.permissoes.includes(2) &&
                        !$atividades.permissoes.includes(8))
                    "
                    type="filled"
                    @click="openModalEdit(os)"
                  ></vs-button>
                </vs-col>
                <vs-col
                  vs-w="1.5"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                >
                  <vs-button
                    class="px-0 py-0 m-0"
                    icon="shield"
                    color="warning"
                    @click="openModalDispositivosPragas(os)"
                    size="small"
                    type="filled"
                  ></vs-button>
                </vs-col>
              </vs-row>

              <vs-row
                class="pt-2"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex-end"
              >
                <vs-col
                  vs-w="7.5"
                  vs-type
                  vs-justify="flex-end"
                  vs-align="flex-end"
                >
                  <vs-button
                    class="px-3 py-0 m-0"
                    color="warning"
                    size="small"
                    type="filled"
                    @click="os.retornos > 0 ? openModalRetornos(os) : 1"
                    >Retornos: {{ os.retornos }}</vs-button
                  >
                  <h3>&nbsp;</h3>
                </vs-col>
                <vs-col
                  vs-w="1.5"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                >
                  <vs-button
                    class="px-0 py-0 m-0"
                    icon="delete"
                    color="danger"
                    size="small"
                    :disabled="
                      os.executado == 1 ||
                      os.executado == 2 ||
                      (!$atividades.permissoes.includes(2) &&
                        !$atividades.permissoes.includes(8))
                    "
                    type="filled"
                    @click="ask2DeleteOs(os)"
                  ></vs-button>
                </vs-col>
                <vs-col
                  vs-w="1.5"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                >
                  <vs-button
                    class="px-0 py-0 m-0"
                    icon="rate_review"
                    color="warning"
                    size="small"
                    :disabled="
                      !$atividades.permissoes.includes(2) &&
                      !$atividades.permissoes.includes(8)
                    "
                    type="filled"
                    @click="openModalEditObs(os)"
                  ></vs-button>
                </vs-col>
                <vs-col
                  vs-w="1.5"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                  v-if="os.executado == 1"
                >
                  <vs-button
                    :disabled="!os.executado > 0"
                    class="px-0 py-0 m-0"
                    icon="remove_red_eye"
                    color="primary"
                    style="color: black"
                    size="small"
                    type="filled"
                    @click="redirectBlank(os)"
                  ></vs-button>
                  <!-- </a> -->
                </vs-col>
                <vs-col
                  vs-w="1.5"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                  v-else-if="os.executado == 2"
                >
                  <OsNaoExec :os="os" />
                </vs-col>
                <vs-col
                  vs-w="1.5"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                  v-else
                >
                  <vs-button
                    :disabled="true"
                    class="px-0 py-0 m-0"
                    icon="remove_red_eye"
                    color="primary"
                    style="color: black"
                    size="small"
                    type="filled"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </vx-card>
          </div>
        </vs-col>
      </span>
    </div>
    <div
      v-else
      class="vs-con-loading__container"
      :id="'servicosEndereco' + index"
    >
      <div class="con-colors">
        <ul>
          <span
            class="pt-2"
            style="color: grey !important; font-weight: 1000 !important"
          >
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
            </vs-col>
            <vs-col
              class="mt-2"
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <span>NÃO HÁ SERVIÇOS</span>
            </vs-col>
          </span>
        </ul>
      </div>
    </div>
    <component
      v-bind:is="ModalOsReagendar"
      :key="reRenderModalOsReagendar"
      :osSelecionado="osSelecionado"
      :contrato="contrato"
      @update="emitirUpdate()"
    />
    <component
      v-bind:is="modalOsDispositivoPraga"
      :key="reRenderModalOsDispositivoPraga"
      :client="cliente"
      :contract="contrato"
      :address="item"
      :os="osSelecionado"
      :onlyActives="true"
      :onlyPests="true"
      @update="emitirUpdate()"
    />
    <component
      v-bind:is="ModalOsEditarObs"
      :key="reRenderModalOsEditarObs"
      :osSelecionado="osSelecionado"
      :contrato="contrato"
      @update="emitirUpdate()"
    />
    <component
      v-bind:is="ModalOsRetornos"
      :key="reRenderModalOsRetornos"
      :osSelecionado="osSelecionado"
      :contrato="contrato"
      @update="emitirUpdate()"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import modalOsReagendar from "./modalOsReagendar.vue";
import modalOsEditarObs from "./modalOsEditarObs.vue";
import modalOsRetornos from "./modalOsRetornos.vue";
import ModalOsDispositivoPraga from "./manage-devices/modal.vue";
import OsNaoExec from "../../../agenda-servicos/os/components/osNaoExec.vue";
export default {
  props: {
    index: null,
    item: { nome: "Carregando" },
    contrato: {},
    cliente: {},
  },
  data() {
    return {
      servicos: {},
      osSelecionado: {},
      ModalOsReagendar: null,
      ModalOsEditarObs: null,
      ModalOsRetornos: null,
      reRenderModalOsReagendar: 0,
      reRenderModalOsEditarObs: 0,
      reRenderModalOsRetornos: 0,
      selectedOs: {},
      relatorioUrl: "",
      reRenderModalOsDispositivoPraga: 0,
      modalOsDispositivoPraga: null,
    };
  },
  methods: {
    async getServicosEndereco() {
      await this.$vs.loading({
        container: "#servicosEndereco" + this.index,
        scale: 0.6,
      });
      try {
        this.item.id_contrato = this.contrato.id;
        const res = await this.$http.post("getServicosEndereco", this.item);
        this.servicos = res.servicos;
        this.relatorioUrl = res.relatorioUrl;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#servicosEndereco" + this.index + " > .con-vs-loading"
        );
      }
    },

    async openModalEdit(os) {
      this.osSelecionado = os;
      this.ModalOsReagendar = "modalOsReagendar";
      this.reRenderModalOsReagendar += 1;
    },

    async openModalDispositivosPragas(os) {
      this.osSelecionado = os;
      this.modalOsDispositivoPraga = "ModalOsDispositivoPraga";
      this.reRenderModalOsDispositivoPraga += 1;
    },

    async openModalEditObs(os) {
      this.osSelecionado = os;
      this.ModalOsEditarObs = "modalOsEditarObs";
      this.reRenderModalOsEditarObs += 1;
    },

    async openModalRetornos(os) {
      this.osSelecionado = os;
      this.ModalOsRetornos = "modalOsRetornos";
      this.reRenderModalOsRetornos += 1;
    },

    async deleteOS() {
      await this.$vs.loading({
        container: "#servicosEndereco" + this.index,
        scale: 0.6,
      });
      try {
        await this.$http.post("deletarOS", this.selectedOs);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "delete",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: `Exclusão da OS N° ${this.selectedOs.id}`,
        };
        await this.$logger(logData);
        await this.emitirUpdate();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#servicosEndereco" + this.index + " > .con-vs-loading"
        );
      }
    },

    async ask2DeleteOs(os) {
      this.selectedOs = os;
      await this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: `Confirmar Exclusão da OS`,
        acceptText: "Confirmar",
        cancelText: "Cancelar",
        text: `Tem certeza que deseja deletar a OS N° ${os.id}`,
        accept: await this.deleteOS,
      });
    },

    async emitirUpdate() {
      this.$emit("update");
    },

    async redirectBlank(os) {
      const link = `${this.relatorioUrl}/?os=${os.id}`;
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = link;
      a.click();
    },
  },
  async mounted() {
    await this.getServicosEndereco();
  },
  components: {
    ...components,
    modalOsReagendar,
    modalOsEditarObs,
    modalOsRetornos,
    ModalOsDispositivoPraga,
    OsNaoExec,
  },
};
</script>
