<template>
  <div class="w-full">
    <SideBar
      title="NOTA MANUAL"
      @hide="modal = false"
      :active="modal"
      size="large"
      :visualizar="blockActions"
      textSucces="Enviar"
      icon="note"
      @success="submitForm()"
    >
      <div slot="body" class="mx-2">
        <div
          class="w-full vs-con-loading__container"
          :id="'loadingNota' + fatura.id"
        >
          <div class="w-full">
            <VuePerfectScrollbar
              class="scroll-cadastrar-nota"
              :settings="settings"
            >
              <vs-row
                class="my-3"
                vs-justify="center"
                vs-align="flex-start"
                vs-type="flex"
              >
                <vx-card no-shadow card-border>
                  <vs-row
                    vs-w="12"
                    vs-justify="center"
                    vs-align="flex-start"
                    vs-type="flex"
                  >
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full mx-2">
                        <vs-input
                          color="dark"
                          :type="numeroNota ? 'text' : 'number'"
                          v-validate="numeroNota ? '' : 'required'"
                          :disabled="numeroNota == 1"
                          v-model="nota.numero"
                          style="width: 200px"
                          name="numero_nota"
                          label="Número da Nota"
                        ></vs-input>
                        <vs-row
                          vs-w="12"
                          vs-justify="flex-start"
                          vs-align="center"
                        >
                          <span
                            style="font-size: 12px"
                            class="text-danger"
                            v-show="errors.has('numero_nota')"
                            >{{ $validators.empty }}</span
                          >
                        </vs-row>
                      </div>
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full mx-2">
                        <vs-input
                          color="dark"
                          style="width: 200px"
                          type="text"
                          v-validate="'required'"
                          name="valor"
                          icon="R$"
                          icon-after="true"
                          :key="reRenderComponents"
                          @change="
                            desconto = 0;
                            nota.iss_retido = 0;
                            reRenderComponents += 1;
                          "
                          v-model="nota.valor_liquido"
                          label="Valor"
                        ></vs-input>
                        <vs-row
                          vs-w="12"
                          vs-justify="flex-start"
                          vs-align="center"
                        >
                          <span
                            style="font-size: 12px"
                            class="text-danger"
                            v-show="errors.has('valor')"
                            >{{ $validators.empty }}</span
                          >
                        </vs-row>
                      </div>
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full mx-2">
                        <vs-input
                          color="dark"
                          type="date"
                          name="data_emissao"
                          v-validate="'required'"
                          v-model="nota.data_emissao"
                          label="Data da Emissão"
                        ></vs-input>
                        <vs-row
                          vs-w="12"
                          vs-justify="flex-start"
                          vs-align="center"
                        >
                          <span
                            style="font-size: 12px"
                            class="text-danger"
                            v-show="errors.has('data_emissao')"
                            >{{ $validators.empty }}</span
                          >
                        </vs-row>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row
                    vs-w="12"
                    vs-justify="center"
                    vs-align="flex-start"
                    vs-type="flex"
                  >
                    <vs-col
                      vs-w="3"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <div class="mx-2">
                        <vs-checkbox
                          color="success"
                          class="py-5"
                          v-model="numeroNota"
                          @change="
                            numeroNota
                              ? (nota.numero = 'SN')
                              : (nota.numero = '')
                          "
                          >SEM NOTA</vs-checkbox
                        >
                      </div>
                    </vs-col>
                    <vs-col
                      vs-w="3"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <div class="w-full mx-2">
                        <vs-checkbox
                          color="success"
                          @change="descontoValor($event.type)"
                          v-model="nota.iss_retido"
                          :key="reRenderComponents"
                          class="py-5"
                          >RETER ISS</vs-checkbox
                        >
                      </div>
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    ></vs-col>
                  </vs-row>
                  <vs-row
                    vs-w="12"
                    vs-justify="center"
                    vs-align="flex-start"
                    vs-type="flex"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="12"
                    >
                      <div class="w-full mx-2">
                        <vs-input
                          color="dark"
                          class="w-full"
                          type="text"
                          label="Link da nota fiscal"
                          v-model="nota.link"
                        ></vs-input>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row
                    vs-w="12"
                    vs-justify="center"
                    vs-align="flex-start"
                    vs-type="flex"
                  >
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full mx-2 my-3">
                        <vs-textarea
                          style="text-align: justify"
                          height="100"
                          color="dark"
                          class="m-0"
                          v-model="nota.obs"
                          label="Observação"
                        />
                      </div>
                    </vs-col>
                  </vs-row>
                  <!-- <vs-row vs-justify="flex-start" vs-align="flex-start" vs-type="flex">
                    <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                      <div class="w-full mx-2 my-3">
                        <b-form-file
                          :state="Boolean(nota.arquivo)"
                          name="foto"
                          class="m-0 p-0"
                          @change="updateImg($event)"
                          placeholder="Arquivo da Nota"
                          drop-placeholder="solte o arquivo..."
                        ></b-form-file>
                      </div>
                    </vs-col>
                  </vs-row>-->
                </vx-card>
              </vs-row>
              <vs-divider class="my-0 font-semibold"
                >Datas de Vencimento</vs-divider
              >
              <vs-row
                class="my-3"
                vs-justify="center"
                vs-align="flex-start"
                vs-type="flex"
              >
                <vx-card no-shadow card-border>
                  <vs-row
                    class="mb-2"
                    vs-align="center"
                    vs-justify="flex-start"
                    vs-type="flex"
                    vs-w="12"
                  >
                    <vs-col
                      vs-align="center"
                      vs-justify="center"
                      vs-type="flex"
                      vs-w="4"
                      v-for="(data, index) in datasVencimento"
                      :key="index"
                    >
                      <div class="w-full mx-2">
                        <vs-input
                          :label="'Parcela N° ' + (index + 1)"
                          type="date"
                          v-validate="'required'"
                          color="dark"
                          :name="'datas' + index"
                          v-model="datasVencimento[index].data"
                        />
                        <vs-input
                          class="mt-3"
                          :label="'Valor Parcela N° ' + (index + 1)"
                          type="number"
                          v-validate="'required'"
                          color="dark"
                          :name="'valores' + index"
                          v-model="datasVencimento[index].valor"
                        />
                        <vs-row>
                          <span
                            style="font-size: 12px"
                            class="text-danger ml-1"
                            v-show="errors.has('datas' + index)"
                            >{{ $validators.empty }}</span
                          >
                        </vs-row>
                      </div>
                    </vs-col>
                  </vs-row>
                </vx-card>
              </vs-row>
            </VuePerfectScrollbar>
          </div>
        </div>
      </div>
    </SideBar>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    fatura: null,
    contrato: {},
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      numeroNota: 0,
      nota: {
        dataHoje: this.$formartData.dataHoje(),
      },
      cliente: {},
      desconto: 0,
      datasVencimento: [],
      modal: false,
      reRenderComponents: 0,
      blockActions: false,
      parametros: {},
    }
  },
  methods: {
    //FUNÇÕES INICIAL
    async openModal () {
      await this.$vs.loading({
        container: "#loadingNota" + this.fatura.id,
        scale: 0.6,
      })
      this.blockActions = true
      this.modal = await true
      try {
        this.parametros = await this.$parametros.get()
        await this.getCliente()
        await this.setCamposNota()
        await this.setDatasVencimento()
        await this.descontoValor("change")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.blockActions = false
        await this.$vs.loading.close(
          "#loadingNota" + this.fatura.id + " > .con-vs-loading"
        )
      }
    },

    //FUNÇÕES SET PARA OS VALORES DA NOTA
    //SETAR OS CAMPOS NECESSARIOS PARA CADASTRAR A NOTA
    async setCamposNota () {
      this.nota.iss_retido = this.cliente.iss_retido
      this.nota.data_emissao = this.$formartData.dataFiltro()
      this.nota.valor_liquido = this.fatura.valor - (this.fatura.valor * this.contrato.desconto) / 100
      this.nota.valor_bruto = this.fatura.valor - (this.fatura.valor * this.contrato.desconto) / 100
      this.nota.link = ``
      this.nota.numero = ""
      this.nota.id_status = 2
      this.nota.id_colaborador = localStorage.getItem("id")
      this.nota.id_faturamento = this.fatura.id
      this.nota.id_contrato = this.contrato.id
      this.nota.id_empresa = this.contrato.id_empresa
    },
    //SETAR AS DATAS DE VENCIMENTO DAS PARCELAS, DEPENDENDO DO TIPO DO CONTRATO
    async setDatasVencimento () {
      this.datasVencimento = []
      let dataVencimento

      const hasEntrada = this.contrato.entrada > 0 && this.contrato.id_forma_pagamento_entrada > 0 && this.contrato.data_entrada != null

      if (hasEntrada) {
        dataVencimento = await this.$formartData.formatar(
          this.contrato.data_entrada,
          1
        )
      } else {
        dataVencimento = await this.$formartData.formatar(
          this.$formartData.dataHoje(),
          1
        )
        dataVencimento = await this.$formartData.somarDias(dataVencimento, 30)
      }
      if (
        this.contrato.id_tipo_faturamento == 1 ||
        this.contrato.id_tipo_faturamento == 2 ||
        this.contrato.id_tipo_faturamento == 3 ||
        this.contrato.id_tipo_faturamento == 6
      ) {
        if (hasEntrada) {
          let dataFormatada = await this.$formartData.formatar(
            dataVencimento,
            1
          )
          this.datasVencimento.push({
            data: dataFormatada,
            valor: this.contrato.entrada,
          })
          dataVencimento = await this.$formartData.somarDias(
            dataVencimento,
            30
          )
        }
        for (let i = 1; i <= this.contrato.parcelas; i++) {
          let dataFormatada = await this.$formartData.formatar(
            dataVencimento,
            1
          )
          this.datasVencimento.push({ data: dataFormatada, valor: 0 })
          dataVencimento = await this.$formartData.somarDias(
            dataVencimento,
            30
          )
        }
      } else {
        let dataFormatada = await this.$formartData.formatar(dataVencimento, 1)
        this.datasVencimento.push({ data: dataFormatada, valor: 0 })
      }
      let cont = 1
      for (let data of this.datasVencimento) {
        if (cont == 1 && hasEntrada) {
          1
        } else {
          if (
            this.contrato.entrada > 0 &&
            this.contrato.id_forma_pagamento_entrada > 0
          ) {
            let valorConta = (
              (this.nota.valor_liquido - this.contrato.entrada) /
              (this.datasVencimento.length - 1)
            ).toFixed(2)

            if (cont == this.datasVencimento.length) {
              valorConta = (valorConta - ((valorConta * (this.datasVencimento.length - 1)) - (this.nota.valor_liquido - this.contrato.entrada))).toFixed(2)
            }
            data.valor = valorConta

          } else {
            let valorConta = (this.nota.valor_liquido / this.datasVencimento.length).toFixed(2)
            if (cont == this.datasVencimento.length) {
              valorConta = (valorConta - ((valorConta * this.datasVencimento.length) - this.nota.valor_liquido)).toFixed(2)
            }
            data.valor = valorConta
          }
        }
        cont++
      }
      this.reRenderComponents += 1
    },

    //FUNÇÕES PARA SALVAR/ALTERAR VALORES
    //VALIDAR OS CAMPOS QUE PRECISAM SER PREENCHIDOS
    async submitForm () {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.cadastrarNota()
        } else {
          this.error = true
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    async removerFormatacao () {
      try {
        let valor = this.nota.valor_liquido.toString()
        if (valor && valor.search(",") !== -1 && valor.search(".") !== -1) {
          valor = valor.replace(/\./g, "")
          valor = valor.replace(/,/g, ".")
        } else if (valor && valor.search(",") !== -1) {
          valor = valor.replace(/,/g, ".")
        }
        this.nota.valor_liquido = parseFloat(valor)
      } catch (err) {
        console.log(err)
      }
    },
    //CADASTRO DA NOTA, BOLETO SE TIVER
    async cadastrarNota () {
      await this.$vs.loading({
        container: "#loadingNota" + this.fatura.id,
        scale: 0.6,
      })
      this.blockActions = true
      try {
        await this.removerFormatacao()
        await this.$http.post("redirectSalvarNota", {
          nota: this.nota,
          contrato: this.contrato,
          datas: this.datasVencimento,
          tipo: 0,
        })
        this.faturamento = await this.$http.put(
          "faturamento/" + this.fatura.id,
          { data_faturamento: this.nota.data_emissao, id_status: 3 }
        )
        this.$vs.notify({
          title: "Nota cadastrada com sucesso!",
          text: "Dados da nota salvos com sucesso",
          color: "success",
        })
        await this.$emit("update")
        this.modal = await false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.blockActions = false
        await this.$vs.loading.close(
          "#loadingNota" + this.fatura.id + " > .con-vs-loading"
        )
      }
    },
    //DESCONTO DO ISS
    async descontoValor (evento) {
      if (evento == "change") {
        await this.removerFormatacao()
        if (this.nota.iss_retido) {
          let descontoValor = parseFloat(
            (this.nota.valor_liquido * this.parametros.iss).toFixed(2)
          )
          this.nota.valor_liquido = parseFloat(
            (this.nota.valor_liquido - descontoValor).toFixed(2)
          )
          this.desconto = descontoValor
        } else {
          this.nota.valor_liquido = this.nota.valor_liquido + this.desconto
        }
        console.log(this.desconto)
        this.reRenderComponents += 1
      }
    },
    async getCliente () {
      try {
        this.cliente = await this.$http.get(
          "cliente/" + this.contrato.id_cliente
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
  },
  async mounted () {
    await this.openModal()
  },
  components: {
    ...components,
  },
};
</script>
<style lang="scss">
.scroll-cadastrar-nota {
  max-height: 85vh !important;
}
</style>
