<template>
  <vs-popup :title="'Respostas do Assunto ' + assunto.id" :active.sync="modal">
    <div class="sizeModal w-full">
      <VuePerfectScrollbar class="scroll-modal-respostas" :settings="settings">
        <vs-row vs-type="flex" vs-align="center">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <div class="w-full vs-con-loading__container" id="modalRespostas">
              <div class="vx-row">
                <div class="w-full px-3">
                  <vs-list>
                    <span :key="indextr" v-for="(tr, indextr) in respostas">
                      <vx-card class="my-2 py-3">
                        <template slot="no-body">
                          <div class="item-details w-full px-2">
                            <div class="mb-2 w-full">
                              <vs-row
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                                class=""
                              >
                                <vs-col
                                  vs-w="0.8"
                                  vs-type="flex"
                                  vs-justify="center"
                                  vs-align="center"
                                  class=""
                                >
                                  <span>
                                    <vs-icon
                                      icon="question_answer"
                                      size="20px"
                                      color="success"
                                    ></vs-icon>
                                  </span>
                                </vs-col>
                                <vs-col
                                  vs-w="7.2"
                                  vs-type="flex"
                                  vs-justify="flex-start"
                                  vs-align="center"
                                >
                                  <span style="font-size: 12px"
                                    ><b>{{ tr.colaborador }}&nbsp;</b></span
                                  >
                                </vs-col>
                                <vs-col
                                  vs-w="4"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <span style="font-size: 12px"
                                    ><b>{{ tr.data }}&nbsp;</b></span
                                  >
                                </vs-col>
                              </vs-row>
                              <vs-row
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                                class="mt-2"
                              >
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-start"
                                  vs-align="center"
                                  class=""
                                >
                                  <span style="font-size: 14px">{{
                                    tr.resposta
                                  }}</span>
                                </vs-col>
                              </vs-row>
                            </div>
                          </div>
                        </template>
                      </vx-card>
                    </span>
                  </vs-list>
                </div>
                <vs-progress
                  v-if="respostas.length > 0"
                  :height="6"
                  :percent="100"
                  class="mt-0 mb-2"
                  color="primary"
                ></vs-progress>
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-align="center">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <div style="display: inline-block" class="w-full">
              <vs-textarea
                label="Nova Resposta"
                height="100"
                v-validate="'required'"
                name="resposta"
                color="dark"
                v-model="novaResposta.resposta"
              ></vs-textarea>
            </div>
          </vs-col>
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              class="w-full mx-3"
              color="success"
              size="huge"
              type="relief"
              @click="submitForm"
              >Cadastrar Nova Resposta</vs-button
            >
            <!-- <SuccessButton
              :disabled="blockButton"
              @func="submitForm"
            ></SuccessButton> -->
          </vs-col>
        </vs-row>
      </VuePerfectScrollbar>
    </div>
  </vs-popup>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    assunto: {},
  },
  data () {
    return {
      modal: false,
      blockButton: false,
      respostas: [],
      novaResposta: { resposta: "" },
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000,
      },
    }
  },
  methods: {
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastroNovaResposta()
        } else {
          this.error = true
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    async getRespostasAssuntos () {
      await this.$vs.loading({
        container: "#modalRespostas",
        scale: 0.6
      })
      try {
        this.respostas = await this.$http.post("getRespostasAssuntos", { id: this.assunto.id })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#modalRespostas > .con-vs-loading"
        )
      }
    },
    async cadastroNovaResposta () {
      await this.$vs.loading({
        container: "#modalRespostas",
        scale: 0.6
      })
      try {
        this.novaResposta.id_assunto_cliente = this.assunto.id
        this.novaResposta.id_colaborador = localStorage.getItem("id")
        this.respostas = await this.$http.post("assunto_resposta", this.novaResposta)
        await this.$vs.notify(this.$notify.Success)
        this.novaResposta = {}
        await this.getRespostasAssuntos()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#modalRespostas > .con-vs-loading"
        )
      }
    }
  },
  components: {
    ...components
  },
  async mounted () {
    this.modal = true
    await this.getRespostasAssuntos()
  }
};
</script>
<style scoped>
.sizeModal {
  height: 60vh !important;
}
.scroll-modal-respostas {
  height: 55vh !important;
}
</style>
