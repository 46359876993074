<template>
  <div class="w-full" id="general">
    <div class="p-5 w-full">
      <vx-card class="p-3" :key="reRender">
        <Cabecalho
          id="item1"
          :empresa="empresa"
          :estagio="estagio"
          :contrato="contrato"
        />

        <div class="w-full m-0 p-0">
          <div
            id="item2"
            class="mt-5 p-1 mr-1"
            style="border-style: dotted; border-width: 1px; border-radius: 5px"
          >
            <vs-row>
              <h6 class="mb-2" style="color: black; font-size: 13px">
                <b>CONTRATANTE / CLIENTE</b>
              </h6>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <label class="my-0" style="font-size: 12px; color: black">
                  <span>
                    <b>Nome:</b>
                  </span>
                  {{ cliente.nome_fantasia }}
                  <span v-if="cliente.tipo_pessoa == 2">
                    -
                    <b>Razao social:</b>
                    {{ cliente.razao_social }}
                  </span>
                </label>
                <vs-divider
                  border-style="solid"
                  color="dark"
                  class="py-0 my-0"
                ></vs-divider>
              </vs-col>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <label class="my-0" style="font-size: 12px; color: black">
                  <b>E-mail:</b>
                  {{ cliente.email }} -
                  <b>Telefone:</b>
                  {{ cliente.telefone }}
                </label>
                <vs-divider
                  border-style="solid"
                  color="dark"
                  class="py-0 my-0"
                ></vs-divider>
              </vs-col>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <label class="my-0" style="font-size: 12px; color: black">
                  <b>
                    {{ cliente.tipo_pessoa == 1 ? "CPF" : "CNPJ" }}
                  </b>
                  :
                  {{
                    cliente.cpf_cnpj != null
                      ? cliente.cpf_cnpj
                      : "Não informado"
                  }}
                </label>
                <vs-divider
                  border-style="solid"
                  color="dark"
                  class="py-0 my-0"
                ></vs-divider>
              </vs-col>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <label style="font-size: 12px; color: black" class="my-0 py-0">
                  <b>Endereco(s):</b>
                </label>
                <div v-if="enderecos != undefined && enderecos.length > 0">
                  <label
                    v-for="endereco in enderecos"
                    :key="endereco.id"
                    class="my-0 py-0"
                    style="text-align: justify; font-size: 10px; color: black"
                  >
                    <div
                      class="w-full"
                      v-if="
                        endereco.showPrint == 1 || endereco.faturamento == 1
                      "
                    >
                      <b>&nbsp;-&nbsp;&nbsp;{{ endereco.nome }}:</b>
                      {{ endereco.nomeBairro }},
                      {{ endereco.logradouro }}
                      <span v-if="endereco.numero > 0"
                        >Nº.{{ endereco.numero }}</span
                      >,
                      <span v-if="endereco.complemento != null"
                        >{{ endereco.complemento }},</span
                      >
                      <b>CEP:</b>
                      {{ endereco.cep }}
                      <b>, Segmento:</b>
                      {{ endereco.nomeSegmento }}.
                      <span
                        v-if="
                          endereco.contatos != undefined &&
                            endereco.contatos.length > 0
                        "
                        >CONTATOS:&nbsp;</span
                      >
                      <span v-for="contato in endereco.contatos" :key="contato">
                        <b>{{ contato.nome }}</b>
                        : {{ contato.contato }} |
                      </span>
                    </div>
                  </label>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div v-if="breakStatus[1]" class="html2pdf__page-break"></div>

          <div
            id="item3"
            class="mt-3 p-1 mr-1"
            style="border-style: dotted; border-width: 1px; border-radius: 5px"
          >
            <vs-row>
              <h6 class="mb-2" style="color: black; font-size: 13px">
                <b>CONDIÇÕES GERAIS</b>
              </h6>
              <vs-col
                v-if="controle.nome != null"
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <label class="my-0" style="font-size: 12px; color: black">
                  <vs-icon icon="done" color="success"></vs-icon>
                  Servico de {{ controle.nome }}
                </label>
              </vs-col>
              <vs-divider
                border-style="solid"
                color="dark"
                class="py-0 my-0"
              ></vs-divider>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <label class="my-0" style="font-size: 12px; color: black">
                  <vs-icon icon="done" color="success"></vs-icon>
                  {{ plano.garantia }} Meses de Garantia
                </label>
              </vs-col>
              <vs-divider
                border-style="solid"
                color="dark"
                class="py-0 my-0"
              ></vs-divider>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <label class="my-0" style="font-size: 12px; color: black">
                  <vs-icon icon="done" color="success"></vs-icon>
                  {{ plano.aplicacoes }} Aplicacoes sendo uma a cada
                  {{ plano.periodo_manutencao }} dias
                </label>
              </vs-col>
              <vs-divider
                v-if="
                  contrato.tipo_faturamento != undefined &&
                    contrato.obs_tipo_faturamento
                "
                border-style="solid"
                color="dark"
                class="py-0 my-0"
              ></vs-divider>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
                class="mt-2"
                v-if="
                  contrato.tipo_faturamento != undefined &&
                    contrato.obs_tipo_faturamento
                "
              >
                <vs-icon
                  v-if="contrato.obs_tipo_faturamento"
                  icon="info"
                  class="my-0"
                  color="warning"
                  size="15px"
                ></vs-icon>
                <label
                  class="my-0 mx-1 font-semibold"
                  style="
                    text-align: justify;
                    font-size: 12px;
                    color: black;
                    text-align: justify;
                  "
                  >{{ contrato.obs_tipo_faturamento }}</label
                >
              </vs-col>
              <vs-divider
                v-if="contrato.obs != null"
                border-style="solid"
                color="dark"
                class="py-0 my-0"
              ></vs-divider>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
                class="mt-2"
                v-if="contrato.obs != null"
              >
                <vs-icon
                  icon="info"
                  class="my-0"
                  color="warning"
                  size="15px"
                ></vs-icon>
                <label
                  class="my-0 mx-1 font-semibold"
                  style="
                    text-align: justify;
                    font-size: 12px;
                    color: black;
                    text-align: justify;
                  "
                >
                  <h6
                    v-if="contrato.obs"
                    class="my-0"
                    style="color: black; font-size: 12px"
                  >
                    <span>{{ contrato.obs }}</span>
                  </h6>
                </label>
              </vs-col>
              <vs-divider
                v-if="vendedor && vendedor.nome != undefined"
                border-style="solid"
                color="dark"
                class="py-0 my-0"
              ></vs-divider>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
                class="mt-2"
              >
                <label class="my-0" style="font-size: 12px; color: black">
                  Vendedor:
                  <b>{{ vendedor.nome }}</b>
                  |
                  <b>{{ vendedor.email }} | {{ vendedor.telefone }}</b>
                </label>
              </vs-col>
              <vs-divider
                v-if="orcamentista && orcamentista.id > 0"
                border-style="solid"
                color="dark"
                class="py-0 my-0"
              ></vs-divider>
              <vs-col
                vs-type="flex"
                v-if="orcamentista && orcamentista.id > 0"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
                class="mt-2"
              >
                <label class="my-0" style="font-size: 12px; color: black">
                  Orçamentista:
                  <b>{{ orcamentista.nome }}</b>
                </label>
              </vs-col>
            </vs-row>
          </div>

          <div v-if="breakStatus[2]" class="html2pdf__page-break"></div>

          <div
            id="item4"
            class="mt-3 p-1 mr-1"
            style="border-style: dotted; border-width: 1px; border-radius: 5px"
          >
            <h6 class="mb-2" style="color: black; font-size: 13px">
              <b>LEVANTAMENTO</b>
            </h6>
            <vs-row>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <h6
                  class="my-0"
                  v-if="areas.length > 0"
                  style="color: black; font-size: 12px"
                >
                  <label class="m-0 p-0" style="font-size: 12px; color: black">
                    AREAS:
                    <span v-for="(area, index) in areas" :key="area.id">
                      {{ index > 0 ? "," : "" }} {{ area.qtd }}
                      {{ area.nome }}(s)
                    </span>
                  </label>
                </h6>
                <h6
                  v-if="contrato.obs_tecnica"
                  class="my-0"
                  style="color: black; font-size: 12px"
                >
                  <strong>OBSERVAÇÃO TÉCNICA:</strong>
                  <span style="text-align: justify">{{
                    contrato.obs_tecnica
                  }}</span>
                </h6>
                <h6 class="my-0" style="color: black; font-size: 12px">
                  <span v-if="contrato.fog || contrato.escada">
                    LEVAR
                    <b>{{ contrato.fog ? "FOG" : "" }}</b>
                    {{ contrato.fog && contrato.escada ? " e " : "" }}
                    <b>{{ contrato.escada ? "ESCADA" : "" }}</b>
                  </span>
                </h6>
              </vs-col>
            </vs-row>
          </div>

          <div v-if="breakStatus[3]" class="html2pdf__page-break"></div>

          <div
            v-if="plano.valor"
            id="item5"
            class="mt-3 p-1 mr-1"
            style="border-style: dotted; border-width: 1px; border-radius: 5px"
          >
            <vs-row>
              <h6 class="mb-2" style="color: black; font-size: 13px">
                <b>FORMA DE PAGAMENTO</b>
              </h6>
              <vs-col vs-type vs-justify="center" vs-align="center" vs-w="12">
                <h6 class="my-0" style="color: black; font-size: 16px">
                  <span
                    v-if="
                      plano.entrada > 0 && plano.id_forma_pagamento_entrada > 0
                    "
                    class="pr-1"
                  >
                    <b>Entrada</b> de <b>{{ $currency(plano.entrada) }}</b> no
                    <b>{{ pagamento.entrada }}</b>
                  </span>
                  <span
                    v-if="
                      plano.parcelas > 0 && plano.id_forma_pagamento_parcela > 0
                    "
                    class="pr-1"
                  >
                    <span
                      v-if="
                        plano.entrada > 0 &&
                          plano.id_forma_pagamento_entrada > 0
                      "
                    >
                      mais
                    </span>
                    <b>{{ plano.parcelas }} Parcela(s)</b> de
                    <span v-if="plano.desconto > 0">
                      <span
                        style="
                          text-decoration: line-through;
                          font-size: 14px;
                          color: red;
                        "
                        class="pr-1"
                        >{{
                          $currency(
                            parseFloat(
                              (plano.valor - plano.entrada) / plano.parcelas
                            )
                          )
                        }}</span
                      >
                    </span>

                    <span
                      style="font-size: 16px; color: green"
                      class="font-bold"
                    >
                      <b>
                        {{
                          $currency(
                            parseFloat(
                              (plano.valor -
                                plano.valor * (plano.desconto / 100) -
                                plano.entrada) /
                                plano.parcelas
                            )
                          )
                        }}
                      </b>
                    </span>
                    no
                    <b style="font-size: 14px">{{ pagamento.parcela }}</b>
                  </span>
                </h6>
              </vs-col>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <h6 class="my-0" style="color: black">
                  <span v-if="plano.desconto > 0">
                    <span
                      style="
                        text-decoration: line-through;
                        font-size: 12px;
                        color: black;
                      "
                      class="pr-1"
                      >{{ $currency(plano.valor) }}</span
                    >
                    com
                    <b>{{ plano.desconto }}%</b> de desconto
                    <span
                      style="font-size: 14px; color: black"
                      class="font-bold"
                    >
                      {{
                        $currency(
                          parseFloat(
                            plano.valor - plano.valor * (plano.desconto / 100)
                          )
                        )
                      }}
                    </span>
                  </span>
                  <span v-else>
                    <span
                      style="font-size: 14px; color: black"
                      class="font-bold"
                    >
                      {{ $currency(plano.valor) }}
                    </span>
                  </span>
                </h6>
              </vs-col>
            </vs-row>
          </div>

          <div v-if="breakStatus[4]" class="html2pdf__page-break"></div>

          <div
            id="item6"
            v-if="contrato.id_estagio == 3"
            class="mt-3 p-1 mr-1"
            style="border-style: dotted; border-width: 1px; border-radius: 5px"
          >
            <h6 class="mb-2" style="color: black; font-size: 13px">
              <b>SERVIÇOS</b>
            </h6>
            <vs-row class="mb-2" type="flex" vs-justify="flex-start">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                :key="index"
                vs-w="12"
                class="my-0 p-0"
                v-for="(data, index) in enderecos"
              >
                <div class="w-full m-0 p-0" v-if="data.showPrint == 1">
                  <vs-col vs-w="12" class="my-0 mx-2">
                    <label style="font-size: 12px; color: black">
                      Endereço:
                      <b>{{ data.nome }}</b>
                    </label>
                  </vs-col>
                  <vs-row
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-type="flex"
                  >
                    <vs-col
                      vs-w="2.4"
                      class="my-0 mx-0"
                      :key="index2"
                      v-for="(os, index2) in servicosEndereco[index]"
                    >
                      <div class="mx-1 mb-1">
                        <vx-card class="m-0 p-0 card-border" no-shadow>
                          <vs-row
                            vs-w="12"
                            class="m-0 p-0"
                            vs-justify="center"
                            vs-align="center"
                            vs-type="flex"
                          >
                            <vs-col
                              vs-w="12"
                              class="m-0 p-0"
                              vs-justify="center"
                              vs-align="center"
                              vs-type="flex"
                            >
                              <label class="m-0 p-0">
                                <strong style="font-size: 9px; color: black"
                                  >{{ os.tipo }}: {{ os.id }}</strong
                                >
                              </label>
                            </vs-col>
                          </vs-row>
                          <vs-row>
                            <vs-col
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                              vs-type="flex"
                              class="m-0 p-0"
                            >
                              <label
                                class="m-0"
                                style="color: black; font-size: 9px"
                              >
                                <strong>
                                  {{ os.data_servico_format }}
                                  {{
                                    os.hora_marcada != null
                                      ? os.hora_marcada
                                      : os.nomeTurno
                                  }}
                                </strong>
                              </label>
                            </vs-col>
                          </vs-row>
                        </vx-card>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div v-if="breakStatus[5]" class="html2pdf__page-break"></div>

          <div
            id="item7"
            class="mt-3 p-1 mr-1"
            style="border-style: dotted; border-width: 1px; border-radius: 5px"
          >
            <h6 style="color: black">
              <b>VISTOS</b>
            </h6>
            <div class="container mt-10 mb-5">
              <vs-row vs-w="12" vs-type="flex">
                <vs-col
                  vs-w="12"
                  class
                  vs-align="center"
                  vs-justify="space-between"
                  vs-type="flex"
                >
                  <vs-col vs-w="5.5" vs-type="flex">
                    <vs-divider
                      border-style="solid"
                      color="dark"
                      class="py-0 my-0"
                    ></vs-divider>
                  </vs-col>
                  <vs-col vs-w="5.5" vs-type="flex">
                    <vs-divider
                      border-style="solid"
                      color="dark"
                      class="py-0 my-0"
                    ></vs-divider>
                  </vs-col>
                </vs-col>
                <vs-col
                  vs-w="12"
                  class
                  vs-align="center"
                  vs-justify="space-between"
                  vs-type="flex"
                >
                  <vs-col
                    vs-w="5.5"
                    class
                    vs-align="center"
                    vs-justify="flex-start"
                    vs-type="flex"
                  >
                    <span style="font-size: 12px; color: black">
                      Cliente:
                      <strong>{{ cliente.nome_fantasia }}</strong>
                    </span>
                  </vs-col>
                  <vs-col
                    vs-w="5.5"
                    class
                    vs-align="center"
                    vs-justify="flex-start"
                    vs-type="flex"
                  >
                    <span style="font-size: 12px; color: black">
                      Responsável Legal:
                      <br />
                      <strong style>{{ empresa.razao_social }}</strong>
                    </span>
                  </vs-col>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </div>
      </vx-card>

      <div
        v-if="clausulas != undefined && clausulas.length > 0"
        class="html2pdf__page-break"
      ></div>
      <vx-card
        class="p-3"
        :key="reRender"
        v-if="clausulas != undefined && clausulas.length > 0"
      >
        <Cabecalho :empresa="empresa" :estagio="estagio" :contrato="contrato" />
        <div
          v-if="clausulas != undefined && clausulas.length > 0"
          class="mt-5 p-1 mr-1"
          style="border-style: dotted; border-width: 1px; border-radius: 5px"
        >
          <h6 class="mb-1" style="color: black">
            <b>TERMOS CONTRATUAIS</b>
          </h6>
          <vs-row>
            <vs-col
              vs-type
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="12"
            >
              <div class="mx-3">
                <span v-for="data in clausulas" :key="data.id">
                  <vs-row vs-type="flex">
                    <vs-col
                      vs-w="12"
                      vs-align="center"
                      vs-justify="flex-start"
                      vs-type="flex"
                    >
                      <span
                        style="
                          font-size: 10px;
                          color: black;
                          text-align: justify;
                        "
                      >
                        <b>{{ data.numero }}°</b>
                        {{ data.clausula }}
                      </span>
                    </vs-col>
                  </vs-row>
                </span>
              </div>
            </vs-col>
          </vs-row>
        </div>
        <div
          class="mt-3 p-1 mr-1"
          style="border-style: dotted; border-width: 1px; border-radius: 5px"
        >
          <h6 style="color: black">
            <b>ASSINATURAS</b>
          </h6>
          <div class="container mt-10 mb-5">
            <vs-row vs-w="12" vs-type="flex">
              <vs-col
                vs-w="12"
                class
                vs-align="center"
                vs-justify="space-between"
                vs-type="flex"
              >
                <vs-col vs-w="5.5" vs-type="flex">
                  <vs-divider
                    border-style="solid"
                    color="dark"
                    class="py-0 my-0"
                  ></vs-divider>
                </vs-col>
                <vs-col vs-w="5.5" vs-type="flex">
                  <vs-divider
                    border-style="solid"
                    color="dark"
                    class="py-0 my-0"
                  ></vs-divider>
                </vs-col>
              </vs-col>
              <vs-col
                vs-w="12"
                class
                vs-align="center"
                vs-justify="space-between"
                vs-type="flex"
              >
                <vs-col
                  vs-w="5.5"
                  class
                  vs-align="center"
                  vs-justify="flex-start"
                  vs-type="flex"
                >
                  <span style="font-size: 12px; color: black">
                    Cliente:
                    <strong>{{ cliente.nome_fantasia }}</strong>
                  </span>
                </vs-col>
                <vs-col
                  vs-w="5.5"
                  class
                  vs-align="center"
                  vs-justify="flex-start"
                  vs-type="flex"
                >
                  <span style="font-size: 12px; color: black">
                    Responsável Legal:
                    <br />
                    <strong style="text-transform: uppercase">
                      {{ empresa.razao_social }}
                    </strong>
                  </span>
                </vs-col>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import Cabecalho from "./cabecalho";
export default {
  props: {
    cliente: null,
    enderecos: null,
    vendedor: null,
    orcamentista: null,
    areas: null,
    controle: null,
    contrato: null,
    plano: {},
    pagamento: {},
    estagio: null,
    servicosEndereco: null,
    clausulas: null,
    empresa: null,
    fraseFaturamento: {}
  },
  data() {
    return {
      soma: [],
      breakStatus: [],
      reRender: 0
    };
  },
  methods: {
    async checkStatus(n) {
      let total = 0;
      for (let i = 1; i <= n; i++) {
        let item = "item" + i;
        if (document.getElementById(item) != null) {
          total += parseInt(await this.getHeight(item));
          total >= 800
            ? ((this.breakStatus[i - 1] = 1), (total = 0))
            : (this.breakStatus[i - 1] = 0);
        }
      }
      this.reRender += await 1;
    },

    async getHeight(name) {
      let height = await document.getElementById(name).clientHeight;
      return height;
    }
  },
  async mounted() {
    await this.checkStatus(7);
  },
  components: {
    Cabecalho
  }
};
</script>

<style lang="scss" scoped>
pre {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: -0.3px;
  word-spacing: -1px;
}
.card-border {
  background-color: white;
  border-style: solid;
  border-radius: 4px;
  border-color: #ccc;
  border-width: 1px;
}
#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
