<template>
  <vs-col
    vs-w="2.8"
    class="p-0"
    vs-type="flex"
    vs-justify="flex-end"
    vs-align="center"
  >
    <SideBar
      :title="'Adicionar Servicos para ' + endereco.nome"
      @hide="modalOs = false"
      :active="modalOs"
      size="huger"
      @success="validacao == true ? submitForm() : verificarAgenda()"
      :textSucces="validacao == true ? 'Agendar Reforco' : 'Validar'"
      :visualizar="!buttonStatus"
    >
      <div
        class="w-full p-4 vs-con-loading__container"
        slot="body"
        id="cadastrar"
      >
        <vs-col vs-w="5" class="mr-10">
          <div class="w-full">
            <vs-col vs-w="12"> <Agenda class="m-4"></Agenda> </vs-col
            ><vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <vs-button
                color="warning"
                :disabled="!$atividades.permissoes.includes(17)"
                @click="submitForm()"
                type="relief"
                >Agendar Sem Bloqueios</vs-button
              >
            </vs-col>
          </div>
        </vs-col>
        <vs-col vs-w="6.5">
          <vs-divider class="mt-0" border-style="dotted"
            >Informações gerais do serviço</vs-divider
          >
          <vs-row class="mt-4 mx-4 mb-0" vs-type="flex" vs-w="12">
            <vs-col vs-w="4">
              <vs-row>
                <div>
                  <label style="color: black; font-size: 13px"
                    >Tipo do Serviço</label
                  >
                </div>
              </vs-row>
              <div class="w-full">
                <el-select
                  style="width: 200px"
                  v-model="os.id_tipo"
                  clearable
                  v-validate="'required'"
                  name="tipo"
                  @change="removeValidacao()"
                  :popper-append-to-body="false"
                  filterable
                >
                  <el-option
                    v-for="tipo in tipos"
                    :key="tipo.id"
                    :label="tipo.nome"
                    :value="tipo.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col
              vs-w="4"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div>
                <label style="color: black; font-size: 13px">Turno</label>
                <br />
                <el-select
                  filterable
                  clearable
                  @change="removeValidacao()"
                  class="select-info"
                  placeholder="Selecione..."
                  v-model="os.id_turno"
                  name="turnos"
                  v-validate="os.hora_marcada == null ? 'required' : false"
                  style="width: 200px"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="turno in turnos"
                    class="select-info"
                    :value="turno.id"
                    :label="turno.nome"
                    :key="turno.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col
              vs-w="3"
              class="ml-3"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full">
                <vs-input
                  label="Hora marcada"
                  name="hora_marcada"
                  @change="removeValidacao()"
                  v-validate="os.id_turno == null ? 'required' : false"
                  v-model="os.hora_marcada"
                  v-mask="'##:00'"
                  max="24"
                  type="datetime"
                  placeholder="00:00"
                  class="inputx w-full"
                />
              </div>
            </vs-col>
          </vs-row>

          <vs-row
            class="mt-2 mb-0"
            vs-type="flex"
            vs-justify="flex-start"
            vs-w="12"
          >
            <vs-col
              vs-w="2.5"
              class="mx-3"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <div class="w-full">
                <vs-input
                  label="Tecnicos"
                  name="tecnicos"
                  v-validate="'required'"
                  size="small"
                  v-model="os.tecnicos_exec"
                  @change="removeValidacao()"
                  min="1"
                  class="inputx w-full"
                  type="number"
                />
              </div>
            </vs-col>
            <vs-col
              vs-w="2.5"
              class="mx-3"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full">
                <vs-input
                  label="Horas"
                  size="small"
                  name="hora"
                  v-validate="'required'"
                  v-model="os.horas_exec"
                  @change="removeValidacao()"
                  min="1"
                  type="number"
                  class="inputx w-full"
                />
              </div>
            </vs-col>
            <vs-col
              vs-w="2.5"
              class="mx-3"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
            >
              <div class="w-full">
                <vs-input
                  class="inputx w-full"
                  color="success"
                  v-validate="'required|numeric'"
                  label="Carros."
                  min="0"
                  size="small"
                  type="number"
                  v-model="os.carros"
                  name="carros"
                  @change="removeValidacao()"
                />
              </div>
            </vs-col>
          </vs-row>
          <div id="head_modal_reforco" class="vs-con-loading__container">
            <vs-divider border-style="dotted">Data do serviço</vs-divider>
            <vs-row class="ml-5">
              <vs-col vs-w="4" class="mb-5">
                <vs-input
                  label="Data do serviço"
                  type="date"
                  color="dark"
                  @change="removeValidacao()"
                  name="data"
                  v-validate="'required'"
                  v-model="os.data_competencia"
                />
              </vs-col>
              <vs-col vs-w="5" class="ml-5">
                <div>
                  <vs-input
                    class="mt-3 w-full"
                    id="status"
                    :label="servico.status"
                    style="border-radius: 0px"
                    :color="servico.color"
                    size="small"
                    readonly
                    :value="
                      servico.disponivel == 1
                        ? 'Melhor Data: ' + servico.data
                        : servico.disponivel == 0
                        ? 'Última Data Verificada: ' + servico.ultimaData
                        : 'Aguardando validação'
                    "
                  />
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </div>
    </SideBar>
  </vs-col>
</template>
<script>
import components from "@/components/default/exports.js"
import Agenda from "../../../agenda/Agenda.vue"
export default {
  props: {
    cliente: { required: true },
    contrato: { required: true },
    endereco: { required: true },
  },
  data () {
    return {
      buttonStatus: true,
      diaSelect: [1, 2, 3, 4, 5, 6, 7],
      validacao: false,
      turnos: [
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],
      tipos: [],
      modalOs: false,
      servico: { status: "Aguardando validação", obs: "Aguardando validação" },
      os: {
        id_turno: null,
        horas_exec: 1,
        id_tipo: null,
        tecnicos_exec: 1,
        hora_marcada: null,
        data_competencia: null,
        carros: 0,
      },
    }
  },
  methods: {
    async verificarAgenda () {
      if (
        (this.os.id_turno != null || this.os.hora_marcada != null) &&
        this.os.id_tipo != null &&
        this.os.tecnicos_exec != null &&
        this.os.horas_exec != null &&
        this.os.data_competencia != null
      ) {
        this.buttonStatus = false
        this.validacao = null
        this.$vs.loading({ container: "#head_modal_reforco", scale: 0.9 })
        let horasNecessarias = this.os.horas_exec * this.os.tecnicos_exec
        try {
          let date = this.$formartData.dataHoje()
          this.servico = await this.$http.post("verificarForca", {
            data: this.os.data_competencia,
            id_turno: this.os.id_turno,
            horaMarcada: this.os.hora_marcada,
            horas_exec: this.os.horas_exec,
            tecnicos_exec: this.os.tecnicos_exec,
            dias: this.diaSelect,
            inicial: false,
            carros: this.os.carros,
            hoje: date,
            periodoManutencao: this.contrato.periodo_manutencao,
            datas: [],
          })
          if (this.servico.disponivel == 1) {
            this.os.data_competencia = this.servico.data
            this.servico.color = "success"
          } else {
            this.servico.ultimaData = this.servico.data
            this.validacao = false
            this.servico.color = "danger"
          }
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        }
        this.$vs.loading.close("#head_modal_reforco > .con-vs-loading")
        this.verificarServicos()
        this.buttonStatus = true
      } else {
        this.$vs.notify({
          title: "Erro",
          text: "Preencha os campos",
          color: "danger",
        })
        this.buttonStatus = true
      }
    },
    async verificarServicos () {
      if (this.validacao != false) {
        this.validacao = true
      }
    },
    async removeValidacao () {
      this.validacao = false
    },
    async submitForm () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.cadastrar()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            })
            reject("error")
          }
        })
      })
    },
    async cadastrar () {
      await this.$vs.loading({ container: "#cadastrar", scale: 0.6 })
      this.os.id_cliente = this.contrato.id_cliente
      this.os.id_contrato = this.contrato.id
      this.os.id_endereco = this.endereco.id_endereco
      this.os.obs = this.contrato.obs_tecnica
      try {
        const res = await this.$http.post("cadastrarNovaOs", this.os)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro da OS N°" + res.id,
        }
        await this.$logger(logData)
        this.$vs.notify({
          title: "",
          text: "Reforco cadastrado com successo",
          color: "success",
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      this.$emit("update")
      await this.$vs.loading.close("#cadastrar > .con-vs-loading")
      this.modalOs = false
    },
    async getTipos () {
      try {
        this.tipos = await this.$http.get("os_tipos")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
  },
  async mounted () {
    this.modalOs = true
    await this.getTipos()
  },
  components: {
    ...components,
    Agenda,
  },
};
</script>
