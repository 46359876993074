<template>
  <div id="loader_manage_blueprint" class="vs-con-loading__container">
    <vs-row vs-type="flex" vs-justify="center" vs-align="center">
      <vs-col vs-w="12">
        <input
          type="file"
          ref="fileInput"
          class="hidden"
          accept=".png, .png, .jpg, .jpeg"
          @change="handleClick"
        />
        <div
          @click="$refs.fileInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="
            py-5
            my-4
            cursor-pointer
            text-center
            border-2 border-dashed
            d-theme-border-grey-light d-theme-dark-bg
            text-xl
          "
        >
          <span class="font-bold my-2">{{ title }}</span>
          <feather-icon
            icon="UploadCloudIcon"
            svgClasses="stroke-current text-grey"
            class="block"
          />
          <span>Arraste a Imagem ou </span>
          <span
            class="font-medium text-primary"
            @click.stop="$refs.fileInput.click()"
            >Selecione</span
          >
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
export default {
  props: {
    embedRef: String,
    imageDataRef: String,
    title: String,
  },

  data() {
    return {
      imageData: null,
      embedSrc: null,

      loaderContainer: "#loader_manage_blueprint",
      reRenderFileInput: 0,
      acceptedImageTypes: ["image/jpg", "image/jpeg", "image/png"],
    };
  },

  methods: {
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },

    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$vs.notify({
          title: "Erro ao carregar imagem",
          text: "Selecione somente um arquivo!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return;
      }
      this.uploadFile(files[0]);
    },

    readerData(rawFile) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const buffer = Buffer.from(e.target.result);
          const bufferData = buffer.toString("base64");
          this.imageData = `data:${rawFile.type};base64,${bufferData}`;
          await this.emitImageData();
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },

    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;

      this.uploadFile(rawFile);
    },

    async uploadFile(file) {
      if (!this.imageIsValid(file)) {
        return;
      }

      this.embedSrc = URL.createObjectURL(file);
      await this.emitEmbedFile();
      this.readerData(file);
    },

    imageIsValid(file) {
      if (!this.isImage(file)) {
        this.$vs.notify({
          title: "Erro ao carregar imagem",
          text: "Selecione apenas Imagens",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return false;
      }

      return true;
    },

    isImage(file) {
      return this.acceptedImageTypes.includes(file.type);
    },

    async emitImageData() {
      this.$emit("readed-data", {
        ref: this.imageDataRef,
        data: this.imageData,
      });
    },

    async emitEmbedFile() {
      this.$emit("embed-file", { ref: this.embedRef, url: this.embedSrc });
    },
  },
};
</script>