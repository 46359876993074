<template>
  <div class="vs-con-loading__container" id="headPlano">
    <div v-if="planos.length > 0">
      <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <h4>Planos</h4>
      </vs-col>
      <vs-row type="flex" vs-justify="space-around">
        <vs-col vs-w="3.4" class="px-5" v-for="plano in planos" :key="plano.id">
          <vx-card
            :class="
              plano.selecionado == null
                ? 'plan-card overflow-hidden my-5'
                : 'plan-card-selected overflow-hidden my-5'
            "
          >
            <vs-col
              vs-w="9"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <h6 class="mb-5" style>
                Plano {{ plano.tipo_faturamento }} - {{ plano.id }}
              </h6>
            </vs-col>
            <vs-col
              vs-w="3"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
            >
              <ModalEditarPlano
                :limites_desconto="limites_desconto"
                @update="getPlanos()"
                :plano="plano"
              />
            </vs-col>
            <vs-row>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <h6 class>
                  <vs-icon icon="done" color="success"></vs-icon>
                  {{ plano.validade }} Meses para Validade
                </h6>
              </vs-col>
            </vs-row>
            <vs-divider
              border-style="dotted"
              color
              class="py-0 mb-1 my-0"
            ></vs-divider>
            <vs-col
              vs-type
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="12"
            >
              <h6 class>
                <vs-icon icon="done" color="success"></vs-icon>
                {{ plano.garantia }} Meses de Garantia
              </h6>
            </vs-col>
            <vs-divider
              border-style="dotted"
              color
              class="py-0 mb-1 my-0"
            ></vs-divider>
            <vs-row>
              <vs-col
                vs-type
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="12"
              >
                <h6 class>
                  <vs-icon icon="done" color="success"></vs-icon>
                  {{ plano.aplicacoes }} Aplicacoes sendo uma a cada
                  {{ plano.periodo_manutencao }} dias
                </h6>
              </vs-col>
              <vs-divider
                border-style="dotted"
                color
                class="py-0 mb-1 my-0"
              ></vs-divider>
              <vs-row>
                <vs-col
                  vs-type
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-w="12"
                >
                  <h6 class>
                    <vs-icon icon="done" color="success"></vs-icon>
                    {{ plano.parcelas }} Parcelas
                  </h6>
                </vs-col>
              </vs-row>
            </vs-row>
            <div>
              <div v-if="plano.desconto > 0">
                <vs-row class="mt-5">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                  >
                    <h5
                      class="text-center"
                      style="color: #c0564b; text-decoration: line-through"
                    >
                      {{ $currency(plano.valor) }}
                    </h5>
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                  >
                    <h6 class="text-center">
                      {{ plano.desconto }}% de desconto
                    </h6>
                  </vs-col>
                  <vs-col
                    vs-type
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                  >
                    <h1 class="text-center" style="color: #00bba2">
                      {{
                        $currency(
                          parseFloat(plano.valor) -
                            (parseFloat(plano.valor) *
                              parseFloat(plano.desconto)) /
                              100
                        )
                      }}
                    </h1>
                  </vs-col>
                </vs-row>
              </div>
              <div v-else>
                <vs-row class="mt-4">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                  >
                    <h5 class="text-center">&nbsp;</h5>
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                  >
                    <h5 class="text-center">&nbsp;</h5>
                  </vs-col>
                  <vs-col
                    vs-type
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                  >
                    <h1 class="text-center" style="color: #00bba2">
                      {{ $currency(plano.valor) }}
                    </h1>
                  </vs-col>
                </vs-row>
              </div>
              <vs-row class="mt-4">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                >
                  <vs-button
                    :disabled="
                      $atividades.permissoes.includes(2) &&
                      plano.selecionado == null &&
                      contrato.faturado != 1
                        ? false
                        : true
                    "
                    class="mx-2 w-full"
                    color="success"
                    @click="confirmarEscolhaPlano(plano)"
                    type="relief"
                  >
                    {{
                      plano.selecionado == null
                        ? "Selecionar"
                        : "Plano Selecionado"
                    }}
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </vx-card>
        </vs-col>
      </vs-row>
      <vs-row class="mt-5" type="flex" vs-justify="center" vs-align="center">
        <vs-col
          vs-type="flex"
          v-if="this.contrato.id_estagio == 2 ? true : false"
          vs-justify="center"
          vs-align="center"
          vs-w="3.5"
        >
          <vs-button
            class="w-full"
            color="danger"
            type="relief"
            icon="save"
            @click="imprimirPdf()"
            >IMPRIMIR PROPOSTAS</vs-button
          >
        </vs-col>
      </vs-row>
    </div>
    <div v-else>
      <vs-row type="flex" vs-justify="space-around">
        <vs-col vs-w="12" class="px-5">
          <h1 class="text-center py-10 mt-10">Nenhum plano definido</h1>
          <h1 class="text-center mb-10">
            <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
          </h1>
        </vs-col>
      </vs-row>
    </div>
    <component
      v-bind:is="showImprimirPdf"
      :key="reRenderImprimirPdf"
      :contrato="contrato"
      :empresa="empresa"
      :cliente="cliente"
      :planos="planos"
    ></component>
  </div>
</template>

<script>
import ImprimirPdf from "./../../pdf/pdfPlanos.vue"
import components from "@/components/default/exports.js"
import ModalEditarPlano from "./modalEditarPlano.vue"

export default {
  props: {
    empresa: {},
    limites_desconto: {},
    contrato: { require: true },
    cliente: { require: true },
    status: null
  },
  data () {
    return {
      planos: {},
      showImprimirPdf: null,
      reRenderImprimirPdf: 0,
      currentPlano: {},
      estagio: 2
    }
  },
  methods: {
    async getPlanos () {
      try {
        this.planos = await this.$http.get(
          "getPlanosByContrato/" + this.contrato.id
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async confirmarEscolhaPlano (plano) {
      this.currentPlano = plano
      await this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: `Confirmar Escolha de Plano`,
        acceptText: 'Confirmar',
        cancelText: 'Cancelar',
        text: "Atenção: Ao escolher um plano, o documento é transformado automáticamente em CONTRATO",
        accept: await this.aceitarPlano
      })
    },
    async aceitarPlano () {
      this.$vs.loading()
      try {
        let plano = this.currentPlano
        plano.hoje = await this.$formartData.dataHoje()
        plano.vencimento = await this.$formartData.somarMeses(
          new Date(),
          parseInt(plano.garantia)
        )
        await this.$http.put(`aceitarPlano`, plano)
        await this.logAssunto(plano)
        await this.getPlanos()
        this.$emit("refresh")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.currentPlano = {}
        this.$vs.loading.close()
      }
    },
    async logAssunto (plano) {
      try {
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "update",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto:
            "Plano N°" +
            plano.id +
            " do contrato N°" +
            this.contrato.id +
            " aceito"
        }
        await this.$logger(logData)
        await this.$http.post("assuntoCliente", {
          id_assuntos: 13,
          id_cliente: this.contrato.id_cliente,
          id_contrato: this.contrato.id,
          id_estagio: this.contrato.id_estagio,
          status: 2,
          tipo: 5,
          id_colaborador: window.localStorage.getItem("id"),
          obs: "Plano N°" + plano.id + " aceito"
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async imprimirPdf () {
      this.showImprimirPdf = "ImprimirPdf"
      this.reRenderImprimirPdf = this.reRenderImprimirPdf + 1
    }
  },
  async mounted () {
    await this.$vs.loading({ container: "#headPlano", scale: 0.6 })
    await this.getPlanos()
    await this.$vs.loading.close("#headPlano > .con-vs-loading")
  },
  components: { ...components, ModalEditarPlano, ImprimirPdf }
};
</script>

<style lang="scss" scoped>
.plan-card {
  transition-duration: 50ms;
  background-color: #fbfbfb;
  border-style: solid;
  border-radius: 7px;
  border-color: #000a12;
  border-width: 2px;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-5px);
    border-color: #00bba2;
    background-color: #000a12;
    .grid-view-img {
      opacity: 0.9;
    }
    h6 {
      transition-duration: 500ms;
      color: white;
    }
  }
}
.plan-card-selected {
  transition-duration: 50ms;
  background-color: #fbfbfb;
  border-style: solid;
  border-radius: 7px;
  border-color: #ffca00;
  border-width: 2px;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-5px);
    border-color: #00bba2;
    background-color: #000a12;
    .grid-view-img {
      opacity: 0.9;
    }
    h6 {
      transition-duration: 500ms;
      color: white;
    }
  }
}
</style>
