<template>
  <div>
    <vs-button
      class="mx-1"
      type="relief"
      color="warning"
      icon="settings"
      style="color: white"
      size="small"
      @click="openModal"
      v-if="contrato.have2Bill"
    >
      <b>Alterar Datas</b>
    </vs-button>
    <vs-popup title="Alterar Datas dos Faturamentos" :active.sync="modalSyncAlterarDatas">
      <div class="w-full vs-con-loading__container" id="popupAlterarDatas">
        <div>
          <VuePerfectScrollbar class="scroll-alterar-datas" :settings="settings">
            <vs-row class="mb-2" vs-align="center" vs-justify="center" vs-type="flex" vs-w="12">
              <vs-col
                vs-align="center"
                vs-justify="center"
                vs-type="flex"
                vs-w="12"
                v-for="(fatura, index) in faturasAlterarDatas"
                :key="index"
              >
                <div class="w-full mx-2">
                  <vs-row vs-align="center" vs-justify="center" vs-type="flex" vs-w="12">
                    <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="6">
                      <div class="w-full my-1 mx-2">
                        <vs-input
                          :label="'Fatura N° ' + fatura.parcela"
                          type="date"
                          v-validate="'required'"
                          @blur="setDatas(index)"
                          class="w-full"
                          color="dark"
                          :name="'datas' + index"
                          v-model="fatura.data_competencia"
                        />
                        <vs-row vs-w="12" vs-justify="center" vs-align="center">
                          <span
                            style="font-size: 12px"
                            class="text-danger"
                            v-show="errors.has('datas' + index)"
                          >{{ $validators.empty }}</span>
                        </vs-row>
                      </div>
                    </vs-col>
                    <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="6">
                      <div class="w-full my-1 mx-2">
                        <vs-input
                          :label="'Valor Fatura N° ' + fatura.parcela"
                          type="number"
                          v-validate="'required'"
                          color="dark"
                          class="w-full"
                          :disabled="true"
                          icon="R$"
                          icon-after="true"
                          :name="'valor' + index"
                          v-model="fatura.valorDesconto"
                        />
                        <vs-row vs-w="12" vs-justify="center" vs-align="center">
                          <span
                            style="font-size: 12px"
                            class="text-danger"
                            v-show="errors.has('valor' + index)"
                          >{{ $validators.empty }}</span>
                        </vs-row>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>
          </VuePerfectScrollbar>
          <vs-row class="mt-5" vs-type="flex" vs-align="center">
            <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="center">
              <vs-button
                class="w-full mt-5 ml-1"
                color="success"
                type="relief"
                @click="submitForm"
              >SALVAR</vs-button>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    contrato: {
      require: true
    },
    result: { validation: false }
  },
  data() {
    return {
      modalSyncAlterarDatas: false,
      faturasAlterarDatas: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  methods: {
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.salvar();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },
    async validarData(data) {
      if (!data) {
        this.$vs.notify({
          title: "Data de Faturamento é necessária",
          text: "Preencha o campo!",
          color: "danger"
        });
        return false;
      } else {
        let date = new Date(data);
        date = parseInt(date.getUTCDate());
        if (date > 28) {
          this.$vs.notify({
            title: "Data de Faturamento Inválida",
            text: "Dia não pode ser maior que 28!",
            color: "danger"
          });
          return false;
        } else {
          return true;
        }
      }
    },
    async setDatas(indice) {
      let dataProximaFatura = new Date(
        `${this.faturasAlterarDatas[indice].data_competencia}T12:00:00+0000`
      );
      if (await this.validarData(dataProximaFatura)) {
        for (let i = indice; i < this.faturasAlterarDatas.length; i++) {
          this.faturasAlterarDatas[
            i
          ].data_competencia = await this.$formartData.formatar(
            dataProximaFatura,
            1
          );
          dataProximaFatura = await this.$formartData.somarMeses(
            dataProximaFatura,
            1
          );
        }
      } else {
        this.faturasAlterarDatas[indice].data_competencia = null;
      }
    },
    async openModal() {
      this.modalSyncAlterarDatas = true;
      await this.getFaturasAlterarDatas();
    },
    async salvar() {
      await this.$vs.loading({
        container: "#popupAlterarDatas",
        scale: 0.6
      });
      try {
        await this.$http.post(
          `updateFaturasAlterarDatas`,
          this.faturasAlterarDatas
        );
        this.modalSyncAlterarDatas = false;
        this.$emit("update");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#popupAlterarDatas > .con-vs-loading");
      }
    },
    async getFaturasAlterarDatas() {
      await this.$vs.loading({
        container: "#popupAlterarDatas",
        scale: 0.6
      });
      try {
        this.faturasAlterarDatas = await this.$http.get(
          `getFaturasAlterarDatas/${this.contrato.id}`
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#popupAlterarDatas > .con-vs-loading");
      }
    }
  },
  async mounted() {},
  components: {
    ...components
  }
};
</script>

<style lang="scss">
.scroll-alterar-datas {
  min-height: 20vh !important;
  max-height: 50vh !important;
}
</style>
