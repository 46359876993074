<template>
  <div>
    <div id="headAssunto" class="vs-con-loading__container">
      <VuePerfectScrollbar class="scroll-assunto-contrato" :settings="settings">
        <vx-card no-shadow title="Nova interacao" collapse-action>
          <div>
            <vs-row vs-type="flex" class>
              <vs-col
                vs-w="9.5"
                class="px-2"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex-end"
              ></vs-col>
              <vs-col
                vs-w="2.5"
                class
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex-end"
              >
                <selectTipoLigacao
                  @select-interaction="selectInteraction"
                  :opcao="cliente.id_tipo_ligacao"
                ></selectTipoLigacao>
              </vs-col>
            </vs-row>
            <vs-row vs-type="flex" class>
              <vs-col
                vs-w="2"
                class="px-2"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                v-if="$atividades.permissoes.includes(17)"
              >
                <div style="display: inline-block; margin-right: 20px">
                  <label style="color: black; font-size: 13px">Tipo de contato</label>
                  <br />
                  <el-select
                    filterable
                    clearable
                    @change="alterarTipoContato()"
                    class="select-info"
                    placeholder="Selecione..."
                    name="assunto"
                    v-validate="'required'"
                    v-model="contrato.id_tipo_contato"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="tipo in tipoContatos"
                      class="select-info"
                      :value="tipo.id"
                      :label="tipo.nome"
                      :key="tipo.id"
                    ></el-option>
                  </el-select>
                  <vs-row>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('assunto')"
                      >{{ $validators.empty }}</span
                    >
                  </vs-row>
                </div>
              </vs-col>
              <vs-col
                vs-w="2"
                class="px-2"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div style="display: inline-block; margin-right: 20px">
                  <label style="color: black; font-size: 13px">Assuntos</label>
                  <br />
                  <el-select
                    filterable
                    clearable
                    class="select-info"
                    placeholder="Selecione..."
                    name="assunto"
                    v-validate="'required'"
                    v-model="assuntoCliente.id_assuntos"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="assunto in assuntos"
                      class="select-info"
                      :value="assunto.id"
                      :label="assunto.nome"
                      :key="assunto.id"
                    ></el-option>
                  </el-select>
                  <vs-row>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('assunto')"
                      >{{ $validators.empty }}</span
                    >
                  </vs-row>
                </div>
              </vs-col>
              <vs-col
                vs-w="2"
                class="px-2"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div style="display: inline-block; margin-right: 20px">
                  <label style="color: black; font-size: 13px">Status</label>
                  <br />
                  <el-select
                    color="dark"
                    filterable
                    clearable
                    v-validate="'required'"
                    class="w-full"
                    name="status"
                    :popper-append-to-body="false"
                    v-model="assuntoCliente.status"
                  >
                    <el-option
                      v-for="status in statusAssunto"
                      :key="status.key"
                      :label="status.nome"
                      :value="status.value"
                    ></el-option>
                  </el-select>
                  <vs-row>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('status')"
                      >{{ $validators.empty }}</span
                    >
                  </vs-row>
                </div>
              </vs-col>
              <vs-col
                vs-w="2"
                class="px-2"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <vs-input
                  label="lembrete"
                  v-model="assuntoCliente.data_marcada"
                  type="date"
                />
              </vs-col>
              <vs-col
                vs-w="4"
                class="px-10"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <vs-textarea
                  label="Observacao"
                  v-model="assuntoCliente.obs"
                  counter="250"
                />
              </vs-col>
            </vs-row>
            <vs-button
              class="w-full"
              color="success"
              type="relief"
              :disabled="blockButton"
              @click="submitForm()"
              >Adicionar</vs-button
            >
          </div>
        </vx-card>
        <vs-progress
          v-if="assuntosCliente.length > 0"
          :height="6"
          :percent="100"
          color="primary"
        ></vs-progress>
        <vs-col
          vs-w="12"
          vs-type
          vs-justify="flex-start"
          vs-align="flex-start"
          class="my-3"
          :key="reRender"
        >
          <div class="pl-3" v-if="assuntosCliente.length > 0">
            <VuePerfectScrollbar
              class="p-1 sizeScrollListaAssunto"
              :settings="settings"
            >
              <span :key="indextr" v-for="(tr, indextr) in assuntosCliente">
                <vx-card
                  class="assuntoCliente-card overflow-hidden mb-2"
                  card-border
                  no-shadow
                >
                  <template slot="no-body">
                    <div class="px-2">
                      <div class="my-2">
                        <vs-row vs-type vs-justify="center" vs-align="center">
                          <span class="w-full" @click="openModalRespostas(tr)">
                            <vs-row
                              vs-type
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <vs-col vs-w="0.5">
                                <span
                                  v-if="tr.tipo == 1"
                                  style="font-size: 16px"
                                  class="mr-2"
                                >
                                  <vs-icon
                                    icon="add_ic_call"
                                    size="large"
                                    color="success"
                                  ></vs-icon>
                                </span>
                                <span
                                  v-else-if="tr.tipo == 2"
                                  style="font-size: 16px"
                                  class="mr-2"
                                >
                                  <vs-icon
                                    icon="phone_callback"
                                    size="large"
                                    color="warning"
                                  ></vs-icon>
                                </span>
                                <span
                                  v-else-if="tr.tipo == 3"
                                  style="font-size: 16px"
                                  class="mr-2"
                                >
                                  <vs-icon
                                    icon="streetview"
                                    size="large"
                                    color="blue"
                                  ></vs-icon>
                                </span>
                                <span
                                  v-else-if="tr.tipo == 4"
                                  style="font-size: 16px"
                                  class="mr-2"
                                >
                                  <vs-icon
                                    icon="drive_eta"
                                    size="large"
                                    color="danger"
                                  ></vs-icon>
                                </span>
                                <span
                                  v-else
                                  style="font-size: 16px"
                                  class="mr-2"
                                >
                                  <vs-icon
                                    icon="insert_drive_file"
                                    size="large"
                                    color="black"
                                  ></vs-icon>
                                </span>
                              </vs-col>
                              <vs-col vs-w="10">
                                <vs-col vs-w="12">
                                  <span style="font-size: 12px; color: ">
                                    <b>
                                      {{ tr.created_at }}
                                      <span
                                        v-if="tr.data_marcada"
                                        class="font-bold"
                                      >
                                        - Lembrete: {{ tr.data_marcada }}
                                      </span>
                                      <span v-if="tr.colaborador">
                                        - <b>{{ tr.colaborador }}</b></span
                                      >
                                      <span v-if="tr.id_contrato > 0">
                                        - {{ tr.estagio }} N°{{
                                          tr.id_contrato
                                        }}</span
                                      >
                                    </b>
                                  </span>
                                </vs-col>
                                <vs-col vs-w="12">
                                  <span style="font-size: 16px">
                                    <b>{{ tr.assunto }}</b>
                                    : {{ tr.obs }}
                                  </span>
                                </vs-col>
                              </vs-col>
                            </vs-row>
                          </span>
                          <vs-col
                            class="mt-2"
                            vs-w="1.5"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <div
                              class="w-full"
                              @click="changeStatus(tr)"
                              style="cursor: pointer"
                            >
                              <vs-row
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                                ><vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="center"
                                  vs-align="center"
                                >
                                  <Badge
                                    :color="
                                      tr.status == '0' ? '#0044f2' : 'success'
                                    "
                                    :text="
                                      tr.status == '0'
                                        ? 'Em processo'
                                        : 'Concluido'
                                    "
                                  ></Badge>
                                </vs-col>
                              </vs-row>
                            </div>
                          </vs-col>
                        </vs-row>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </span>
            </VuePerfectScrollbar>
          </div>
        </vs-col>
      </VuePerfectScrollbar>
    </div>
    <component
      v-bind:is="modalRespostas"
      :key="reRenderModalRespostas"
      :assunto="selectedAssunto"
    />
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
import selectTipoLigacao from "./../selectTipoLigacao.vue";
import modalRespostas from "./modalRespostas.vue";

export default {
  props: {
    cliente: {
      require: true
    },
    contrato: null,
    size: {},
    edit: true
  },
  data() {
    return {
      modalRespostas: "",
      reRenderModalRespostas: 0,
      selectedAssunto: {},

      assuntosCliente: {},
      assuntos: {},
      statusAssunto: [
        { value: 0, nome: "Em processo", key: 1 },
        { value: 1, nome: "Concluido", key: 2 }
      ],
      assuntoCliente: {
        id_assuntos: null,
        status: 0,
        obs: "",
        data_marcada: "",
        tipo: ""
      },
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000
      },
      blockButton: false,
      reRender: 0,
      tipoContatos: [],
      idTipoContato: null
    };
  },
  methods: {
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.addAssuntoCliente();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },
    async changeStatus(doc) {
      if (doc.id_colaborador + "" == localStorage.getItem("id") + "") {
        await this.$vs.loading({ container: "#headAssunto", scale: 0.6 });
        try {
          await this.$http.post("markLembreteAsRead", {
            id: doc.id,
            status: !doc.status
          });
          await this.getAssuntosContrato();
          this.reRender += 1;
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          await this.$vs.loading.close("#headAssunto > .con-vs-loading");
        }
      } else {
        this.$vs.notify({
          title: "Acao Bloqueada",
          text:
            "Apenas o usuario que cadastrou este assunto pode o concluir ou reverte sua conclusao",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
      }
    },
    async addAssuntoCliente() {
      this.blockButton = false;
      await this.$vs.loading({ container: "#headAssunto", scale: 0.6 });
      try {
        this.assuntoCliente.id_cliente = this.cliente.id;
        this.assuntoCliente.id_colaborador = localStorage.id;
        if (this.contrato) {
          this.assuntoCliente.id_contrato = this.contrato.id;
          this.assuntoCliente.id_estagio = this.contrato.id_estagio;
        }
        const res = await this.$http.post(
          "assuntoCliente",
          this.assuntoCliente
        );
        let logData = {
          id_colaborador: localStorage.id,
          funcao: "cadastrar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro do assunto N°" + res.id
        };
        await this.$logger(logData);
        if (this.contrato) {
          await this.getAssuntosContrato();
        } else {
          await this.getAssuntosCliente();
        }
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.reRender = (await this.reRender) + 1;
        this.blockButton = false;
        await this.$vs.loading.close("#headAssunto > .con-vs-loading");
      }
    },
    async getAssuntos() {
      try {
        this.assuntos = await this.$http.get("assunto");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getAssuntosContrato() {
      try {
        this.assuntosCliente = await this.$http.get(
          "getAssuntosContrato/" + this.contrato.id
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async selectInteraction(tipo) {
      this.assuntoCliente.tipo = tipo;
    },
    async openModalRespostas(assunto) {
      this.selectedAssunto = assunto;
      this.modalRespostas = "modalRespostas";
      this.reRenderModalRespostas += 1;
    },
    async getTipoContatos(){
      try{
        this.tipoContatos = await this.$http.get("getTipoContatos")
      }catch(erro){
        const error = this.$httpErrors(erro);
        this.$vs.notify(error);
      }
    },
    async alterarTipoContato(){
      await this.$vs.loading({ container: "#headAssunto", scale: 0.6 });
      try{
        await this.$http.put(`contrato/${this.contrato.id}`, { id_tipo_contato: this.contrato.id_tipo_contato })
        await this.$vs.notify({title: "Sucesso", text: "Tipo de contato alterado com sucesso", color: "success"})
      }catch(erro){
        const error = this.$httpErrors(erro); 
        this.$vs.notify(error);
      }finally{
        await this.$vs.loading.close("#headAssunto > .con-vs-loading");
      }
    }
  },
  async mounted() {
    await this.$vs.loading({ container: "#headAssunto", scale: 0.6 });
    await this.getAssuntos();
    await this.getAssuntosContrato();
    await this.getTipoContatos()
    await this.$vs.loading.close("#headAssunto > .con-vs-loading");
  },
  components: { ...components, selectTipoLigacao, modalRespostas }
};
</script>
<style lang="scss">
.scroll-assunto-contrato {
  max-height: 80vh !important;
}
.sizeScrollListaAssunto {
  height: 25vh !important;
}
.vx-card__title h4 {
  font-weight: 600;
  text-transform: uppercase !important;
}
.feather-chevron-up {
  width: 30px !important;
  height: 30px !important;
}
.assuntoCliente-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
