<template>
  <div class="w-full">
    <vs-popup :title="'Retornos do Serviço'" :active.sync="modalOs">
      <VuePerfectScrollbar class="scroll-editar-obs" :settings="settings">
        <div class="w-full vs-con-loading__container" id="obsServico">
          <vs-row
            vs-type="flex"
            class="mt-1 mb-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="12"
              v-for="servico in servicos"
              :key="servico.id"
            >
              <vx-card
                class="card-lev overflow-hidden my-2"
                :card-background="servico.status > 0 ? 'success' : 'danger'"
              >
                <template slot="no-body">
                  <div class="item-details px-2">
                    <div class="my-2">
                      <vs-col
                        vs-w="11"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <div class="w-full">
                          <vs-row vs-w="12" vs-type="flex">
                            <vs-col vs-w="12" vs-type="flex">
                              <h5
                                style="color: white !important"
                                class="w-full"
                              >
                                Retorno:
                                <b>{{ servico.id_retorno }}</b> / Data de
                                criação:
                                <b>{{ servico.data_criacao }}</b>
                              </h5>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              v-if="servico.data_inicio && servico.data_fim"
                            >
                              <span
                                style="color: white !important; font-size: 16px"
                                class="w-full"
                              >
                                Data inicio:
                                <b>{{ servico.data_inicio }}</b> / Data fim:
                                <b>{{ servico.data_fim }}</b>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              v-if="servico.data_nao_exec"
                            >
                              <span
                                style="color: white !important; font-size: 16px"
                                class="w-full"
                              >
                                Data retorno não executado:
                                <b>{{ servico.data_nao_exec }}</b>
                              </span>
                            </vs-col>
                            <vs-col vs-w="12" vs-type="flex">
                              <div
                                style="color: white !important; font-size: 16px"
                              >
                                <div v-if="servico.obs">
                                  <span class="w-full">
                                    Observação:
                                    <b>{{ servico.obs }}</b>
                                  </span>
                                </div>
                                <div v-if="servico.obs_interna">
                                  <span class="w-full">
                                    Observação Interna:
                                    <b>{{ servico.obs_interna }}</b>
                                  </span>
                                </div>
                                <div v-if="servico.obs_proximo_servico">
                                  <span class="w-full">
                                    Observação Prox. Serviço:
                                    <b>{{ servico.obs_proximo_servico }}</b>
                                  </span>
                                </div>
                                <div v-if="servico.obs_pos_venda">
                                  <span class="w-full">
                                    Observação Pós-venda:
                                    <b>{{ servico.obs_pos_venda }}</b>
                                  </span>
                                </div>
                              </div>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                      <vs-col
                        vs-w="1"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        class
                      >
                        <span class="mb-2" v-if="servico.status > 0">
                          <vs-button
                            class="px-0 py-0 m-0"
                            icon="remove_red_eye"
                            color="primary"
                            style="color: black"
                            size="small"
                            type="filled"
                            @click="redirectBlank(servico)"
                          ></vs-button>
                        </span>
                        <span @click="modalOs = false" class="mb-2" v-else>
                          <OsNaoExecRetorno :os="servico" />
                        </span>
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
      </VuePerfectScrollbar>
    </vs-popup>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"
import OsNaoExecRetorno from "../../../agenda-servicos/os/components/osNaoExecRetorno.vue"

export default {
  props: {
    osSelecionado: {},
    contrato: null,
  },
  data () {
    return {
      modalOs: false,
      servicos: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1500,
      },
      relatorioUrl: ""
    }
  },
  methods: {
    async openModal () {
      this.modalOs = true
      this.getRetornosOs()
    },
    async getRetornosOs () {
      // await this.$vs.loading({
      //   container: "#servicosEndereco" + this.index,
      //   scale: 0.6
      // });
      try {
        const res = await this.$http.post("getRetornosOSContrato", {
          os: this.osSelecionado.id,
        })
        this.servicos = res.servicos
        this.relatorioUrl = res.relatorioUrl
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        // await this.$vs.loading.close(
        //   "#servicosEndereco" + this.index + " > .con-vs-loading"
        // );
      }
    },
    async redirectBlank (os) {
      const link = `${this.relatorioUrl}/?os=${os.id}`
      var a = document.createElement("a")
      a.target = "_blank"
      a.href = link
      a.click()
    },
  },

  async mounted () {
    await this.openModal()
  },
  components: {
    ...components,
    OsNaoExecRetorno,
  },
};
</script>

<style lang="scss" scoped>
#status {
  border-radius: 20px !important;
}
.vs-input-success #status {
  border: 1px solid rgba(var(--vs-success), 1) !important;
}
.vs-input-danger #status {
  border: 1px solid rgba(var(--vs-danger), 1) !important;
}
.scroll-editar-obs {
  max-height: 80vh !important;
}
.card-lev {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-1.5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
///whatsapp confirmação. Tirar "deseja remarcar" deixar --> "deseja confirmar" // QUBERAR LINHA
</style>

