<template>
  <vs-row class="my-3" vs-justify="center" vs-align="center" vs-type="flex">
    <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
      <vx-card no-shadow card-border>
        <vs-col vs-w="12" class="mt-1" vs-type="flex" vs-justify="center" vs-align="center">
          <label style="font-size:14px;color: black" class="font-semibold mb-1">TOMADOR DO SERVIÇO</label>
        </vs-col>
        <div class="w-full">
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <label style="font-size:13px;color: black" class="mb-1">
              <b>Razão social:</b>
              {{ cliente.razao_social ? cliente.razao_social : 'Não Informado' }}
            </label>
          </vs-col>
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <label style="font-size:13px;color: black" class="mb-1">
              <b>Nome Fantasia:</b>
              {{ cliente.nome_fantasia ? cliente.nome_fantasia : 'Não Informado' }}
            </label>
          </vs-col>
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <label style="font-size:13px;color: black" class="mb-1">
              <b>CNPJ:</b>
              {{ cliente.cpf_cnpj ? cliente.cpf_cnpj : 'Não Informado' }}
            </label>
          </vs-col>
        </div>
      </vx-card>
    </vs-col>
  </vs-row>
</template>
<script>
export default {
  props: {
    cliente: {}
  }
}
</script>
