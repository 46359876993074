<template>
  <div class="p-10">
    <div class="w-full" v-if="id_segmento == 1">
      <div class="w-full back">
        <img src="./condominio-1.png" />
        <div class="html2pdf__page-break"></div>
      </div>
      <div class="w-full back">
        <img src="./condominio-2.png" />
        <div class="html2pdf__page-break"></div>
      </div>
    </div>
    <div class="w-full" v-if="id_segmento == 2">
      <div class="w-full back">
        <img src="./residencia-1.png" />
        <div class="html2pdf__page-break"></div>
      </div>
      <div class="w-full back">
        <img src="./residencia-2.png" />
        <div class="html2pdf__page-break"></div>
      </div>
    </div>
    <div class="w-full" v-if="id_segmento == 3">
      <div class="w-full back">
        <img src="./comercio-alimenticio-1.png" />
        <div class="html2pdf__page-break"></div>
      </div>
      <div class="w-full back">
        <img src="./comercio-alimenticio-2.png" />
        <div class="html2pdf__page-break"></div>
      </div>
    </div>
    <div class="w-full" v-if="id_segmento == 4">
      <div class="w-full back">
        <img src="./empresa-comercial-1.png" />
        <div class="html2pdf__page-break"></div>
      </div>
      <div class="w-full back">
        <img src="./empresa-comercial-2.png" />
        <div class="html2pdf__page-break"></div>
      </div>
    <div class="w-full" v-if="id_segmento == 5">
      <div class="w-full back">
        <img src="./industria-1.png" />
        <div class="html2pdf__page-break"></div>
      </div>
      <div class="w-full back">
        <img src="./industria-2.png" />
        <div class="html2pdf__page-break"></div>
      </div>
    </div>
    <div class="w-full" v-if="id_segmento == 7">
      <div class="w-full back">
        <img src="./restaurante-1.png" />
        <div class="html2pdf__page-break"></div>
      </div>
      <div class="w-full back">
        <img src="./restaurante-2.png" />
        <div class="html2pdf__page-break"></div>
      </div>
    </div>   
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id_segmento: 0
  },
  mounted () {
  }
};
</script>

<style lang="scss">
.back {
  width: 100% !important;
  height: 100% !important;
}
</style>
