var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs-con-loading__container",attrs:{"id":"headServico"}},[(_vm.enderecos.length > 0)?_c('div',[_c('vs-row',{staticClass:"mb-3",attrs:{"vs-type":"flex"}},[_c('vs-col',{attrs:{"vs-w":"2","vs-type":"flex","vs-justify":"flex-start","vs-align":"flex-start"}},[_c('h4',{staticClass:"px-2"},[_c('strong',[_vm._v("ENDERECO")])])]),_c('vs-col',{attrs:{"vs-w":"8","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('h4',{style:(_vm.servicosAssociadas == _vm.aplicacoes
              ? 'color:green'
              : _vm.servicosAssociadas > _vm.aplicacoes
              ? 'color:red'
              : 'color: #0f4084')},[_vm._v(" "+_vm._s(_vm.servicosAssociadas == _vm.aplicacoes ? "Quantidade de serviços igual a negociada" : _vm.servicosAssociadas > _vm.aplicacoes ? _vm.servicosAssociadas - _vm.aplicacoes + " serviços mais que o negociado" : "Falta " + (parseInt(_vm.aplicacoes) - parseInt(_vm.servicosAssociadas)) + " serviços")+" ")])]),_c('vs-col',{attrs:{"vs-w":"2","vs-type":"flex","vs-justify":"flex-end","vs-align":"flex-end"}},[_c('SuccessButton',{attrs:{"size":"small","disabled":!_vm.$atividades.permissoes.includes(2) &&
            !_vm.$atividades.permissoes.includes(8),"text":"Associar"},on:{"func":function($event){return _vm.associarEnderecosServicos()}}})],1)],1),(_vm.enderecos.length > 0)?_c('div',{key:_vm.reRender,staticClass:"w-full"},[_c('vs-table',{attrs:{"data":_vm.enderecos},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
return _vm._l((data),function(tr,index){return _c('vs-tr',{key:index,staticClass:"w-full list-border",attrs:{"state":tr.id_contrato_endereco > 0 &&
              tr.servicos > 0 &&
              tr.status_botao == 1
                ? 'success'
                : null,"data":tr}},[_c('vs-td',[_c('vs-input',{staticClass:"pr-5",staticStyle:{"width":"100px"},attrs:{"type":"number","min":"0","disabled":!_vm.$atividades.permissoes.includes(2) &&
                  !_vm.$atividades.permissoes.includes(8),"name":"servicos"},on:{"change":function($event){return _vm.calServicosAssociados(_vm.enderecos)}},model:{value:(tr.servicos),callback:function ($$v) {_vm.$set(tr, "servicos", $$v)},expression:"tr.servicos"}})],1),_c('vs-td',[_c('span',{staticStyle:{"font-size":"16px"}},[_c('b',[_vm._v(_vm._s(tr.nome))])])]),_c('vs-td',{key:_vm.reRenderButtons},[_c('div',{staticClass:"w-full"},[_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[(!(tr.id_contrato_endereco > 0 && tr.servicos > 0))?_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vx-tooltip',{attrs:{"color":"dark","position":"left","text":"Adicione serviços ao endereço."}},[_c('vs-button',{staticStyle:{"margin":"auto !important"},attrs:{"type":"relief","color":"dark","disabled":!_vm.$atividades.permissoes.includes(2) &&
                          !_vm.$atividades.permissoes.includes(8),"icon":"priority_high"}})],1)],1):_vm._e(),(
                      tr.id_contrato_endereco > 0 &&
                      tr.servicos > 0 &&
                      tr.status_botao == 0
                    )?_c('vs-col',{attrs:{"vs-type":"flex","vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('vx-tooltip',{attrs:{"position":"left","color":"warning","text":"Agendar serviços - por horários"}},[_c('vs-button',{staticStyle:{"margin":"auto !important"},attrs:{"color":"warning","icon":"schedule","disabled":!_vm.$atividades.permissoes.includes(2) &&
                          !_vm.$atividades.permissoes.includes(8),"type":"relief"},on:{"click":function($event){return _vm.openModalServicosAgenda(
                            'ModalServicosAgendaPorHorario',
                            tr,
                            index
                          )}}})],1)],1):_vm._e(),(
                      tr.id_contrato_endereco > 0 &&
                      tr.servicos > 0 &&
                      tr.status_botao == 0
                    )?_c('vs-col',{attrs:{"vs-type":"flex","vs-w":"4","vs-justify":"center","vs-align":"center"}},[_c('vx-tooltip',{attrs:{"position":"left","color":"warning","text":"Agendar serviços."}},[_c('vs-button',{staticStyle:{"margin":"auto !important"},attrs:{"color":"warning","icon":"event_note","disabled":!_vm.$atividades.permissoes.includes(2) &&
                          !_vm.$atividades.permissoes.includes(8),"type":"relief"},on:{"click":function($event){return _vm.openModalServicosAgenda(
                            'ModalServicosAgenda',
                            tr,
                            index
                          )}}})],1)],1):_vm._e()],1),_c('vs-row',{attrs:{"vs-type":"flex","vs-w":"12","vs-justify":"center","vs-align":"center"}},[(
                      tr.id_contrato_endereco > 0 &&
                      tr.servicos > 0 &&
                      tr.status_botao == 1
                    )?_c('vs-col',{attrs:{"vs-type":"flex","vs-w":"12"}},[_c('div',{staticClass:"w-full"},[_c('vs-row',{attrs:{"vs-w":"12","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"3","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vx-tooltip',{attrs:{"position":"left","color":"warning","text":"Gerenciar Dispositivos"}},[_c('vs-button',{staticStyle:{"margin":"auto !important"},attrs:{"color":"warning","icon":"app_settings_alt","disabled":!_vm.$atividades.permissoes.includes(2) &&
                                !_vm.$atividades.permissoes.includes(8),"type":"relief"},on:{"click":function($event){return _vm.openModalGerenciarDispositivos(tr)}}})],1)],1),_c('vs-col',{attrs:{"vs-w":"3","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vx-tooltip',{attrs:{"position":"left","color":"warning","text":"Editar Agenda"}},[_c('vs-button',{staticStyle:{"margin":"auto !important"},attrs:{"color":"warning","icon":"edit","disabled":!_vm.$atividades.permissoes.includes(2) &&
                                !_vm.$atividades.permissoes.includes(8),"type":"relief"},on:{"click":function($event){return _vm.openModalServicosAgenda(
                                  'ModalOsEditarAgenda',
                                  tr,
                                  index
                                )}}})],1)],1),_c('vs-col',{attrs:{"vs-w":"3","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vx-tooltip',{attrs:{"color":"success","text":"Adicionar serviços extras.","position":"left"}},[_c('vs-button',{staticStyle:{"margin":"auto !important"},attrs:{"color":"success","icon":"add","disabled":!_vm.$atividades.permissoes.includes(2) &&
                                !_vm.$atividades.permissoes.includes(8),"type":"relief"},on:{"click":function($event){return _vm.openModalOsReforco(tr)}}})],1)],1),_c('vs-col',{attrs:{"vs-w":"3","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vx-tooltip',{attrs:{"color":"danger","text":"Limpar a agenda do endereco?","position":"left"}},[_c('el-popconfirm',{attrs:{"confirmButtonText":"Excluir","cancelButtonText":"Cancelar","icon":"el-icon-delete","iconColor":"red","title":'Limpar agenda'},on:{"confirm":function($event){return _vm.limparAgenda(tr.id_endereco, tr.id_contrato)}}},[_c('vs-button',{staticStyle:{"margin":"auto !important"},attrs:{"slot":"reference","color":"danger","icon":"delete","disabled":!_vm.$atividades.permissoes.includes(3) &&
                                  !_vm.$atividades.permissoes.includes(9),"type":"relief"},slot:"reference"})],1)],1)],1)],1)],1)]):_vm._e()],1)],1)]),_c('template',{slot:"expand"},[_c('ServicosEnderecos',{attrs:{"index":index,"cliente":_vm.cliente,"contrato":_vm.contrato,"item":tr},on:{"update":function($event){return _vm.getEnderecosParaAssociar()}}})],1)],2)})}}],null,false,689577232)},[_c('template',{slot:"thead"},[_c('vs-th',{staticStyle:{"width":"20%"},attrs:{"sort-key":"qtd"}},[_vm._v("Quantidade de Serviços")]),_c('vs-th',{staticStyle:{"width":"60%"},attrs:{"sort-key":"local"}},[_vm._v("Local do Serviço")]),_c('vs-th',{staticStyle:{"width":"20%"}})],1)],2)],1):_vm._e(),_c('div',_vm._l((_vm.totalizadores),function(tipo){return _c('span',{key:tipo.tipo},[_vm._v(_vm._s(tipo.tipo)+": "),_c('b',[_vm._v(_vm._s(tipo.qtd))]),_vm._v(" | ")])}),0)],1):_c('div',[_c('vs-row',{attrs:{"type":"flex","vs-justify":"space-around"}},[_c('vs-col',{staticClass:"px-5",attrs:{"vs-w":"12"}},[_c('h1',{staticClass:"text-center py-10 mt-10"},[_vm._v("Nenhum Endereco de servico")]),_c('h1',{staticClass:"text-center mb-10"},[_c('vs-icon',{attrs:{"size":"large","icon":"sentiment_dissatisfied"}})],1)])],1)],1),_c(_vm.modalAgendar,{key:_vm.reRenderModalAgendar,tag:"component",attrs:{"cliente":_vm.cliente,"endereco":_vm.endereco,"contrato":_vm.contrato,"servicoEndereco":_vm.servicoEnderecoOs},on:{"update":function($event){return _vm.getEnderecosParaAssociar()}}}),_c(_vm.modalOsReforco,{key:_vm.reRenderModalOsReforco,tag:"component",attrs:{"cliente":_vm.cliente,"endereco":_vm.endereco,"contrato":_vm.contrato},on:{"update":function($event){return _vm.getEnderecosParaAssociar()}}}),_c(_vm.modalManageDevices,{key:_vm.reRenderModalManageDevices,tag:"component",attrs:{"client":_vm.cliente,"contract":_vm.contrato,"address":_vm.endereco},on:{"update":function($event){return _vm.getEnderecosParaAssociar()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }