<template>
  <div>
    <vs-popup
      :background-color-popup="colorx"
      title="RESUMO DA IMPRESSÃO"
      :active.sync="resumoModal"
      class="vs-con-loading__container"
      id="loading"
    >
      <div class="w-full">
        <vs-tabs
          position="top"
          color="primary"
          alignment="fixed"
          id="profile-tabs"
          class
        >
          <vs-tab
            :label="'Plano ' + planos[0].tipo_faturamento"
            class="p-0 m-0"
          >
            <VuePerfectScrollbar class="scroll-pdf-planos" :settings="settings">
              <resumoCard
                :orcamentista="orcamentista"
                :cliente="cliente"
                :areas="areas"
                :controle="controle"
                :plano="planos[0]"
                :vendedor="vendedor"
                :servicosEndereco="servicosEndereco"
                :enderecos="enderecos"
                :contrato="contrato"
                :clausulas="clausulas[planos[0].id_tipo_faturamento]"
              ></resumoCard>
            </VuePerfectScrollbar>
          </vs-tab>
          <vs-tab
            :label="'Plano ' + planos[1].tipo_faturamento"
            class="p-0 m-0"
          >
            <VuePerfectScrollbar class="scroll-pdf-planos" :settings="settings">
              <resumoCard
                :orcamentista="orcamentista"
                :cliente="cliente"
                :areas="areas"
                :controle="controle"
                :servicosEndereco="servicosEndereco"
                :plano="planos[1]"
                :vendedor="vendedor"
                :enderecos="enderecos"
                :contrato="contrato"
                :clausulas="clausulas[planos[1].id_tipo_faturamento]"
              ></resumoCard>
            </VuePerfectScrollbar>
          </vs-tab>
          <vs-tab
            :label="'Plano ' + planos[2].tipo_faturamento"
            class="p-0 m-0"
          >
            <VuePerfectScrollbar class="scroll-pdf-planos" :settings="settings">
              <resumoCard
                :orcamentista="orcamentista"
                :cliente="cliente"
                :areas="areas"
                :controle="controle"
                :enderecos="enderecos"
                :vendedor="vendedor"
                :servicosEndereco="servicosEndereco"
                :plano="planos[2]"
                :contrato="contrato"
                :clausulas="clausulas[planos[2].id_tipo_faturamento]"
              ></resumoCard>
            </VuePerfectScrollbar>
          </vs-tab>
        </vs-tabs>

        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-button
            class="mt-5 mx-3"
            color="danger"
            type="relief"
            @click="gerarPdf()"
            >IMPRIMIR PDF</vs-button
          >
        </vs-col>
      </div>
    </vs-popup>

    <vue-html2pdf
      :show-layout="false"
      :preview-modal="false"
      :paginate-elements-by-height="12000"
      :filename="
        'Plano 1 - ' + this.contrato.estagio + ' N°' + this.contrato.id
      "
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="plano1"
      @hasStartedDownload="hasStartedDownload()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <!-- <component
          :id_segmento="contrato.id_segmento"
          v-bind:is="comp"
          :key="reRenderPdf"
        />-->
        <component
          v-bind:is="pdf"
          :orcamentista="orcamentista"
          :key="reRenderPdf"
          :plano="planos[0]"
          :cliente="cliente"
          :vendedor="vendedor"
          :estagio="contrato.estagio"
          :areas="areas"
          :controle="controle"
          :servicosEndereco="servicosEndereco"
          :enderecos="enderecos"
          :contrato="contrato"
          :clausulas="clausulas[planos[0].id_tipo_faturamento]"
          :empresa="empresa"
        />
      </section>
    </vue-html2pdf>

    <vue-html2pdf
      :show-layout="false"
      :preview-modal="false"
      :paginate-elements-by-height="12000"
      :filename="
        'Plano 2 - ' + this.contrato.estagio + ' N°' + this.contrato.id
      "
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="plano2"
      @hasStartedDownload="hasStartedDownload()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <!-- <component
          :id_segmento="contrato.id_segmento"
          v-bind:is="comp"
          :key="reRenderPdf"
        />-->
        <component
          v-bind:is="pdf"
          :orcamentista="orcamentista"
          :key="reRenderPdf"
          :plano="planos[1]"
          :cliente="cliente"
          :areas="areas"
          :vendedor="vendedor"
          :servicosEndereco="servicosEndereco"
          :enderecos="enderecos"
          :estagio="contrato.estagio"
          :controle="controle"
          :contrato="contrato"
          :empresa="empresa"
          :clausulas="clausulas[planos[1].id_tipo_faturamento]"
        ></component>
      </section>
    </vue-html2pdf>

    <vue-html2pdf
      :show-layout="false"
      :preview-modal="false"
      :paginate-elements-by-height="12000"
      :filename="
        'Plano 3 - ' + this.contrato.estagio + ' N°' + this.contrato.id
      "
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="plano3"
      @hasStartedDownload="hasStartedDownload()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <!-- <component
          :id_segmento="contrato.id_segmento"
          v-bind:is="comp"
          :key="reRenderPdf"
        />-->
        <component
          v-bind:is="pdf"
          :orcamentista="orcamentista"
          :key="reRenderPdf"
          :plano="planos[2]"
          :cliente="cliente"
          :contrato="contrato"
          :servicosEndereco="servicosEndereco"
          :enderecos="enderecos"
          :vendedor="vendedor"
          :estagio="contrato.estagio"
          :controle="controle"
          :areas="areas"
          :empresa="empresa"
          :clausulas="clausulas[planos[2].id_tipo_faturamento]"
        ></component>
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import capa from "./components/capas/capa.vue"
import VueHtml2pdf from "vue-html2pdf"
import printDoc from "./components/printDoc"
import components from "@/components/default/exports.js"
import resumoCard from "./components/resumoCard"

export default {
  props: {
    planos: {
      require: true,
    },
    contrato: {
      require: true,
    },
    cliente: {
      require: true,
    },
    estagio: null,
    empresa: null,
  },
  data () {
    return {
      comp: "capa",
      areas: {},
      controle: {},
      controles: {},
      clausulas: [],
      enderecos: [],
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],
      pdf: null,
      reRenderPdf: 0,
      resumoModal: false,
      colorx: "#030e1d",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      servicosEndereco: [],
      vendedor: {},
      orcamentista: {},
    }
  },
  components: {
    ...components,
    VueHtml2pdf,
    printDoc,
    resumoCard,
    capa,
  },
  methods: {
    async gerarPdf () {
      await this.$refs.plano3.generatePdf()
      await this.$refs.plano2.generatePdf()
      await this.$refs.plano1.generatePdf()
      await this.logAssunto()
    },
    async hasStartedDownload () {
      await this.$vs.loading({ container: "#loading", scale: 0.6 })
    },
    async hasGenerated (event) {
      await this.$vs.loading.close("#loading > .con-vs-loading")
    },
    async getAreas () {
      try {
        this.areas = await this.$http.get(
          "getAreasSelecionadas/" + this.contrato.id
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getControle () {
      try {
        this.controle = await this.$http.get(
          "controle/" + this.contrato.id_controle
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getClausulas () {
      try {
        this.planos.forEach(async (plano) => {
          this.clausulas[
            plano.id_tipo_faturamento
          ] = await await this.$http.post(
            `getClausulasControles`, { id_controle: this.contrato.id_controle, id_tipo_faturamento: plano.id_tipo_faturamento }
          )
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getOrcamentista () {
      try {
        this.orcamentista = await this.$http.get(
          `colaborador/` + this.contrato.id_orcamentista
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getCronograma () {
      try {
        this.enderecos = await this.$http.post("getEnderecosParaAssociar/", {
          id_cliente: this.contrato.id_cliente,
          id_contrato: this.contrato.id,
        })
        this.servicosEndereco = []
        for (let i in this.enderecos) {
          await this.servicosEndereco.push(
            await this.getServicosEndereco(this.enderecos[i].id_endereco)
          )
        }
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getServicosEndereco (id_endereco) {
      try {
        const res = await this.$http.post("getServicosEndereco", {
          id_endereco: id_endereco,
          id_contrato: this.contrato.id,
        })
        let servicos = res.servicos
        servicos.forEach(async (servico) => {
          servico.nomeTurno = await this.turnos[servicos.id_turno].nome
        })
        return await servicos
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getVendedor () {
      try {
        this.vendedor = await this.$http.get(
          `colaborador/` + this.contrato.id_colaborador
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async logAssunto () {
      let assuntoCliente = {
        id_assuntos: 5,
        id_cliente: this.contrato.id_cliente,
        id_contrato: this.contrato.id,
        id_estagio: 1,
        status: 2,
        tipo: 1,
        id_colaborador: localStorage.getItem("id"),
        obs:
          "Envio das Propostas pelo Colaborador " +
          localStorage.getItem("nome"),
      }
      await this.$http.post("assuntoCliente", assuntoCliente)
    },
  },
  async mounted () {
    await this.$vs.loading()
    await this.getCronograma()
    await this.getClausulas()
    await this.getAreas()
    await this.getControle()
    await this.getOrcamentista()
    await this.getVendedor()
    this.pdf = "printDoc"
    this.reRenderPdf += 1
    this.resumoModal = await true
    await this.$vs.loading.close()
  },
};
</script>
<style lang="css">
.vs-popup--content {
  overflow: hidden;
}
.scroll-pdf-planos {
  max-height: 65vh !important;
}
</style>
