<template>
  <vx-card class="mb-5">
    <vs-row vs-type="flex" vs-justify="center" vs-align="flex-end" class="mb-3">
      <vs-col
        vs-w="4"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
      >
        <h5 style="color: black"></h5>
      </vs-col>
      <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="flex-end">
        <h5 class="font-semibold">Faturamento</h5>
      </vs-col>
      <vs-col vs-w="4" vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
        <menuFaturamento
          @update="update"
          :result="0"
          :contrato="contrato"
        ></menuFaturamento>
      </vs-col>
    </vs-row>
    <div
      class="vs-con-loading__container"
      style="overflow: auto; height: 65vh"
      id="listaFaturamento"
    >
      <vs-table
        stripe
        class="m"
        :data="listaFaturamento"
        max-items="100"
        id="tableFaturamento"
      >
        <template slot="thead">
          <vs-th sort-key="parcela" style="width: 10%">N° Fatura</vs-th>
          <vs-th>N° Nota</vs-th>
          <vs-th style="width: 20%">Data competência</vs-th>
          <vs-th style="width: 20%">Data faturamento</vs-th>
          <vs-th style="width: 20%">Valor</vs-th>
          <vs-th>Status</vs-th>
          <vs-th>Ações</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
            <vs-td :data="tr.parcela" class="text-center">
              <b>{{ tr.parcela }}</b>
            </vs-td>
            <vs-td>
              <b>{{ tr.numero ? tr.numero : "Sem Nota" }}</b>
            </vs-td>
            <vs-td>
              <b class="mx-5">{{ tr.data_competencia }}</b>
            </vs-td>
            <vs-td>
              <b class="mx-5">
                {{ tr.data_faturamento ? tr.data_faturamento : "Sem data" }}
              </b>
            </vs-td>
            <vs-td>{{
              $currency(tr.valor - tr.valor * (contrato.desconto / 100))
            }}</vs-td>
            <vs-td>
              <b>
                {{ tr.status_faturamento ? tr.status_faturamento : "Pendente" }}
              </b>
            </vs-td>
            <vs-td>
              <vs-col vs-w="12" vs-align="center" vs-justify="center">
                <NotaMenu :contrato="contrato" @update="update" :fatura="tr" />
              </vs-col>
            </vs-td>

            <template slot="expand">
              <notaLista
                @update="update"
                :empresa="empresa"
                :cliente="cliente"
                :id_fatura="tr.id"
                :fatura="tr"
                :contrato="contrato"
              ></notaLista>
            </template>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vx-card>
</template>
<script>
import components from "@/components/default/exports.js";
import NotaMenu from "../../../financeiro/nfse/notaMenu";
import notaLista from "../../../financeiro/nfse/notaLista.vue";
import menuFaturamento from "./menuFaturamento";
import validarFaturamento from "./validarFaturamento";
export default {
  props: {
    contrato: { require: true },
    cliente: { require: true },
    empresa: null,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1500,
      },
      listaFaturamento: {},
      result: {},
      reRender: 0,
    };
  },
  methods: {
    async update() {
      await this.$vs.loading({ container: "#listaFaturamento", scale: 0.6 });
      await this.getListaFaturamento();
      await this.$emit("update");
      await this.$vs.loading.close("#listaFaturamento > .con-vs-loading");
    },
    async getListaFaturamento() {
      try {
        this.listaFaturamento = await this.$http.get(
          "listaFaturamento/" + this.contrato.id
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  async mounted() {
    await this.$vs.loading({ container: "#listaFaturamento", scale: 0.6 });
    await this.getListaFaturamento();
    await this.$vs.loading.close("#listaFaturamento > .con-vs-loading");
  },
  components: {
    ...components,
    notaLista,
    validarFaturamento,
    menuFaturamento,
    NotaMenu,
  },
};
</script>
<style lang="scss" scoped>
.scroll-lista-faturamento {
  height: 55vh !important;
}
.card {
  transition-duration: 50ms;
  background-color: #fbfbfb;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 14;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-5px);
  }
}
</style>
