<template>
  <div id="loader_manage_device_pests" class="vs-con-loading__container">
    <vs-row vs-type="flex" vs-justify="center" vs-align="center">
      <vs-col vs-w="2"> </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-align="center" vs-justify="center">
        <h5>Pragas do Dispositivo {{ device.sequencia }}</h5>
      </vs-col>
      <vs-col vs-w="2" vs-type="flex" vs-align="center" vs-justify="center">
        <div class="mx-1">
          <vs-button
            icon="edit"
            type="relief"
            color="warning"
            size="small"
            @click="$emit('save', device)"
          >
            Salvar
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex" vs-justify="center" vs-align="center">
      <vs-col vs-w="2.5" vs-type="flex" vs-align="center" vs-justify="center">
        <div class="mx-1 w-full">
          <vs-row>
            <vs-col vs-w="12">
              <label style="color: black; font-size: 0.85rem" class="pl-2">
                Praga
              </label>
              <el-select
                v-model="pest.id_praga"
                v-validate="'required'"
                filterable
                :popper-append-to-body="false"
                name="id_pest"
                class="w-full"
              >
                <el-option
                  v-for="pest in pests"
                  :value="pest.id"
                  :label="pest.nome"
                  :key="pest.id"
                ></el-option>
              </el-select>
            </vs-col>
            <vs-col vs-w="12">
              <span class="text-danger text-sm" v-show="errors.has('id_pest')">
                {{ $validators.empty }}
              </span>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>

      <vs-col vs-w="2.5" vs-type="flex" vs-align="center" vs-justify="center">
        <div class="mx-1 w-full">
          <vs-row>
            <vs-col vs-w="12">
              <label style="color: black; font-size: 0.85rem" class="pl-2">
              </label>
              <vs-input-number
                color="success"
                v-validate="'required|numeric|min_value:1'"
                name="quantity"
                label="Quantidade."
                min="0"
                value="1"
                v-model="pest.quantidade"
              />
            </vs-col>
            <vs-col vs-w="12">
              <span
                class="text-danger text-sm pl-3"
                v-show="errors.has('quantity')"
              >
                {{ $validators.empty }}
              </span>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
        <vs-button
          icon="add"
          @click="submitForm()"
          type="relief"
          color="success"
          size="small"
        >
          Adicionar
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex" vs-justify="center" v-if="devicePests.length > 0">
      <vs-col vs-w="12" vs-type vs-justify="center" vs-align="center">
        <VuePerfectScrollbar
          class="scroll-manage-devices-pests"
          :settings="settings"
        >
          <div class="w-full">
            <vs-row vs-type="flex" vs-justify="center">
              <vs-col
                vs-w="2.5"
                vs-type
                vs-justify="center"
                vs-align="center"
                v-for="(pest, index) in devicePests"
                :key="index"
              >
                <div class="card mx-2 mt-3">
                  <vs-list-item
                    :title="`${pest.praga}: ${pest.quantidade} qtd. `"
                  >
                    <vs-button
                      @click="removePest(pest.id_praga)"
                      type="relief"
                      size="small"
                      icon="delete"
                      color="danger"
                    ></vs-button>
                  </vs-list-item>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </VuePerfectScrollbar>
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex" vs-justify="center" v-else class="pt-3">
      <vs-col vs-w="12" vs-type vs-justify="center" vs-align="center">
        <span style="color: grey !important; font-weight: 1000 !important">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
          </vs-col>
          <vs-col
            class="mt-2"
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <span>NÃO HÁ PRAGAS CADASTRADAS</span>
          </vs-col>
        </span>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";

export default {
  props: {
    device: Object,
    os: Object,
    client: Object,
    contract: Object,
    address: Object,
    pests: Array,
  },

  components: { ...components },

  data() {
    return {
      pest: {},
      devicePests: [],
      loaderContainer: "#loader_manage_device_pests",
      settings: {
        maxScrollbarLength: 200,
        wheelSpeed: 0.6,
        height: 1000,
      },
    };
  },

  async mounted() {
    await this.loadDevicePests();
    this.pest = this.getNewObj();
  },

  methods: {
    getNewObj() {
      return {
        quantidade: 1,
      };
    },

    async loadDevicePests() {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6,
      });
      try {
        this.devicePests = await this.$http.post(`list-device-pests`, {
          id_os: this.os.id,
          id_dispositivo: this.device.id,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    },

    async submitForm() {
      return new Promise(() => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.addPest();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            });
          }
        });
      });
    },

    async addPest() {
      try {
        const pestIndex = this.devicePests.findIndex(
          (pest) => pest.id_praga == this.pest.id_praga
        );
        if (pestIndex === -1) {
          const pest = this.pests.find((p) => p.id == this.pest.id_praga);
          this.devicePests.push({ ...this.pest, praga: pest.nome });
        } else {
          this.devicePests[pestIndex].quantidade =
            parseInt(this.devicePests[pestIndex].quantidade) +
            parseInt(this.pest.quantidade);
        }
        this.device.devicePests = this.devicePests;
        this.pest = this.getNewObj();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },

    async removePest(pestId) {
      try {
        const pestIndex = this.devicePests.findIndex(
          (pest) => pest.id_praga == pestId
        );
        if (pestIndex !== -1) {
          this.devicePests.splice(pestIndex, 1);
        }
        this.device.devicePests = this.devicePests;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
};
</script>