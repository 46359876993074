<template>
  <div class="w-full">
    <div v-if="contrato != undefined">
      <div :class="fadeClass">
        <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="text-white"
          >
            <vx-card
              no-shadow
              card-background="danger"
              v-if="contrato.devendo == 1"
              class="w-full"
            >
              <vs-row
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <vs-col
                  vs-w="12"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  class="text-white"
                >
                  <span class="font-bold" style="font-size: 12px"
                    >CLIENTE COM PENDÊNCIAS FINANCEIRAS!</span
                  >
                </vs-col>
              </vs-row>
            </vx-card>
          </vs-col>
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="text-white"
          >
            <vx-card
              no-shadow
              :card-background="contrato.id_status == 3 ? 'danger' : 'primary'"
            >
              <vs-row
                vs-type="flex"
                vs-justify="space-between"
                vs-align="center"
              >
                <vs-col
                  class="pt-2"
                  vs-w="9.5"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  :class="contrato.id_status == 3 ? 'text-white' : 'text-black'"
                >
                  <h6 class="font-bold">
                    {{ empresa_contrato.nome_fantasia }}
                  </h6>
                  <h6
                    class="ml-8 font-weight-bold"
                    :class="
                      contrato.id_status == 3 ? 'text-white' : 'text-black'
                    "
                  >
                    {{ estagio }} {{ tipo_faturamento }}
                    {{
                      contrato.renovacao > 0 && contrato.id_relacional > 0
                        ? " de Renovação "
                        : ""
                    }}
                    Nº.
                    {{ contrato.id }}
                  </h6>
                  <div v-if="contrato.id_estagio == 2">
                    <h6
                      class="ml-8"
                      :class="
                        contrato.id_status == 3 ? 'text-white' : 'text-black'
                      "
                    >
                      Data: {{ contrato.data_proposta | moment("DD/MM/YYYY") }}
                    </h6>
                  </div>
                  <div v-if="contrato.id_estagio == 3">
                    <h6
                      class="ml-8"
                      :class="
                        contrato.id_status == 3 ? 'text-white' : 'text-black'
                      "
                    >
                      Data: {{ contrato.data_contrato | moment("DD/MM/YYYY") }}
                    </h6>
                  </div>
                  <div v-if="contrato.data_vencimento">
                    <h6
                      class="ml-8"
                      :class="
                        contrato.id_status == 3 ? 'text-white' : 'text-black'
                      "
                    >
                      Vencimento:
                      {{ contrato.data_vencimento | moment("DD/MM/YYYY") }}
                    </h6>
                  </div>
                </vs-col>
                <vs-col
                  vs-w="2.5"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                  v-if="cliente.id"
                  :key="reRender"
                >
                  <ModalEditarCliente
                    :textColor="contrato.id_status == 3 ? 'white' : 'dark'"
                    :cliente="cliente"
                    :botaoEditar="false"
                  />
                </vs-col>
              </vs-row>
            </vx-card>
          </vs-col>
        </vs-row>
        <vs-tabs
          position="left"
          color="dark"
          alignment="fixed"
          class="tabs-shadow-none mt-5"
          id="icons-contrato"
          v-model="tabs"
          :key="reRender"
        >
          <vs-tab
            :disabled="!(contrato.id_estagio > 1)"
            icon="filter_1"
            label="Planos"
            style="height: 500px"
          >
            <VuePerfectScrollbar
              class="scroll-tab-contrato"
              :settings="settings"
            >
              <div class="tab-general w-full">
                <vx-card>
                  <planos
                    @refresh="getContratoRerender()"
                    :empresa="empresa_contrato"
                    :limites_desconto="limites_desconto"
                    :status="status"
                    :cliente="cliente"
                    :contrato="contrato"
                  ></planos>
                </vx-card>
              </div>
            </VuePerfectScrollbar>
          </vs-tab>
          <vs-tab icon="filter_2" label="Pagamento" style="height: 500px">
            <VuePerfectScrollbar
              class="scroll-tab-contrato"
              :settings="settings"
            >
              <div class="tab-general w-full">
                <vx-card>
                  <vs-row vs-type="flex">
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                    >
                      <h5 style="color: black"></h5>
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <h4 style="color: black">Informação Financeira</h4>
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="flex-end"
                      vs-align="flex-end"
                    >
                      <SuccessButton
                        size="small"
                        class
                        :disabled="
                          (!$atividades.permissoes.includes(2) ||
                            contrato.notaExists == 1) &&
                          !$atividades.permissoes.includes(17)
                        "
                        text="Atualizar"
                        @func="update()"
                      ></SuccessButton>
                    </vs-col>
                  </vs-row>
                  <vs-divider position="center" class="pt-5 pb-0 my-0"
                    >Entrada</vs-divider
                  >
                  <div class="pt-2">
                    <vs-row
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                      class="pt-5"
                    >
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Entrada"
                            v-model="contrato.entrada"
                            icon="R$"
                            icon-after="true"
                            type="number"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.id_tipo_faturamento == 4 ||
                                contrato.id_tipo_faturamento == 5 ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2.5"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <div
                            style="display: inline-block; margin-right: 20px"
                          >
                            <label style="color: black; font-size: 13px"
                              >Forma pagamento</label
                            >
                            <br />
                            <el-select
                              filterable
                              clearable
                              class="select-info"
                              placeholder="Selecione..."
                              name="pagamento"
                              v-validate="'required'"
                              :disabled="
                                (contrato.id_tipo_faturamento == 4 ||
                                  contrato.id_tipo_faturamento == 5 ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              v-model="contrato.id_forma_pagamento_entrada"
                              :popper-append-ssto-body="true"
                            >
                              <el-option
                                v-for="forma in formasPagamento"
                                class="select-info"
                                :value="forma.id"
                                :label="forma.nome"
                                :key="forma.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Data Entrada"
                            @input="verificarDataEntrada"
                            v-model="contrato.data_entrada"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.id_tipo_faturamento == 4 ||
                                contrato.id_tipo_faturamento == 5 ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            type="date"
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                    </vs-row>
                  </div>
                  <vs-divider position="center" class="py-0 my-0"
                    >Parcela</vs-divider
                  >
                  <div class="pt-2">
                    <vs-row
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                      class="pt-5"
                    >
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Parcelas"
                            v-model="contrato.parcelas"
                            icon="QTD"
                            icon-after="true"
                            type="number"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2.5"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <div
                            style="display: inline-block; margin-right: 20px"
                          >
                            <label style="color: black; font-size: 13px"
                              >Forma pagamento</label
                            >
                            <br />
                            <el-select
                              filterable
                              clearable
                              class="select-info"
                              placeholder="Selecione..."
                              name="forma"
                              v-validate="'required'"
                              :disabled="
                                (!$atividades.permissoes.includes(2) ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              v-model="contrato.id_forma_pagamento_parcela"
                              :popper-append-to-body="true"
                            >
                              <el-option
                                v-for="forma in formasPagamento"
                                class="select-info"
                                :value="forma.id"
                                :label="forma.nome"
                                :key="forma.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mr-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Dias entre pagamentos"
                            v-model="contrato.intervalo_parcela"
                            icon-after="true"
                            icon="DIA"
                            type="number"
                            color="dark"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                    </vs-row>
                  </div>
                  <vs-divider position="center" class="py-0 my-0"
                    >Total</vs-divider
                  >
                  <div class="pt-2">
                    <vs-row
                      class="pt-5"
                      vs-align="flex-start"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-w="12"
                    >
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            @change="verifyTotal(), setValorComDesconto()"
                            label="Total"
                            v-model="contrato.valor"
                            icon="R$"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            icon-after="true"
                            type
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Desconto"
                            @change="verifyDesconto(), setValorComDesconto()"
                            :max="
                              limites_desconto.length > 0 &&
                              limites_desconto != undefined &&
                              this.contrato.id_tipo_faturamento > 0
                                ? limites_desconto[
                                    this.contrato.id_tipo_faturamento - 1
                                  ].limite_desconto
                                : 0
                            "
                            v-model="contrato.desconto"
                            icon-after="true"
                            icon="%"
                            type="number"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2.5"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <div
                            style="display: inline-block; margin-right: 20px"
                          >
                            <label style="color: black; font-size: 13px"
                              >Vendedor</label
                            >
                            <br />
                            <el-select
                              filterable
                              clearable
                              class="select-info"
                              placeholder="Selecione..."
                              name="colaborador"
                              v-model="contrato.id_colaborador"
                              :disabled="
                                (!$atividades.permissoes.includes(2) ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              :popper-append-to-body="true"
                            >
                              <el-option
                                v-for="colaborador in colaboradores"
                                class="select-info"
                                :value="colaborador.id"
                                :label="colaborador.nome"
                                :key="colaborador.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2.5"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mr-5"
                      >
                        <vx-input-group class="mb-base">
                          <div
                            style="display: inline-block; margin-right: 20px"
                          >
                            <label style="color: black; font-size: 13px"
                              >Orcamentista</label
                            >
                            <br />
                            <el-select
                              filterable
                              clearable
                              class="select-info"
                              placeholder="Selecione..."
                              :disabled="
                                (!$atividades.permissoes.includes(2) ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              name="orcamentista"
                              v-model="contrato.id_orcamentista"
                              :popper-append-to-body="true"
                            >
                              <el-option
                                v-for="orcamentista in orcamentistas"
                                class="select-info"
                                :value="orcamentista.id"
                                :label="orcamentista.nome"
                                :key="orcamentista.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vx-input-group>
                      </vs-col>

                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            :key="key"
                            label="Total com desconto"
                            v-model="valorComDesconto"
                            @change="setValorComDesconto()"
                            icon="R$"
                            readonly
                            icon-after="true"
                            type
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2.5"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        class="px-3 pb-3"
                      >
                        <vx-input-group class="mb-base w-full">
                          <div class="w-full">
                            <label style="color: black; font-size: 0.85rem"
                              >Tipo de Faturamento</label
                            >
                            <el-select
                              filterable
                              clearable
                              size
                              class="w-full"
                              :popper-append-to-body="true"
                              placeholder="Tipo do Faturamento"
                              :disabled="
                                (!$atividades.permissoes.includes(2) ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              v-model="contrato.id_tipo_faturamento"
                            >
                              <el-option
                                v-for="tipo in tipos_faturamento"
                                :value="tipo.id"
                                :label="tipo.nome"
                                :key="tipo.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vx-input-group>
                      </vs-col>
                    </vs-row>
                  </div>
                </vx-card>
              </div>
            </VuePerfectScrollbar>
          </vs-tab>
          <vs-tab
            icon="filter_3"
            label="Inf. Tecnica"
            class="w-full"
            style="height: 500px"
          >
            <VuePerfectScrollbar
              class="scroll-tab-contrato"
              :settings="settings"
            >
              <div class="tab-general w-full">
                <vx-card>
                  <vs-row vs-type="flex">
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                    >
                      <h5 style="color: black"></h5>
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <h4 style="color: black">Informação Tecnica</h4>
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="flex-end"
                      vs-align="flex-end"
                    >
                      <SuccessButton
                        size="small"
                        class
                        :disabled="
                          (!$atividades.permissoes.includes(2) ||
                            contrato.notaExists == 1) &&
                          !$atividades.permissoes.includes(17)
                        "
                        text="Atualizar"
                        @func="update()"
                      ></SuccessButton>
                    </vs-col>
                  </vs-row>
                  <vs-divider position="center">Força de trabalho</vs-divider>
                  <div class="pt-2">
                    <vs-row
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                      class
                    >
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <!-- !$atividades.permissoes.includes(17) || -->
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Tecnicos inicias"
                            v-model="contrato.tecnicos_iniciais"
                            icon="QTD"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            icon-after="true"
                            type="number"
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Horas inciais"
                            v-model="contrato.horas_iniciais"
                            icon="HRS"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            icon-after="true"
                            type="number"
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Tecnicos manutencao"
                            v-model="contrato.tecnicos_manutencao"
                            icon="QTD"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            icon-after="true"
                            type="number"
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Horas manutencao"
                            v-model="contrato.horas_manutencao"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            icon="HRS"
                            icon-after="true"
                            type="number"
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                    </vs-row>
                  </div>
                  <vs-divider position="center" class="py-0 my-0"
                    >Datas</vs-divider
                  >
                  <div class="pt-5">
                    <vs-row
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                      class
                    >
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <div v-if="contrato.id_estagio == 2">
                          <vx-input-group class="mb-base">
                            <vs-input
                              label="Data Proposta"
                              v-model="contrato.data_proposta"
                              :disabled="
                                (!$atividades.permissoes.includes(2) ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              type="date"
                              color="dark"
                              min="1"
                            />
                          </vx-input-group>
                        </div>
                        <div v-if="contrato.id_estagio == 3">
                          <vx-input-group class="mb-base">
                            <vs-input
                              label="Data Contrato"
                              :disabled="
                                (!$atividades.permissoes.includes(2) ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              v-model="contrato.data_contrato"
                              type="date"
                              color="dark"
                              min="1"
                            />
                          </vx-input-group>
                        </div>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Vencimento"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            v-model="contrato.data_vencimento"
                            type="date"
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Garantia"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            v-model="contrato.garantia"
                            icon="MES"
                            icon-after="true"
                            type="number"
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                    </vs-row>
                    <vs-row
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                      class
                    >
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Aplicacoes"
                            v-model="contrato.aplicacoes"
                            icon="QTD"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            icon-after="true"
                            type="number"
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        class="mx-5"
                      >
                        <vx-input-group class="mb-base">
                          <vs-input
                            label="Dias entre Manutencoes"
                            v-model="contrato.periodo_manutencao"
                            icon="DIA"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            icon-after="true"
                            type="number"
                            color="dark"
                            min="1"
                          />
                        </vx-input-group>
                      </vs-col>
                    </vs-row>
                  </div>
                  <vs-divider position="center" class="py-0 my-0"
                    >Detalhes Tecnicos</vs-divider
                  >
                  <div class="pt-5">
                    <vs-row
                      class
                      vs-align="flex-start"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-w="12"
                    >
                      <vs-col
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="3"
                      >
                        <vx-input-group class="mx-5 mb-base">
                          <div>
                            <label style="color: black; font-size: 13px"
                              >Controle</label
                            >
                            <br />
                            <el-select
                              filterable
                              clearable
                              class="select-info"
                              placeholder="Selecione..."
                              name="controle"
                              v-validate="'required'"
                              :disabled="
                                (!$atividades.permissoes.includes(2) ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              v-model="contrato.id_controle"
                              :popper-append-to-body="true"
                            >
                              <el-option
                                v-for="controle in controles"
                                class="select-info"
                                :value="controle.id"
                                :label="controle.nome"
                                :key="controle.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vx-input-group>
                      </vs-col>

                      <vs-col
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="3"
                      >
                        <vx-input-group class="mx-5 mb-base">
                          <div>
                            <label style="color: black; font-size: 13px"
                              >Segmento</label
                            >
                            <br />
                            <el-select
                              filterable
                              clearable
                              class="select-info"
                              placeholder="Selecione..."
                              name="segmento"
                              v-validate="'required'"
                              :disabled="
                                (!$atividades.permissoes.includes(2) ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              v-model="contrato.id_segmento"
                              :popper-append-to-body="true"
                            >
                              <el-option
                                v-for="segmento in segmentos"
                                class="select-info"
                                :value="segmento.id"
                                :label="segmento.nome"
                                :key="segmento.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vx-input-group>
                      </vs-col>

                      <vs-col
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="3"
                      >
                        <vx-input-group class="mx-5 mb-base">
                          <div>
                            <label style="color: black; font-size: 13px"
                              >Empresa</label
                            >
                            <br />
                            <el-select
                              filterable
                              clearable
                              class="select-info"
                              placeholder="Selecione..."
                              name="empresa"
                              v-validate="'required'"
                              :disabled="
                                (!$atividades.permissoes.includes(2) ||
                                  contrato.notaExists == 1) &&
                                !$atividades.permissoes.includes(17)
                              "
                              v-model="contrato.id_empresa"
                              :popper-append-to-body="true"
                            >
                              <el-option
                                v-for="empresa in empresas"
                                class="select-info"
                                :value="empresa.id"
                                :label="empresa.razao_social"
                                :key="empresa.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vx-input-group>
                      </vs-col>
                    </vs-row>
                    <vs-row
                      class
                      vs-align="flex-start"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-w="12"
                    >
                      <vs-col
                        vs-w="6"
                        vs-align="flex-start"
                        vs-type="flex"
                        vs-justify="flex-start"
                      >
                        <vx-input-group class="mx-5 mb-base w-full">
                          <vs-textarea
                            label="Observação Técnica"
                            class="w-full"
                            color="dark"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            height="100"
                            v-model="contrato.obs_tecnica"
                            counter="255"
                          />
                        </vx-input-group>
                      </vs-col>
                      <vs-col
                        vs-w="6"
                        class
                        vs-align="flex-start"
                        vs-type="flex"
                        vs-justify="flex-start"
                      >
                        <vx-input-group class="mx-5 mb-base w-full">
                          <vs-textarea
                            label="Observação"
                            class="w-full"
                            :disabled="
                              (!$atividades.permissoes.includes(2) ||
                                contrato.notaExists == 1) &&
                              !$atividades.permissoes.includes(17)
                            "
                            color="dark"
                            height="100"
                            v-model="contrato.obs"
                            counter="255"
                          />
                        </vx-input-group>
                      </vs-col>
                    </vs-row>
                  </div>
                  <vs-divider position="center" class="py-0 my-0"
                    >Áreas</vs-divider
                  >
                  <div class="py-3">
                    <Extras
                      @update="updateContrato"
                      :contrato="contrato"
                    ></Extras>
                    <Areas
                      Areas
                      :id="contrato.id"
                      @addAreas="atualizarAreasSelecionadas"
                    ></Areas>
                  </div>
                </vx-card>
              </div>
            </VuePerfectScrollbar>
          </vs-tab>
          <vs-tab
            :disabled="!(contrato.id_estagio == 3)"
            icon="filter_4"
            label="Faturamento"
            style="height: 800px; !important"
          >
            <div
              class="tab-general w-full"
              v-if="contrato.faturado == 1 && validation.validation"
            >
              <listaFaturamento
                :empresa="empresa_contrato"
                :cliente="cliente"
                :contrato="contrato"
                @update="getContrato"
              ></listaFaturamento>
            </div>
            <div class="w-full" v-else>
              <validarFaturamento
                @getValidacao="getValidacao"
                @update="getContrato"
                :contrato="contrato"
              />
            </div>
          </vs-tab>

          <vs-tab
            :disabled="!(contrato.id_estagio == 3)"
            icon="filter_5"
            label="Serviços"
            style="height: 500px; !important"
          >
            <VuePerfectScrollbar
              class="scroll-tab-contrato"
              :settings="settings"
            >
              <div class="tab-general w-full">
                <vx-card>
                  <Servicos
                    :aplicacoes="contrato.aplicacoes"
                    :cliente="cliente"
                    @update="reRender += 1"
                    :contrato="contrato"
                  ></Servicos>
                </vx-card>
              </div>
            </VuePerfectScrollbar>
          </vs-tab>
          <vs-tab icon="filter_6" label="Assuntos" style="height: 500px">
            <div class="tab-general w-full">
              <Assunto :cliente="cliente" :contrato="contrato"></Assunto>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <md-speed-dial
        md-event="click"
        class="md-top-right"
        md-direction="bottom"
      >
        <md-speed-dial-target @click="fade()" style="background-color: #ffca00">
          <md-icon style>settings</md-icon>
        </md-speed-dial-target>
        <md-speed-dial-content v-if="fadeClass == 'transparent'">
          <md-button
            class="md-icon-button"
            @click="ImprimirPdf()"
            style="background-color: white"
          >
            <md-icon>print</md-icon>
            <md-tooltip md-direction="right" style="font-size: 15px">
              <Badge color="dark" text="Imprimir" size="large"></Badge>
            </md-tooltip>
          </md-button>
          <md-button
            :disabled="!$atividades.permissoes.includes(2)"
            v-if="contrato.id_status == 3"
            @click="
              $atividades.permissoes.includes(2) ? showModalRestaurar() : ''
            "
            class="md-icon-button"
            :style="
              $atividades.permissoes.includes(2)
                ? 'background-color: #00BBA2'
                : 'background-color: #aaa'
            "
          >
            <md-icon style="color: white">redo</md-icon>
            <md-tooltip md-direction="right" style="font-size: 15px">
              <Badge color="success" text="Restaurar" size="large"></Badge>
            </md-tooltip>
          </md-button>
          <md-button
            :disabled="!$atividades.permissoes.includes(3)"
            v-if="contrato.id_status != 3"
            @click="
              $atividades.permissoes.includes(3) ? showModalDeclinar() : ''
            "
            class="md-icon-button"
            :style="
              $atividades.permissoes.includes(3)
                ? 'background-color: red'
                : 'background-color: #aaa'
            "
          >
            <md-icon>not_interested</md-icon>
            <md-tooltip md-direction="right" style="font-size: 15px">
              <Badge color="red" text="Declinar" size="large"></Badge>
            </md-tooltip>
          </md-button>
          <md-button
            :disabled="
              (!$atividades.permissoes.includes(2) || contrato.devendo == 1) &&
              !$atividades.permissoes.includes(17)
            "
            v-if="contrato.id_estagio == 3"
            @click="
              ($atividades.permissoes.includes(2) && contrato.devendo != 1) ||
              $atividades.permissoes.includes(17)
                ? showModalRenovar()
                : ''
            "
            class="md-icon-button"
            :style="
              ($atividades.permissoes.includes(2) && contrato.devendo != 1) ||
              $atividades.permissoes.includes(17)
                ? 'background-color: #0f4084'
                : 'background-color: #aaa'
            "
          >
            <md-icon style="color: white">autorenew</md-icon>
            <md-tooltip md-direction="right" style="font-size: 15px">
              <Badge color="#0f4084" text="Renovar" size="large"></Badge>
            </md-tooltip>
          </md-button>
          <md-button
            :disabled="
              (!$atividades.permissoes.includes(2) || contrato.devendo == 1) &&
              !$atividades.permissoes.includes(17)
            "
            v-if="contrato.id_estagio == 2"
            @click="
              ($atividades.permissoes.includes(2) && contrato.devendo != 1) ||
              $atividades.permissoes.includes(17)
                ? showModalGerarContrato()
                : ''
            "
            class="md-icon-button"
            :style="
              ($atividades.permissoes.includes(2) && contrato.devendo != 1) ||
              $atividades.permissoes.includes(17)
                ? 'background-color: #0f4084'
                : 'background-color: #aaa'
            "
          >
            <md-icon style="color: white">list_alt</md-icon>
            <md-tooltip md-direction="right" style="font-size: 15px">
              <Badge color="#021a33" text="Gerar Contrato" size="large"></Badge>
            </md-tooltip>
          </md-button>
          <a
            :href="'/contas-receber?contrato=' + contrato.id"
            v-if="contrato.id_estagio == 3"
          >
            <md-button
              v-if="contrato.id_estagio == 3"
              class="md-icon-button"
              style="background-color: #4a148c"
            >
              <md-icon style="color: white">account_balance</md-icon>
              <md-tooltip md-direction="right" style="font-size: 15px">
                <Badge color="#4a148c" text="Contas" size="large"></Badge>
              </md-tooltip>
            </md-button>
          </a>
          <a
            :href="'/nota?contrato=' + contrato.id"
            v-if="contrato.id_estagio == 3"
          >
            <md-button class="md-icon-button" style="background-color: #4a148c">
              <md-icon style="color: white">receipt</md-icon>
              <md-tooltip md-direction="right" style="font-size: 15px">
                <Badge color="#4a148c" text="Notas" size="large"></Badge>
              </md-tooltip>
            </md-button>
          </a>
        </md-speed-dial-content>
      </md-speed-dial>
    </div>

    <component
      v-bind:is="showImprimirPdf"
      :key="reRenderImprimirPdf"
      :cliente="cliente"
      :empresa="empresa_contrato"
      :contrato="contrato"
      :plano="contrato"
      :pagamento="formasPagamentoContrato"
    ></component>
    <component
      v-bind:is="ModalDeclinio"
      :key="reRenderModalDeclinar"
      :contrato="contrato"
      @update="getContratoRerender"
    ></component>
    <component
      v-bind:is="ModalRestaurar"
      :key="reRenderModalRestaurar"
      :contrato="contrato"
      @update="getContratoRerender"
    ></component>
    <component
      v-bind:is="ModalRenovacao"
      :key="reRenderModalRenovacao"
      :contrato="contrato"
      @redirect="contratoRenovado"
    ></component>
    <component
      v-bind:is="ModalGerarContrato"
      :key="reRenderModalGerarContrato"
      :contrato="contrato"
      @update="getContratoRerender"
    ></component>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
import ModalEditarCliente from "./../cliente/editar.vue";
import Assunto from "./components/assuntoContrato.vue";
import Areas from "./../area/components/areas.vue";
import Extras from "./../area/components/extras.vue";
import ImprimirPdf from "./../pdf/pdf.vue";
import planos from "./components/planos.vue";
import declinarModal from "./components/declinarModal";
import restaurarModal from "./components/restaurarModal";
import validarFaturamento from "./components/faturamento/validarFaturamento";
import renovarModal from "./components/renovarModal";
import router from "./../../../router.js";
import gerarContrato from "./components/gerarContrato";
import listaFaturamento from "./components/faturamento/listaFaturamento";
import Servicos from "./components/os/servicos.vue";

export default {
  data() {
    return {
      contrato: {},
      cliente: {},
      controles: [],
      segmentos: [],
      empresas: [],
      formasPagamento: [],
      colaboradores: [],
      orcamentistas: [],
      key: 0,
      valorComDesconto: null,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000,
      },
      ModalDeclinio: null,
      ModalRestaurar: null,
      ModalRenovacao: null,
      ModalGerarContrato: null,
      showImprimirPdf: null,
      reRenderImprimirPdf: 0,
      reRenderModalDeclinar: 0,
      reRenderModalRestaurar: 0,
      reRenderModalRenovacao: 0,
      reRenderModalGerarContrato: 0,
      estagio: null,
      tipo_faturamento: null,
      status: null,
      areasSelecionadas: null,
      fadeClass: "unTransparent",
      reRender: 0,
      empresa_contrato: {},
      empresa: null,
      formasPagamentoContrato: { entrada: null, parcela: null },
      tabs: 0,
      limites_desconto: {},
      tipos_faturamento: {},
      total: 0,
      validation: false,
    };
  },
  methods: {
    async fade() {
      if (this.fadeClass == "unTransparent") {
        this.fadeClass = "transparent";
      } else {
        this.fadeClass = "unTransparent";
      }
    },
    async getControles() {
      try {
        this.controles = await this.$http.get("controle");
        await this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getSegmentos() {
      try {
        this.segmentos = await this.$http.get("segmento");
        await this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get("empresa");
        await this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getValidacao(validation) {
      this.validation = validation;
    },
    async getFormasPagamento() {
      try {
        this.formasPagamento = await this.$http.get(`formasPagamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getTipoFaturamento() {
      try {
        this.tipos_faturamento = await this.$http.get(`tipo_faturamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getVendedor() {
      const atividades = [1, 2, 3];
      try {
        this.colaboradores = await this.$http.post(
          `colaboradorAtividade`,
          atividades
        );
        await this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getOrcamentistas() {
      const atividades = [11];
      try {
        this.orcamentistas = await this.$http.post(
          `colaboradorAtividade`,
          atividades
        );
        await this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContratoRerender() {
      await this.getContrato();
      await this.getEmpresa();
      this.reRender += await 1;
    },
    async getContratoTab() {
      await this.getContrato();
    },
    async getContrato() {
      try {
        this.contrato = await this.$http.get(
          "getContratoById/" + this.$route.query.id
        );
        this.status = this.contrato.status;
        this.estagio = this.contrato.estagio;
        this.tipo_faturamento = this.contrato.tipo_faturamento;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getCliente() {
      try {
        this.cliente = await this.$http.get(
          "cliente/" + this.contrato.id_cliente
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async contratoRenovado(id) {
      await router.push("/contrato?id=" + id);
    },
    async update() {
      await this.$vs.loading();
      await this.setValorComDesconto();
      delete this.contrato.notaExists;
      delete this.contrato.have2Bill;
      delete this.contrato.estagio;
      delete this.contrato.tipo_faturamento;
      delete this.contrato.status;
      delete this.contrato.controle;
      delete this.contrato.obs_tipo_faturamento;
      delete this.contrato.forma_pagamento;
      delete this.contrato.data_documento;
      delete this.contrato.vencimento;
      delete this.contrato.devendo;
      try {
        if (this.areasSelecionadas != undefined) {
          await this.postAreas();
        }
        const res = await this.$http.put(
          "contrato/" + this.$route.query.id,
          this.contrato
          );
        await this.$vs.notify({
          title: "Successo",
          text: "Informações Atualizadas com Sucesso",
          color: "success",
        });
        await this.getContratoRerender();
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Renovação do contrato N°" + res.id,
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close();
      }
    },
    async showModalDeclinar() {
      this.ModalDeclinio = "declinarModal";
      this.reRenderModalDeclinar = this.reRenderModalDeclinar + 1;
    },
    async showModalRestaurar() {
      this.ModalRestaurar = "restaurarModal";
      this.reRenderModalRestaurar = this.reRenderModalRestaurar + 1;
    },
    async showModalRenovar() {
      this.ModalRenovacao = "renovarModal";
      this.reRenderModalRenovacao = this.reRenderModalRenovacao + 1;
    },
    async showModalGerarContrato() {
      this.ModalGerarContrato = "gerarContrato";
      this.reRenderModalGerarContrato = this.reRenderModalGerarContrato + 1;
    },
    async ImprimirPdf() {
      if (
        this.contrato.parcelas > 0 &&
        this.contrato.id_forma_pagamento_parcela > 0
      ) {
        this.formasPagamentoContrato.parcela = this.formasPagamento.find(
          (x) => x.id == this.contrato.id_forma_pagamento_parcela
        ).nome;
      }
      if (
        this.contrato.entrada > 0 &&
        this.contrato.id_forma_pagamento_entrada > 0
      ) {
        this.formasPagamentoContrato.entrada = this.formasPagamento.find(
          (x) => x.id == this.contrato.id_forma_pagamento_entrada
        ).nome;
      }
      this.showImprimirPdf = "ImprimirPdf";
      this.reRenderImprimirPdf = this.reRenderImprimirPdf + 1;
    },
    async atualizarAreasSelecionadas(areasSelecionadas) {
      this.areasSelecionadas = areasSelecionadas;
    },
    async getLimitesDesconto() {
      try {
        this.limites_desconto = await this.$http.get("limites_desconto");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async verifyDesconto() {
      if (this.$atividades.permissoes.includes(17)) {
        return;
      }
      if (
        this.limites_desconto[this.contrato.id_tipo_faturamento - 1]
          .limite_desconto < this.contrato.desconto
      ) {
        this.contrato.desconto =
          this.limites_desconto[
            this.contrato.id_tipo_faturamento - 1
          ].limite_desconto;
        this.$vs.notify({
          title: "Desconto inválido!",
          text: "O desconto dado é maior que o limite deste tipo de faturamento!",
          color: "danger",
        });
      }
    },
    async postAreas() {
      delete this.contrato.notaExists;
      delete this.contrato.estagio;
      delete this.contrato.tipo_faturamento;
      delete this.contrato.status;
      try {
        await this.$http.post("contrato_areas", {
          id_contrato: this.contrato.id,
          areas: this.areasSelecionadas,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async updateContrato(contrato) {
      if (contrato) {
        this.contrato = contrato;
      }
    },
    async verifyTotal() {
      if (this.$atividades.permissoes.includes(17)) {
        return;
      }
      if (this.contrato.valor < this.total) {
        this.contrato.valor = this.total;
        this.$vs.notify({
          title: "Valor total inválido!",
          text: "O valor total só aceita incremento!",
          color: "danger",
        });
      }
    },
    async verificarDataEntrada(event) {
      const dataEntrada = new Date(event).getTime();
      let dataDocumento = new Date(this.contrato.data_proposta).getTime();
      if (this.contrato.id_estagio == 3) {
        dataDocumento = new Date(this.contrato.data_contrato).getTime();
      }
      if (dataEntrada < dataDocumento) {
        this.$vs.notify({
          title: "Data de Entrada Inválida",
          text: "Data da entrada não pode ser menor que a data do documento!",
          color: "danger",
        });
        this.contrato.data_entrada = null;
      }
    },
    async getEmpresa() {
      try {
        this.empresa_contrato = await this.$http.get(
          `empresa/` + this.contrato.id_empresa
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async setValorComDesconto() {
      if (this.contrato.desconto != 0) {
        this.valorComDesconto =
          this.contrato.valor -
          this.contrato.valor * (this.contrato.desconto / 100);
      }
      if (this.contrato.desconto == 0) {
        this.valorComDesconto = this.contrato.valor;
      }
      this.key++;
    },
  },
  components: {
    ...components,
    ModalEditarCliente,
    Assunto,
    planos,
    declinarModal,
    restaurarModal,
    renovarModal,
    gerarContrato,
    validarFaturamento,
    Areas,
    Extras,
    ImprimirPdf,
    listaFaturamento,
    Servicos,
  },
  async mounted() {
    await this.$vs.loading();
    await this.getContratoRerender();
    await this.getEmpresa();
    await this.getCliente();
    await this.getControles();
    await this.getLimitesDesconto();
    await this.getFormasPagamento();
    await this.getVendedor();
    await this.getOrcamentistas();
    await this.getSegmentos();
    await this.getEmpresas();
    await this.getTipoFaturamento();
    await this.setValorComDesconto();
    await this.$vs.loading.close();
    this.total = this.contrato.valor;
  },
};
</script>

<style lang="scss">
.transparent {
  opacity: 0.3;
  transition-duration: 300ms;
}
.unTransparent {
  opacity: 1;
  transition-duration: 300ms;
}
.con-slot-tabs {
  width: 100%;
}
.con-tab-ejemplo {
  width: 100%;
}
.vs-list--item .list-titles .vs-list--subtitle {
  font-size: 1.3rem;
}

.el-tabs__item.is-active {
  color: #ffca00 !important;
}

.el-tabs__item:hover {
  color: #ffca00 !important;
}

.el-tabs__active-bar {
  background-color: #ffca00 !important;
}
.col {
  width: auto;
}
.vs-tabs--content {
  padding: 0;
}
.input-group-prepend {
  color: #ffca00 !important;
}

.bootstrap-button {
  background-color: #ffca00 !important;
  color: black !important;
  cursor: default !important;
}
.scroll-tab-contrato {
  height: 500px !important;
}
#icons-contrato {
  .vs-icon-dark {
    font-size: 20px !important;
  }
}
</style>
