<template>
  <vs-col
    vs-w="2.8"
    class="p-0"
    vs-type="flex"
    vs-justify="flex-end"
    vs-align="center"
  >
    <SideBar
      :title="'Agendar Servicos para ' + endereco.nome"
      @hide="modalServicosAgenda = false"
      :active="modalServicosAgenda"
      size="huger"
      @success="submitForm()"
      textSucces="Agendar"
      :visualizar="!buttonStatus"
    >
      <div
        class="w-full p-4 vs-con-loading__container"
        slot="body"
        id="servicosAgendar"
      >
        <vs-col vs-w="5" class="mr-10">
          <div class="w-full">
            <vs-col vs-w="12" vs-type="flex">
              <Agenda class="m-4"></Agenda>
            </vs-col>
          </div>
        </vs-col>
        <vs-col vs-w="6.5">
          <vs-divider border-style="dotted">Data dos serviços</vs-divider>
          <div id="head_modal_agenda" class="vs-con-loading__container">
            <VuePerfectScrollbar
              class="scroll-servicos-agenda"
              :settings="settings"
            >
              <div v-for="(data, index) in servicos" :key="index">
                <vs-row vs-w="12" vs-type="flex">
                  <vs-col vs-w="4" class="mb-5" vs-type="flex">
                    <div class="w-full mx-2">
                      <vs-input
                        :label="'Data do  ' + (index + 1) + '° serviço'"
                        type="date"
                        color="dark"
                        @change="index == 0 ? changeFirstDate(index) : 0"
                        v-validate="'required'"
                        v-model="servicos[index].data_competencia"
                      />
                    </div>
                  </vs-col>
                  <vs-col vs-w="4" vs-type="flex">
                    <div class="w-full mx-2">
                      <vs-input
                        label="Hora marcada"
                        :name="'horario' + index"
                        v-validate="
                          servicos[index].id_turno == null ? 'required' : false
                        "
                        v-model="servicos[index].hora_marcada"
                        v-mask="'##:00'"
                        max="24"
                        type="datetime"
                        placeholder="00:00"
                        class="inputx w-full"
                      />
                    </div>
                  </vs-col>
                  <vs-col vs-w="4" vs-type="flex">
                    <div class="w-full mx-2">
                      <label style="color: black; font-size: 0.85rem"
                        >Turno</label
                      >
                      <br />
                      <el-select
                        filterable
                        clearable
                        class="select-info"
                        placeholder="Selecione..."
                        v-model="servicos[index].id_turno"
                        :name="'turno' + index"
                        v-validate="
                          servicos[index].hora_marcada == null
                            ? 'required'
                            : false
                        "
                        :popper-append-to-body="false"
                      >
                        <el-option
                          v-for="turno in turnos"
                          class="select-info"
                          :value="turno.id"
                          :label="turno.nome"
                          :key="turno.id"
                        ></el-option>
                      </el-select>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </VuePerfectScrollbar>
          </div>
        </vs-col>
      </div>
    </SideBar>
  </vs-col>
</template>
<script>
import components from "@/components/default/exports.js"
import Agenda from "../../../agenda/Agenda.vue"

export default {
  props: {
    cliente: { require: true },
    contrato: { require: true },
    endereco: { nome: "Carregando", servicos: 0 },
    servicoEndereco: null,
  },
  data () {
    return {
      buttonStatus: true,
      modalServicosAgenda: false,
      turnos: [
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],
      servicos: [],
      settings: {
        maxScrollbarLength: 200,
        wheelSpeed: 0.6,
        height: 1000,
      },
    }
  },
  methods: {
    async changeFirstDate (index) {
      await this.updateMapServices(this.servicos[index].data_competencia)
    },
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.agendar()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            })
          }
        })
      })
    },
    async agendar () {
      await this.$vs.loading({ container: "#servicosAgendar", scale: 0.6 })
      let object = {
        obs: this.contrato.obs_tecnica,
        id_endereco: this.endereco.id_endereco,
        id_cliente: this.contrato.id_cliente,
        id_contrato: this.contrato.id,
        horas_manutencao: this.contrato.horas_manutencao,
        horas_iniciais: this.contrato.horas_iniciais,
        tecnicos_manutencao: this.contrato.tecnicos_manutencao,
        tecnicos_iniciais: this.contrato.tecnicos_iniciais,
        fog: this.contrato.fog,
        fog_inicial: this.contrato.fog_inicial,
        escada: this.contrato.escada,
        escada_inicial: this.contrato.escada_inicial,
        carros: this.contrato.carros,
      }
      try {
        await this.$http.post("agendaPorHorario", {
          object: object,
          servicos: this.servicos,
        })
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "Servico",
          ip: window.localStorage.getItem("ip"),
          texto: "Agendamento dos servicos por horarios para o contrato N°" + this.contrato.id,
        }
        await this.$logger(logData)
        await this.$vs.notify({
          title: "Successo",
          text: "Agendado com successo",
          color: "success",
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#servicosAgendar > .con-vs-loading")
        this.$emit("update")
        this.modalServicosAgenda = false
      }
    },
    async mapServices () {
      this.servicos = []
      let date = this.$formartData.dataHoje()
      for (let i = 1; i <= parseInt(this.endereco.servicos); i++) {
        let dataFormatada = await this.$formartData.formatar(new Date(date), 1)
        this.servicos.push({
          data_competencia: dataFormatada
        })
        date = await this.$formartData.somarDias(
          new Date(date),
          parseInt(this.contrato.periodo_manutencao)
        )
      }
    },
    async updateMapServices (date) {
      date = new Date(date)
      this.servicos = []
      for (let i = 1; i <= parseInt(this.endereco.servicos); i++) {
        let dataFormatada = await this.$formartData.formatar(date, 1)
        this.servicos.push({
          data_competencia: dataFormatada
        })
        date = await this.$formartData.somarDias(
          date,
          parseInt(this.contrato.periodo_manutencao)
        )
      }
    },
  },
  async mounted () {
    this.modalServicosAgenda = true
    await this.mapServices()
  },
  components: { ...components, Agenda },
};
</script>
<style lang="scss">
.scroll-servicos-agenda {
  height: 50vh !important;
}
</style>
