<template>
  <div class="w-full">
    <vs-popup :title="titulo" :active.sync="modalVerErro">
      <div class="w-full vs-con-loading__container" id="erroConsulta">
        <VuePerfectScrollbar class="scroll-erros m-0 p-0" :settings="confScroll">
          <vs-row
            vs-type="flex"
            vs-justify="space-between"
            v-for="(data, index) in erro"
            :key="index"
          >
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
              <span
                style="color: red; font-size: 14px"
                class="font-semibold m-0 p-0"
              >Erro {{data.codigo ? data.codigo : data.Codigo }}</span>
            </vs-col>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
              <span class="font-semibold m-0 p-0" style="font-size: 12px">Mensagem do Erro:</span>
            </vs-col>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
              <span
                style="font-size: 12px; text-align: justify"
                class="m-0 p-0"
              >{{data.mensagem ? data.mensagem : data.Mensagem }}</span>
            </vs-col>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
              <span class="font-semibold" style="text-align: center; color: green">Correção:</span>
            </vs-col>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
              <span style="font-size: 12px" class="m-0 p-0">
                <b>{{data.correcao ? data.correcao : (JSON.stringify(data.Correcao) != "{}" ? data.Correcao : 'Entre em Contato com o Suporte')}}</b>
              </span>
            </vs-col>
          </vs-row>
        </VuePerfectScrollbar>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    titulo: { default: "Ver Erros" },
    nota: {},
    json: null,
  },
  data () {
    return {
      modalVerErro: false,
      erro: {},
      confScroll: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
      },
    }
  },
  methods: {
    async openModal () {
      this.modalVerErro = await true
      if (this.json != null) {
        if (this.json.ListaMensagemRetorno != undefined) {
          if (this.json.ListaMensagemRetorno.MensagemRetorno.length > 0) {
            this.erro = this.json.ListaMensagemRetorno.MensagemRetorno
          } else {
            this.erro = [this.json.ListaMensagemRetorno.MensagemRetorno]
          }
        } else {
          this.erro = [this.json]
        }
      } else {
        await this.getErrosConsulta()
      }
    },
    async getErrosConsulta () {
      await this.$vs.loading({
        container: "#erroConsulta",
        scale: 0.6,
      })
      try {
        this.erro = await this.$http.get(`getErroConsulta/${this.nota.rps}`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#erroConsulta > .con-vs-loading")
      }
    },
    async closeModal () {
      this.modalVerErro = false
    },
  },
  async mounted () {
    await this.openModal()
  },
  components: {
    ...components,
  },
};
</script>
<style scoped>
.scroll-erros {
  height: 52vh !important;
}
</style>
