<template>
  <div>
    <vs-button
      icon="edit"
      color="primary"
      size="small"
      type="filled"
      @click="modalEditarPlano = true"
    ></vs-button>

    <vs-popup :title="'Plano ' + plano.id" :active.sync="modalEditarPlano">
      <div class="w-full vs-con-loading__container" :id="'plano' + plano.id">
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Valor Plano"
                @change="verifyValor()"
                v-model="plano.valor"
                icon="R$"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                @change="recalcularValor()"
                class="w-full"
                label="Tecnicos inicias"
                icon="QTD"
                v-model="plano.tecnicos_iniciais"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                @change="recalcularValor()"
                class="w-full"
                label="Horas inciais"
                icon="HRS"
                v-model="plano.horas_iniciais"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                @change="recalcularValor()"
                class="w-full"
                label="Tecnicos manutencao"
                icon="QTD"
                v-model="plano.tecnicos_manutencao"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                @change="recalcularValor()"
                class="w-full"
                label="Horas manutencao"
                icon="HRS"
                v-model="plano.horas_manutencao"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Dias entre Manutencoes"
                v-model="plano.periodo_manutencao"
                icon="DIA"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                @change="recalcularValor()"
                class="w-full"
                label="Aplicações"
                v-model="plano.aplicacoes"
                icon="QTD"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Garantia"
                v-model="plano.garantia"
                icon="QTD"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Validade"
                v-model="plano.validade"
                icon="QTD"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                @change="recalcularValor()"
                class="w-full"
                label="Desconto"
                :max="
                  limites_desconto.length > 0 && limites_desconto != undefined
                    ? limites_desconto[this.plano.id_tipo_faturamento - 1]
                        .limite_desconto
                    : 0
                "
                v-model="plano.desconto"
                icon="%"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Parcelas"
                v-model="plano.parcelas"
                icon="%"
                icon-after="true"
                type="number"
                color="dark"
                min="1"
              />
            </vx-input-group>
          </vs-col>
        </vs-row>

        <vs-row vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
          <vs-button
            class="mx-2 w-full"
            color="success"
            type="relief"
            @click="passForm()"
            >Atualizar</vs-button
          >
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    plano: {},
    limites_desconto: {},
  },
  data() {
    return {
      modalEditarPlano: false,
      valor: 0,
    };
  },
  methods: {
    async verifyValor() {
      if (this.$atividades.permissoes.includes(17)) {
        return;
      }
      if (parseFloat(this.plano.valor) < parseFloat(this.valor)) {
        this.plano.valor = this.valor;
        this.$vs.notify({
          title: "Valor do plano inválido!",
          text: "O valor do plano só aceita incremento!",
          color: "danger",
        });
      }
    },
    async recalcularValor() {
      this.valor = this.plano.valor =
        200 * (this.plano.horas_iniciais * this.plano.tecnicos_iniciais) +
        200 *
          (this.plano.horas_manutencao *
            this.plano.tecnicos_manutencao *
            (this.plano.aplicacoes - 1));
      //     -
      // (200 * (this.plano.horas_iniciais * this.plano.tecnicos_iniciais) +
      //   200 *
      //     (this.plano.horas_manutencao *
      //       this.plano.tecnicos_manutencao *
      //       (this.plano.aplicacoes - 1))) *
      //   (this.plano.desconto / 100);
      if (this.$atividades.permissoes.includes(17)) {
        return;
      }
      if (
        this.limites_desconto[this.plano.id_tipo_faturamento - 1]
          .limite_desconto < this.plano.desconto
      ) {
        this.plano.desconto = this.limites_desconto[
          this.plano.id_tipo_faturamento - 1
        ].limite_desconto;
        this.$vs.notify({
          title: "Desconto inválido!",
          text:
            "O desconto dado é maior que o limite deste tipo de faturamento!",
          color: "danger",
        });
      }
    },
    async passForm() {
      //await this.recalcularValor();
      //await this.verifyValor();
      await this.atualizar();
    },
    async atualizar() {
      delete this.plano.tipo_faturamento;
      delete this.plano.obs_tipo_faturamento;
      await this.$vs.loading({
        container: "#plano" + this.plano.id,
        scale: 0.6,
      });
      try {
        const res = await this.$http.put("plano/" + this.plano.id, this.plano);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Atualização do plano N°" + res.id,
        };
        await this.$logger(logData);

        this.modalEditarPlano = false;
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        await this.$vs.loading.close(
          "#plano" + this.plano.id + " > .con-vs-loading"
        );
      }
    },
  },
  async mounted() {
    this.valor = this.plano.valor;
  },
};
</script>
