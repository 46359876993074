<template>
  <vs-popup
    :title="'Declinar ' + contrato.estagio + ' Nº.' + contrato.id"
    :active.sync="modalSync"
  >
    <div
      :key="key"
      v-if="acaoLiberada"
      class="w-full vs-con-loading__container"
      id="loadingDeclinar"
    >
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="px-3"
        >
          <vs-textarea v-model="obs" label="Reporte o que aconteceu" />
        </vs-col>
        <vs-col
          vs-w="5"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class
        >
          <div style="display: inline-block; margin-right: 20px">
            <label style="color: black; font-size: 13px">Motivo</label>
            <br />
            <el-select
              filterable
              clearable
              class="select-info"
              placeholder="Selecione..."
              name="frase"
              v-validate="'required'"
              v-model="fraseDeclinioSelect"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="frase in frasesDeclinio"
                class="select-info"
                :value="frase.id"
                :label="frase.frase"
                :key="frase.id"
              ></el-option>
            </el-select>
            <span class="text-danger text-sm" v-show="errors.has('frase')">{{
              $validators.empty
            }}</span>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-1"
        >
          <vx-card class="declinarConf overflow-hidden" @click="submitForm()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class
                  >
                    <span style="font-size: 20px" class="py-1"
                      >CONFIRMAR DECLINIO</span
                    >
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
    <div v-else>Não é possível declinar um documento vencido.</div>
  </vs-popup>
</template>
<script>
export default {
  props: {
    contrato: {
      require: true,
    },
  },
  data() {
    return {
      frasesDeclinio: {},
      fraseDeclinioSelect: null,
      obs: "",
      modalSync: true,
      id_status: null,
      hoje: this.$formartData.dataFiltro(),
      acaoLiberada: false,
      key: 0,
    };
  },
  methods: {
    async currentDateTime() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();

      return date;
    },
    verifyDate() {
      if (new Date(this.contrato.data_vencimento) >= new Date(this.hoje)) {
        this.acaoLiberada = true;
      }
      this.key++;
    },
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.declinar();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            });
          }
        });
      });
    },
    async declinar() {
      // console.log(this.fraseDeclinioSelect);
      await this.$vs.loading({ container: "#loadingDeclinar", scale: 0.6 });
      try {
        const date = new Date();
        const hoje = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        )
          .toISOString()
          .split("T")[0];
        const res = await this.$http.post("mudarStatusContrato", {
          contrato: this.contrato,
          id_frase: this.fraseDeclinioSelect,
          lixeira: 1,
          hoje: hoje,
        });
        await this.$vs.notify(this.$notify.Success);
        console.log(res);
        await this.logAssunto(res);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
        await this.$vs.loading.close();
      } finally {
        await this.$emit("update");
        this.modalSync = await false;
        await this.$vs.loading.close("#loadingDeclinar > .con-vs-loading");
      }
    },
    async getFrasesDeclinio() {
      try {
        this.frasesDeclinio = await this.$http.get(`frasesDeclinio`);
        // this.fraseDeclinioSelect = this.frasesDeclinio[0];
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async logAssunto(res) {
      try {
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "excluir",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: this.contrato.estagio + " N°" + res.id + " declinado",
        // };
        // await this.$logger(logData);
        await this.$http.post("assuntoCliente", {
          id_assuntos: res.id_estagio == 2 ? 7 : 17,
          id_contrato: this.contrato.id,
          id_cliente: this.contrato.id_cliente,
          id_estagio: res.id_estagio,
          status: 2,
          tipo: 5,
          id_colaborador: window.localStorage.getItem("id"),
          obs: this.obs,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  async mounted() {
    await this.verifyDate();
    await this.$vs.loading({ container: "#loadingDeclinar", scale: 0.6 });
    await this.getFrasesDeclinio();
    this.modalSync = await true;
    await this.$vs.loading.close("#loadingDeclinar > .con-vs-loading");
  },
};
</script>
<style lang="scss" scoped>
.declinarConf {
  transition-duration: 50ms;
  background-color: #c0564b;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #c0564b;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
