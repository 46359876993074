<template>
  <vs-popup
    :title="'Restaurar ' + contrato.estagio + ' Nº.' + contrato.id"
    :active.sync="modalSyncRestaurar"
  >
    <div class="w-full vs-con-loading__container" id="loadingRestaurar">
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="px-3"
        >
          <vs-textarea v-model="obs" label="Reporte o que aconteceu" />
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-1"
        >
          <vx-card class="restaurar overflow-hidden" @click="restaurar()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class
                  >
                    <span class="py-1" style="font-size: 20px"
                      >CONFIRMAR RESTAURAÇÃO</span
                    >
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
  </vs-popup>
</template>
<script>
export default {
  props: {
    contrato: {
      require: true,
    },
  },
  data() {
    return {
      obs: "",
      modalSyncRestaurar: true,
      assunto: {},
    };
  },
  methods: {
    async restaurar() {
      await this.$vs.loading({ container: "#loadingRestaurar", scale: 0.6 });
      try {
        const res = await this.$http.post("mudarStatusContrato", {
          contrato: this.contrato,
          id_frase: this.contrato.id_frase,
          lixeira: 0,
        });
        await this.$vs.notify(this.$notify.Success);
        await this.logAssunto(res);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
        await this.$vs.loading.close();
      } finally {
        await this.$emit("update");
        this.modalSyncRestaurar = await false;
        await this.$vs.loading.close("#loadingRestaurar > .con-vs-loading");
      }
    },
    async logAssunto(res) {
      try {
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: this.contrato.estagio + " N°" + res.id + " restaurado",
        // };
        // await this.$logger(logData);
        await this.$http.post("assuntoCliente", {
          id_assuntos: res.id_estagio == 2 ? 8 : 14,
          id_cliente: this.contrato.id_cliente,
          id_contrato: this.contrato.id,
          id_estagio: res.id_estagio,
          status: 2,
          tipo: 5,
          id_colaborador: window.localStorage.getItem("id"),
          obs: this.obs,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  async mounted() {
    this.modalSyncRestaurar = true;
  },
};
</script>
<style lang="scss" scoped>
.restaurar {
  transition-duration: 50ms;
  background-color: #00bba2;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
