<template>
  <section class="pdf-content">
    <vx-card
      no-shadow
      style="background-color: #ffffff; padding: 0% !important"
    >
      <vs-row vs-type="flex" vs-align="center" vs-w="12">
        <vs-col
          vs-w="1.5"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <img width="80" height="80" src="./emops.png" />
        </vs-col>
        <vs-col vs-w="7" vs-justify="flex-start" vs-align="flex-start">
          <span v-if="empresa != undefined">
            <pre style="font-size: 12px; color: black">
<b style="font-size: 15px;text-tranform: uppercase">{{empresa.razao_social}}</b>
 <b>{{empresa.telefone}}</b> - PJ: <b>{{empresa.cnpj}}</b>
INSC.ESTADUAL: {{empresa.inscricao_estadual == 0 ? "ISENTA" : empresa.inscricao_estadual}}-INSC.MUNICIPAL: {{empresa.inscricao_municipal}}
{{empresa.endereco}} - {{empresa.cep}}</pre>
          </span>
        </vs-col>
        <vs-col
          vs-w="3.5"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <div class="w-full">
            <span
              style="
                font-family: Arial, Helvetica, sans-serif;
                letter-spacing: -0.3px;
                word-spacing: -1px;
                font-size: 11px;
                color: black;
              "
            >
              <div class="w-full">
                <b style="font-size: 17px"
                  >{{ estagio }}
                  {{
                    contrato.renovacao > 0 && contrato.id_relacional > 0
                      ? " de Renovação "
                      : ""
                  }}
                  Nº. {{ contrato.id }}</b
                >
              </div>
              <div class="w-full">
                <span style="font-size: 15px"
                  >Data: {{ contrato.data_documento }}
                </span>
              </div>
              <div class="w-full">
                <span
                  style="font-size: 15px"
                  v-if="contrato.vencimento && contrato.id_estagio == 3"
                  >Vencimento: {{ contrato.vencimento }}</span
                >
              </div>
            </span>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
  </section>
</template>
<script>
export default {
  props: {
    estagio: null,
    contrato: null,
    empresa: null
  }
};
</script>

<style lang="scss" scoped>
pre {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: -0.3px;
  word-spacing: -1px;
}
.card-border {
  transition-duration: 50ms;
  background-color: #fbfbfb;
  border-style: solid;
  border-radius: 7px;
  border-color: #000a12;
  border-width: 0.5px;
}
</style>
