<template>
  <div class="vs-con-loading__container" id="headServico">
    <div v-if="enderecos.length > 0">
      <vs-row vs-type="flex" class="mb-3">
        <vs-col
          vs-w="2"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <h4 class="px-2">
            <strong>ENDERECO</strong>
          </h4>
        </vs-col>
        <vs-col vs-w="8" vs-type="flex" vs-justify="center" vs-align="center">
          <h4
            :style="
              servicosAssociadas == aplicacoes
                ? 'color:green'
                : servicosAssociadas > aplicacoes
                ? 'color:red'
                : 'color: #0f4084'
            "
          >
            {{
              servicosAssociadas == aplicacoes
                ? "Quantidade de serviços igual a negociada"
                : servicosAssociadas > aplicacoes
                ? servicosAssociadas -
                  aplicacoes +
                  " serviços mais que o negociado"
                : "Falta " +
                  (parseInt(aplicacoes) - parseInt(servicosAssociadas)) +
                  " serviços"
            }}
          </h4>
        </vs-col>
        <vs-col
          vs-w="2"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
        >
          <SuccessButton
            size="small"
            :disabled="
              !$atividades.permissoes.includes(2) &&
              !$atividades.permissoes.includes(8)
            "
            text="Associar"
            @func="associarEnderecosServicos()"
          ></SuccessButton>
        </vs-col>
      </vs-row>
      <div class="w-full" v-if="enderecos.length > 0" :key="reRender">
        <vs-table :data="enderecos">
          <template slot="thead">
            <vs-th sort-key="qtd" style="width: 20%"
              >Quantidade de Serviços</vs-th
            >
            <vs-th sort-key="local" style="width: 60%">Local do Serviço</vs-th>
            <vs-th style="width: 20%"></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :state="
                tr.id_contrato_endereco > 0 &&
                tr.servicos > 0 &&
                tr.status_botao == 1
                  ? 'success'
                  : null
              "
              class="w-full list-border"
              :data="tr"
              :key="index"
              v-for="(tr, index) in data"
            >
              <vs-td>
                <vs-input
                  type="number"
                  class="pr-5"
                  style="width: 100px"
                  min="0"
                  :disabled="
                    !$atividades.permissoes.includes(2) &&
                    !$atividades.permissoes.includes(8)
                  "
                  @change="calServicosAssociados(enderecos)"
                  v-model="tr.servicos"
                  name="servicos"
                />
              </vs-td>
              <vs-td>
                <span style="font-size: 16px">
                  <b>{{ tr.nome }}</b>
                </span>
              </vs-td>
              <vs-td :key="reRenderButtons">
                <div class="w-full">
                  <vs-row
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <!-- AVISO PARA ADICIONAR QUANTIDADE DE SERVICOS DO ENDERECO -->
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      v-if="!(tr.id_contrato_endereco > 0 && tr.servicos > 0)"
                    >
                      <vx-tooltip
                        color="dark"
                        position="left"
                        text="Adicione serviços ao endereço."
                      >
                        <vs-button
                          type="relief"
                          style="margin: auto !important"
                          color="dark"
                          :disabled="
                            !$atividades.permissoes.includes(2) &&
                            !$atividades.permissoes.includes(8)
                          "
                          icon="priority_high"
                        ></vs-button>
                      </vx-tooltip>
                    </vs-col>
                    <!-- ADICIONAR SERVIÇOS POR HORÁRIO -->
                    <vs-col
                      vs-type="flex"
                      vs-w="4"
                      v-if="
                        tr.id_contrato_endereco > 0 &&
                        tr.servicos > 0 &&
                        tr.status_botao == 0
                      "
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vx-tooltip
                        position="left"
                        color="warning"
                        text="Agendar serviços - por horários"
                      >
                        <vs-button
                          color="warning"
                          icon="schedule"
                          style="margin: auto !important"
                          :disabled="
                            !$atividades.permissoes.includes(2) &&
                            !$atividades.permissoes.includes(8)
                          "
                          @click="
                            openModalServicosAgenda(
                              'ModalServicosAgendaPorHorario',
                              tr,
                              index
                            )
                          "
                          type="relief"
                        />
                      </vx-tooltip>
                    </vs-col>
                    <!-- CRIAR AGENDA -->
                    <vs-col
                      vs-type="flex"
                      vs-w="4"
                      vs-justify="center"
                      vs-align="center"
                      v-if="
                        tr.id_contrato_endereco > 0 &&
                        tr.servicos > 0 &&
                        tr.status_botao == 0
                      "
                    >
                      <vx-tooltip
                        position="left"
                        color="warning"
                        text="Agendar serviços."
                      >
                        <vs-button
                          color="warning"
                          icon="event_note"
                          style="margin: auto !important"
                          :disabled="
                            !$atividades.permissoes.includes(2) &&
                            !$atividades.permissoes.includes(8)
                          "
                          @click="
                            openModalServicosAgenda(
                              'ModalServicosAgenda',
                              tr,
                              index
                            )
                          "
                          type="relief"
                        />
                      </vx-tooltip>
                    </vs-col>
                  </vs-row>
                  <vs-row
                    vs-type="flex"
                    vs-w="12"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-w="12"
                      v-if="
                        tr.id_contrato_endereco > 0 &&
                        tr.servicos > 0 &&
                        tr.status_botao == 1
                      "
                    >
                      <div class="w-full">
                        <vs-row
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-col
                            vs-w="3"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <!-- GERENCIAR DISPOSITIVOS -->
                            <vx-tooltip
                              position="left"
                              color="warning"
                              text="Gerenciar Dispositivos"
                            >
                              <vs-button
                                color="warning"
                                icon="app_settings_alt"
                                style="margin: auto !important"
                                :disabled="
                                  !$atividades.permissoes.includes(2) &&
                                  !$atividades.permissoes.includes(8)
                                "
                                @click="openModalGerenciarDispositivos(tr)"
                                type="relief"
                              />
                            </vx-tooltip>
                          </vs-col>
                          <vs-col
                            vs-w="3"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <!-- EDITAR AGENDA -->
                            <vx-tooltip
                              position="left"
                              color="warning"
                              text="Editar Agenda"
                            >
                              <vs-button
                                color="warning"
                                icon="edit"
                                style="margin: auto !important"
                                :disabled="
                                  !$atividades.permissoes.includes(2) &&
                                  !$atividades.permissoes.includes(8)
                                "
                                @click="
                                  openModalServicosAgenda(
                                    'ModalOsEditarAgenda',
                                    tr,
                                    index
                                  )
                                "
                                type="relief"
                              />
                            </vx-tooltip>
                          </vs-col>
                          <vs-col
                            vs-w="3"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <!-- ADICIONAR SERVICOS EXTRAS -->
                            <vx-tooltip
                              color="success"
                              text="Adicionar serviços extras."
                              position="left"
                            >
                              <vs-button
                                color="success"
                                icon="add"
                                style="margin: auto !important"
                                :disabled="
                                  !$atividades.permissoes.includes(2) &&
                                  !$atividades.permissoes.includes(8)
                                "
                                @click="openModalOsReforco(tr)"
                                type="relief"
                              />
                            </vx-tooltip>
                          </vs-col>
                          <vs-col
                            vs-w="3"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <!-- LIMPAR AGENDA -->
                            <vx-tooltip
                              color="danger"
                              text="Limpar a agenda do endereco?"
                              position="left"
                            >
                              <el-popconfirm
                                confirmButtonText="Excluir"
                                cancelButtonText="Cancelar"
                                icon="el-icon-delete"
                                @confirm="
                                  limparAgenda(tr.id_endereco, tr.id_contrato)
                                "
                                iconColor="red"
                                :title="'Limpar agenda'"
                              >
                                <vs-button
                                  color="danger"
                                  icon="delete"
                                  slot="reference"
                                  style="margin: auto !important"
                                  :disabled="
                                    !$atividades.permissoes.includes(3) &&
                                    !$atividades.permissoes.includes(9)
                                  "
                                  type="relief"
                                />
                              </el-popconfirm>
                            </vx-tooltip>
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-td>

              <template slot="expand">
                <ServicosEnderecos
                  :index="index"
                  :cliente="cliente"
                  :contrato="contrato"
                  @update="getEnderecosParaAssociar()"
                  :item="tr"
                ></ServicosEnderecos>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <div>
        <span v-for="tipo in totalizadores" :key="tipo.tipo">{{ tipo.tipo }}: <b>{{tipo.qtd}}</b> | </span>
      </div>
    </div>
    <div v-else>
      <vs-row type="flex" vs-justify="space-around">
        <vs-col vs-w="12" class="px-5">
          <h1 class="text-center py-10 mt-10">Nenhum Endereco de servico</h1>
          <h1 class="text-center mb-10">
            <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
          </h1>
        </vs-col>
      </vs-row>
    </div>
    <component
      v-bind:is="modalAgendar"
      :key="reRenderModalAgendar"
      :cliente="cliente"
      :endereco="endereco"
      :contrato="contrato"
      :servicoEndereco="servicoEnderecoOs"
      @update="getEnderecosParaAssociar()"
    />
    <component
      v-bind:is="modalOsReforco"
      :key="reRenderModalOsReforco"
      :cliente="cliente"
      :endereco="endereco"
      :contrato="contrato"
      @update="getEnderecosParaAssociar()"
    />
    <component
      v-bind:is="modalManageDevices"
      :key="reRenderModalManageDevices"
      :client="cliente"
      :contract="contrato"
      :address="endereco"
      @update="getEnderecosParaAssociar()"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import ModalServicosAgenda from "./modalServicosAgenda";
import ModalServicosAgendaPorHorario from "./modalServicosAgendaPorHorario";
import ModalOsReforco from "./modalOsReforco";
import ServicosEnderecos from "./servicosEndereco";
import ModalOsEditarAgenda from "./modalOsEditarAgenda";
import ModalManageDevices from "./manage-devices/modal.vue";
export default {
  props: {
    cliente: { require: true },
    contrato: { require: true },
    aplicacoes: null,
  },
  data() {
    return {
      servicosAssociadas: null,
      acima: null,
      endereco: null,
      enderecos: [],
      servicosEndereco: [],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000,
      },
      reRender: 0,
      osSelecionado: {},
      ModalRemarcar: "",
      reRenderModalRemarcar: 0,
      modalAgendar: "",
      reRenderModalAgendar: 0,
      servicoEnderecoOs: null,
      reRenderModalOsReforco: 0,
      modalOsReforco: null,
      reRenderButtons: 0,
      modalManageDevices: null,
      reRenderModalManageDevices: 0,
      totalizadores: null,
    };
  },
  methods: {
    async getEnderecosParaAssociar() {
      try {
        this.enderecos = await this.$http.post("getEnderecosParaAssociar", {
          id_cliente: this.contrato.id_cliente,
          id_contrato: this.contrato.id,
        });
        await this.enderecos.map(async function (x) {
          return (await x.servicos) > 0
            ? (x, (x.nulo = false))
            : ((x.servicos = 0), (x.nulo = true));
        });
        this.calServicosAssociados(this.enderecos);
        this.reRender += 1;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.reRenderButtons += await 1;
      }
    },

    async limparAgenda(id_endereco, id_contrato) {
      try {
        await this.$vs.loading({ container: "#headServico", scale: 0.6 });
        await this.$http.post("limparAgenda", [
          { id_endereco: id_endereco, id_contrato: id_contrato },
        ]);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto:
            "Limpou a agenda do contrato N°" +
            id_contrato +
            "  Endereco: " +
            id_endereco,
        };
        await this.$logger(logData);
        this.$vs.notify({
          title: "Successo",
          text: "Agenda excluida com successo",
          color: "success",
        });
        await this.getEnderecosParaAssociar();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#headServico > .con-vs-loading");
    },

    async associarEnderecosServicos() {
      try {
        await this.$vs.loading({ container: "#headServico", scale: 0.6 });
        let enderecos = [];
        for (let i = 0; i < this.enderecos.length; i++) {
          if (this.enderecos[i].servicos > 0) {
            enderecos.push(this.enderecos[i]);
          }
        }
        await this.$http.post("associarEnderecosServicos", [
          enderecos,
          { id_contrato: this.contrato.id },
        ]);
        this.reRender += 1;
        await this.getEnderecosParaAssociar();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#headServico > .con-vs-loading");
    },

    async calServicosAssociados(enderecos) {
      this.servicosAssociadas = enderecos.reduce((total, endereco) => {
        return total + parseInt(endereco.servicos);
      }, 0);
    },

    async openModalServicosAgenda(component, data, index) {
      this.endereco = data;
      this.servicoEnderecoOs = this.servicosEndereco[index];
      this.modalAgendar = component;
      this.reRenderModalAgendar += 1;
    },

    async openModalOsReforco(data) {
      this.endereco = data;
      this.modalOsReforco = "ModalOsReforco";
      this.reRenderModalOsReforco += 1;
    },

    async openModalGerenciarDispositivos(data) {
      this.endereco = data;
      this.modalManageDevices = "ModalManageDevices";
      this.reRenderModalManageDevices += 1;
    },

    async getTotalizadores(){
      try{
        this.totalizadores = await this.$http.post(`getTotalizadores`, { id_contrato: this.contrato.id })
      }catch (err){
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }

    },
  },

  async mounted() {
    await this.$vs.loading({ container: "#headServico", scale: 0.6 });
    await this.getEnderecosParaAssociar();
    await this.getTotalizadores()
    await this.$vs.loading.close("#headServico > .con-vs-loading");
  },

  components: {
    ...components,
    ModalServicosAgenda,
    ModalServicosAgendaPorHorario,
    ModalOsEditarAgenda,
    ModalOsReforco,
    ServicosEnderecos,
    ModalManageDevices,
  },
};
</script>
<style lang="scss">
.sizeScroll {
  height: 60vh !important;
}
.feather-chevron-up {
  width: 30px !important;
  height: 30px !important;
}
.card-servico {
  border-radius: 8px;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-4.5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #00bba2;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.el-popconfirm__main {
  font-size: 12px !important;
}
</style>
