<template>
  <vs-popup
    :title="'Gerar Contrato da ' + contrato.estagio + ' Nº.' + contrato.id"
    :active.sync="modalSyncGerarContrato"
  >
    <div class="w-full vs-con-loading__container" id="loadingGerarContrato">
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="px-3"
        >
          <vs-textarea v-model="obs" label="Observação" />
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-1"
        >
          <vx-card class="renovar overflow-hidden" @click="gerarContrato()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class
                  >
                    <span class="py-1" style="font-size: 20px"
                      >GERAR CONTRATO</span
                    >
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
  </vs-popup>
</template>
<script>
export default {
  props: {
    contrato: {
      require: true,
    },
  },
  data() {
    return {
      obs: "",
      modalSyncGerarContrato: true,
    };
  },
  methods: {
    async gerarContrato() {
      if(this.validaDadosEntrada()){
        await this.$vs.loading({
          container: "#loadingGerarContrato",
          scale: 0.6,
        });
        try {
          const vencimento = await this.$formartData.somarMeses(
            new Date(),
            parseInt(this.contrato.garantia || 0)
          );
          const res = await this.$http.put("contrato/" + this.contrato.id, {
            id_status: 1,
            obs: this.obs,
            data_vencimento: vencimento,
            id_estagio: 3,
            data_contrato: this.$formartData.dataHoje(),
            id_colaborador: await localStorage.getItem("id"),
          });
          this.modalSyncGerarContrato = false;
          await this.$vs.notify(this.$notify.Success);
          this.$emit("update");
          //await this.logAssunto(res);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          await this.$vs.loading.close("#loadingGerarContrato > .con-vs-loading");
        }
      }
    },
    async logAssunto(res) {
      try {
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "update",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Geração do contrato de renovação N°" + res.id,
        };
        await this.$logger(logData);

        let id_assunto = 13;
        if (this.contrato.renovacao > 0 && this.contrato.id_relacional > 0) {
          id_assunto = 16;
        }
        await this.$http.post("assuntoCliente", {
          id_assuntos: id_assunto,
          id_cliente: this.contrato.id_cliente,
          id_contrato: res.id,
          id_estagio: res.id_estagio,
          status: 2,
          tipo: this.contrato.id_tipo_contato,
          id_colaborador: window.localStorage.getItem("id"),
          obs: this.obs,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },

    validaDadosEntrada() {
      const { entrada, data_entrada } = this.contrato;
      if ((entrada || data_entrada != null) && !(entrada && data_entrada != null)) {
        this.$vs.notify({
          title: 'ERRO',
          text: 'Não é possível informar a entrada sem a data ou ao contrario.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
        return false;
      }

      return true;
    }
  },
  async mounted() {
    this.modalSyncGerarContrato = true;
    this.obs = this.contrato.obs;
  },
};
</script>
<style lang="scss" scoped>
.renovar {
  transition-duration: 50ms;
  background-color: #021a33;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #021a33;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
