<template>
  <vx-card v-if="result">
    <div class="w-full vs-con-loading__container" id="faturamento">
      <div>
        <vs-row
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="mb-3"
        >
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <h5 style="color: black"></h5>
          </vs-col>
          <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
            <h5
              :style="result.validation ? 'color: black' : 'color:red'"
              class="font-semibold"
            >
              {{
                result.validation == false
                  ? "Não é Possivel Faturar o Contrato"
                  : "Todos os campos preenchidos"
              }}
            </h5>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
          >
            <menuFaturamento
              @update="update"
              :result="result"
              :contrato="contrato"
            ></menuFaturamento>
          </vs-col>
        </vs-row>
        <div class="px-5">
          <vs-row
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="mb-5"
          >
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <span style="font-size: 14px" class="font-bold">
                {{
                  result.validation == false
                    ? "Verifique os campos obrigatórios e tente novamente"
                    : "Contrato pronto para ser faturado"
                }}
              </span>
            </vs-col>
          </vs-row>
          <vx-card class="mb-3" no-shadow card-border>
            <vs-row
              class="mt-3"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
            >
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.tecnicos_iniciais == 0 ||
                    result.tecnicos_iniciais == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px">
                    Técnicos Iniciais
                  </span></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.horas_iniciais == 0 || result.horas_iniciais == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Horas Iniciais</span
                  ></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.tecnicos_manutencao == 0 ||
                    result.tecnicos_manutencao == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Técnicos Manutenção</span
                  ></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.horas_manutencao == 0 ||
                    result.horas_manutencao == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Horas Manutenção</span
                  ></vs-checkbox
                >
              </vs-col>

              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.data_vencimento == 0 ||
                    result.data_vencimento == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Vencimento</span
                  ></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.data_contrato == 0 || result.data_contrato == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Data Contrato</span
                  ></vs-checkbox
                >
              </vs-col>

              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.id_colaborador == 0 || result.id_colaborador == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Vendedor</span
                  ></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.id_controle == 0 || result.id_controle == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Controle</span
                  ></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.aplicacoes == 0 || result.aplicacoes == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Aplicações</span
                  ></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    result.periodo_manutencao == 0 ||
                    result.periodo_manutencao == null
                      ? false
                      : true
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Dias entre Manutenções</span
                  ></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="
                    (result.entrada > 0 &&
                      result.id_forma_pagamento_entrada > 0) ||
                    (result.parcelas > 0 &&
                      result.id_forma_pagamento_parcela > 0 &&
                      result.intervalo_parcela > 0)
                      ? true
                      : false
                  "
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Forma de Pagamento</span
                  ></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="result.faturamento > 0 ? true : false"
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Endereco de faturamento</span
                  ></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="result.nome_cliente"
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >{{
                      result.tipo_pessoa == 1
                        ? "Cliente (Nome Fantasia)"
                        : "Cliente (Razão Social)"
                    }}
                  </span></vs-checkbox
                >
              </vs-col>
              <vs-col
                vs-w="3"
                class="my-2"
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
              >
                <vs-checkbox
                  color="success"
                  :checked="result.cpf_cnpj"
                  disabled="true"
                  ><span class="font-bold" style="font-size: 12.5px"
                    >Cliente (CPF ou CNPJ)
                  </span></vs-checkbox
                >
              </vs-col>
            </vs-row>
          </vx-card>
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import components from "@/components/default/exports.js";
import menuFaturamento from "./menuFaturamento";
export default {
  props: {
    contrato: {
      require: true,
    },
  },
  data() {
    return {
      modalSyncFaturar: true,
      result: {},
    };
  },
  methods: {
    async validarFaturamentoContrato() {
      try {
        this.result = await this.$http.get(
          `validarFaturamentoContrato/` + this.contrato.id
        );
        this.$emit("getValidacao", this.result);
        console.log(this.result);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async update() {
      await this.$emit("update");
    },
  },
  async mounted() {
    await this.$vs.loading({
      container: "#faturamento",
      scale: 0.6,
    });
    this.modalSyncFaturar = true;
    await this.validarFaturamentoContrato();
    await this.$vs.loading.close("#faturamento > .con-vs-loading");
  },
  components: {
    ...components,
    menuFaturamento,
  },
};
</script>
