<template>
  <div class="w-full">
    <vs-row vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
      <vs-col vs-w="12" vs-type="flex" vs-align="center" vs-justify="flex-end">
        <popup-gerar-plano
          :contrato="contrato"
          :result="result"
          @update="emitUpdate"
        />
        <popup-alterar-datas
          :contrato="contrato"
          :result="result"
          @update="emitUpdate"
        />
        <popup-cal-novo-valor
          :contrato="contrato"
          :result="result"
          @update="emitUpdate"
        />
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import PopupAlterarDatas from "./popupAlterarDatas.vue";
import PopupCalNovoValor from "./popupCalNovoValor.vue";
import PopupGerarPlano from "./popupGerarPlano.vue";
export default {
  props: {
    contrato: {
      require: true,
    },
    result: { validation: false },
  },
  methods: {
    async emitUpdate() {
      await this.$emit("update");
    },
  },
  components: {
    PopupCalNovoValor,
    PopupAlterarDatas,
    PopupGerarPlano,
  },
};
</script>
