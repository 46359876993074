<template>
  <div class="w-full">
    <SideBar
      title="REENVIAR RPS "
      @hide="close()"
      :active="modal"
      size="large"
      :visualizar="blockActions"
      textSucces="Reenviar"
      icon="note"
      @success="submitForm()"
    >
      <div slot="body" class="mx-2">
        <div
          class="w-full vs-con-loading__container"
          :id="'loadingNota' + fatura.id"
        >
          <div class="w-full">
            <VuePerfectScrollbar
              class="scroll-reenviar-rps"
              :settings="settings"
            >
              <div class="w-full">
                <Tomador :cliente="cliente" />
                <vs-row
                  vs-justify="center"
                  vs-align="flex-start"
                  vs-type="flex"
                >
                  <vx-card no-shadow card-border>
                    <vs-row
                      vs-justify="center"
                      vs-align="center"
                      vs-type="flex"
                    >
                      <vs-col
                        vs-w="3"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <div class="w-full mx-2">
                          <vs-input
                            color="dark"
                            class="w-full"
                            type="number"
                            name="rps"
                            v-validate="'required'"
                            label="RPS"
                            v-model="nota.rps_novo"
                          ></vs-input>
                          <vs-row>
                            <span
                              style="font-size: 12px"
                              class="text-danger mx-3"
                              v-show="errors.has('rps')"
                              >{{ $validators.empty }}</span
                            >
                          </vs-row>
                        </div>
                      </vs-col>
                      <vs-col
                        vs-w="3"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <div class="w-full mx-2">
                          <vs-input
                            color="dark"
                            class="w-full"
                            name="valorDoc"
                            type="text"
                            disabled="true"
                            label="Valor Bruto"
                            icon="R$"
                            icon-after="true"
                            v-model="nota.valor_bruto"
                          ></vs-input>
                        </div>
                      </vs-col>
                      <vs-col
                        vs-w="3"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <div class="w-full mx-2">
                          <vs-input
                            color="dark"
                            class="w-full"
                            name="valorDoc"
                            type="text"
                            v-validate="'required'"
                            label="Valor Líquido"
                            icon="R$"
                            icon-after="true"
                            @change="
                              desconto = 0;
                              nota.iss_retido = 0;
                              reRenderComponents += 1;
                            "
                            :key="reRenderComponents"
                            v-model="nota.valor_liquido"
                          ></vs-input>
                          <vs-row>
                            <span
                              style="font-size: 12px"
                              class="text-danger mx-3"
                              v-show="errors.has('valorDoc')"
                              >{{ $validators.empty }}</span
                            >
                          </vs-row>
                        </div>
                      </vs-col>
                      <vs-col
                        vs-w="3"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="mx-2">
                          <vs-checkbox
                            color="success"
                            @change="descontoValor($event.type)"
                            :key="reRenderComponents"
                            v-model="nota.iss_retido"
                            class="pt-5"
                            >RETER ISS</vs-checkbox
                          >
                        </div>
                      </vs-col>
                    </vs-row>
                    <vs-row
                      vs-w="12"
                      vs-justify="center"
                      vs-align="flex-start"
                      vs-type="flex"
                    >
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full px-2 my-3">
                          <vs-textarea
                            style="text-align: justify"
                            height="100"
                            class="m-0"
                            color="dark"
                            v-model="nota.obs"
                            label="Observação"
                          />
                        </div>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </vs-row>
                <vs-divider class="my-3 font-semibold">Tributações</vs-divider>
                <vs-row
                  class="my-3"
                  vs-justify="center"
                  vs-align="flex-start"
                  vs-type="flex"
                >
                  <vx-card no-shadow card-border>
                    <vs-row
                      class="mb-2"
                      vs-w="12"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                      vs-type="flex"
                    >
                      <vs-col
                        vs-w="3"
                        class="m-0"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <vs-input
                          color="dark"
                          class="mx-2"
                          @change="reRenderComponents += 1"
                          name="pis"
                          type="text"
                          min="0"
                          v-validate="'required'"
                          :label="
                            'PIS: ' +
                            (empresa.valorPis ? empresa.valorPis : 0) +
                            '%'
                          "
                          icon="R$"
                          :key="reRenderComponents"
                          icon-after="true"
                          v-model="nota.valorPis"
                        ></vs-input>
                      </vs-col>
                      <vs-col
                        vs-w="3"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <vs-input
                          color="dark"
                          class="mx-2"
                          name="cofins"
                          type="text"
                          min="0"
                          @change="reRenderComponents += 1"
                          :key="reRenderComponents"
                          v-validate="'required'"
                          :label="
                            'COFINS: ' +
                            (empresa.valorCofins ? empresa.valorCofins : 0) +
                            '%'
                          "
                          icon="R$"
                          icon-after="true"
                          v-model="nota.valorCofins"
                        ></vs-input>
                      </vs-col>
                      <vs-col
                        vs-w="3"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <vs-input
                          color="dark"
                          class="mx-2"
                          name="csll"
                          @change="reRenderComponents += 1"
                          type="text"
                          :key="reRenderComponents"
                          min="0"
                          v-validate="'required'"
                          :label="
                            'CSLL: ' +
                            (empresa.valorCsll ? empresa.valorCsll : 0) +
                            '%'
                          "
                          icon="R$"
                          icon-after="true"
                          v-model="nota.valorCsll"
                        ></vs-input>
                      </vs-col>
                      <vs-col
                        vs-w="3"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <vs-input
                          color="dark"
                          class="mx-2"
                          name="ir"
                          type="text"
                          @change="reRenderComponents += 1"
                          :key="reRenderComponents"
                          min="0"
                          :label="
                            'IR: ' +
                            (empresa.valorIr ? empresa.valorIr : 0) +
                            '%'
                          "
                          icon="R$"
                          icon-after="true"
                          v-model="nota.valorIr"
                        ></vs-input>
                      </vs-col>
                      <vs-col
                        vs-w="3"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <vs-input
                          color="dark"
                          class="mx-2"
                          name="inss"
                          type="text"
                          min="0"
                          :label="
                            'INSS: ' +
                            (empresa.valorInss ? empresa.valorInss : 0) +
                            '%'
                          "
                          icon="R$"
                          :key="reRenderComponents"
                          @change="reRenderComponents += 1"
                          icon-after="true"
                          v-model="nota.valorInss"
                        ></vs-input>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </vs-row>
                <vs-divider class="my-0 font-semibold"
                  >Datas de Vencimento</vs-divider
                >
                <vs-row
                  class="my-3"
                  vs-justify="center"
                  vs-align="flex-start"
                  vs-type="flex"
                >
                  <vx-card no-shadow card-border :key="reRenderComponents">
                    <vs-row
                      class="mb-2"
                      vs-align="center"
                      vs-justify="flex-start"
                      vs-type="flex"
                      vs-w="12"
                    >
                      <vs-col
                        vs-align="center"
                        vs-justify="center"
                        vs-type="flex"
                        vs-w="4"
                        v-for="(data, index) in datasVencimento"
                        :key="index"
                      >
                        <div class="w-full mx-2">
                          <vs-input
                            :label="'Data Parcela N° ' + (index + 1)"
                            type="date"
                            v-validate="'required'"
                            color="dark"
                            :name="'datas' + index"
                            v-model="datasVencimento[index].data"
                          />
                          <vs-input
                            class="mt-3"
                            :label="'Valor Parcela N° ' + (index + 1)"
                            type="number"
                            v-validate="'required'"
                            color="dark"
                            :name="'valores' + index"
                            v-model="datasVencimento[index].valor"
                          />
                          <vs-row
                            vs-w="12"
                            vs-justify="flex-start"
                            vs-align="center"
                          >
                            <span
                              style="font-size: 12px"
                              class="text-danger"
                              v-show="errors.has('datas' + index)"
                              >{{ $validators.empty }}</span
                            >
                          </vs-row>
                        </div>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </vs-row>
                <Prestador :empresa="empresa" />
              </div>
            </VuePerfectScrollbar>
          </div>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"
import Tomador from "./tomador"
import Prestador from "./prestador"
export default {
  props: {
    fatura: null,
    contrato: {},
    nota: {}
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      cliente: {},
      empresa: {},
      modal: false,
      jsonNota: {},
      desconto: 0,
      reRenderComponents: 0,
      blockActions: false,
      enderecoFaturamento: {},
      parametros: {},
      textoPorcentagensServico: "",
      tributos: 0,
      datasVencimento: []
    }
  },
  methods: {
    //FUNÇÕES INICIAL
    async openModal () {
      await this.$vs.loading({
        container: "#loadingNota" + this.fatura.id,
        scale: 0.6
      })
      this.blockActions = true
      this.modal = await true
      try {
        this._beforeEditingCache = await Object.assign({}, this.nota)
        this.parametros = await this.$parametros.get()
        await this.getCliente()
        await this.getEmpresa()
        await this.getEnderecoFaturamento()
        await this.setPorcentagensServico()
        await this.setDatasVencimento()
        await this.setCamposNota()
        await this.montarObs()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.blockActions = false
        await this.$vs.loading.close(
          "#loadingNota" + this.fatura.id + " > .con-vs-loading"
        )
      }
    },

    //FUNÇÕES SET PARA OS VALORES DO REENVIO DO RPS
    //SETAR OS CAMPOS NECESSARIOS PARA O REENVIO DO RPS
    async setCamposNota () {
      this.nota.rps_novo = this.nota.rps
      this.desconto =
        this.nota.valor_bruto - this.nota.valor_liquido - this.tributos
      // this.desconto = this.nota.valor_bruto - this.nota.valor_liquido
    },
    //SETAR AS DATAS DE VENCIMENTO DAS PARCELAS, DEPENDENDO DO TIPO DO CONTRATO
    async setDatasVencimento () {
      this.datasVencimento = []
      let dataVencimento

      const hasEntrada = this.contrato.entrada > 0 && this.contrato.id_forma_pagamento_entrada > 0 && this.contrato.data_entrada != null

      if (hasEntrada) {
        dataVencimento = await this.$formartData.formatar(
          this.contrato.data_entrada,
          1
        )
      } else {
        dataVencimento = await this.$formartData.formatar(
          this.$formartData.dataHoje(),
          1
        )
        dataVencimento = await this.$formartData.somarDias(dataVencimento, 30)
      }
      if (
        this.contrato.id_tipo_faturamento == 1 ||
        this.contrato.id_tipo_faturamento == 2 ||
        this.contrato.id_tipo_faturamento == 3 ||
        this.contrato.id_tipo_faturamento == 6
      ) {
        if (hasEntrada) {
          let dataFormatada = await this.$formartData.formatar(
            dataVencimento,
            1
          )
          this.datasVencimento.push({
            data: dataFormatada,
            valor: this.contrato.entrada,
          })
          dataVencimento = await this.$formartData.somarDias(
            dataVencimento,
            30
          )
        }
        for (let i = 1; i <= this.contrato.parcelas; i++) {
          let dataFormatada = await this.$formartData.formatar(
            dataVencimento,
            1
          )
          this.datasVencimento.push({ data: dataFormatada, valor: 0 })
          dataVencimento = await this.$formartData.somarDias(
            dataVencimento,
            30
          )
        }
      } else {
        let dataFormatada = await this.$formartData.formatar(dataVencimento, 1)
        this.datasVencimento.push({ data: dataFormatada, valor: 0 })
      }
      let cont = 1
      for (let data of this.datasVencimento) {
        if (cont == 1 && hasEntrada) {
          1
        } else {
          if (
            this.contrato.entrada > 0 &&
            this.contrato.id_forma_pagamento_entrada > 0
          ) {
            let valorConta = (
              (this.nota.valor_liquido - this.contrato.entrada) /
              (this.datasVencimento.length - 1)
            ).toFixed(2)

            if (cont == this.datasVencimento.length) {
              valorConta = (valorConta - ((valorConta * (this.datasVencimento.length - 1)) - (this.nota.valor_liquido - this.contrato.entrada))).toFixed(2)
            }
            data.valor = valorConta

          } else {
            let valorConta = (this.nota.valor_liquido / this.datasVencimento.length).toFixed(2)
            if (cont == this.datasVencimento.length) {
              valorConta = (valorConta - ((valorConta * this.datasVencimento.length) - this.nota.valor_liquido)).toFixed(2)
            }
            data.valor = valorConta
          }
        }
        cont++
      }
      this.reRenderComponents += 1
    },
    //SETAR AS PORCENTAGENS DAS TRIBUTAÇÕES QUE O TIPO DE PESSOA RECEBE
    async setPorcentagensServico () {
      let auxSomaValor = 0,
        somaPisCsllCofins = 0
      if (this.empresa.isenta_imposto == 0 && this.cliente.tipo_pessoa == 2) {
        somaPisCsllCofins =
          (this.empresa.valorCofins * this.nota.valor_bruto) / 100 +
          (this.empresa.valorPis * this.nota.valor_bruto) / 100 +
          (this.empresa.valorCsll * this.nota.valor_bruto) / 100
        auxSomaValor = (
          (this.empresa.valorCofins * this.nota.valor_bruto) /
          100
        ).toFixed(2)
        this.nota.valorCofins = somaPisCsllCofins <= 10.0 ? 0.0 : auxSomaValor
        auxSomaValor = (
          (this.empresa.valorPis * this.nota.valor_bruto) /
          100
        ).toFixed(2)
        this.nota.valorPis = somaPisCsllCofins <= 10.0 ? 0.0 : auxSomaValor
        auxSomaValor = (
          (this.empresa.valorInss * this.nota.valor_bruto) /
          100
        ).toFixed(2)
        this.nota.valorInss = auxSomaValor <= 10.0 ? 0.0 : auxSomaValor
        auxSomaValor = (
          (this.empresa.valorIr * this.nota.valor_bruto) /
          100
        ).toFixed(2)
        this.nota.valorIr = auxSomaValor <= 10.0 ? 0.0 : auxSomaValor
        auxSomaValor = (
          (this.empresa.valorCsll * this.nota.valor_bruto) /
          100
        ).toFixed(2)
        this.nota.valorCsll = somaPisCsllCofins <= 10.0 ? 0.0 : auxSomaValor

        this.tributos =
          parseFloat(this.nota.valorInss) +
          parseFloat(this.nota.valorIr) +
          parseFloat(this.nota.valorCsll) +
          parseFloat(this.nota.valorCofins) +
          parseFloat(this.nota.valorPis)

        this.textoPorcentagensServico =
          somaPisCsllCofins <= 10.0
            ? `Para PIS/COFINS/CSLL \nÉ dispensada a retenção de igual ou inferior a R$ 10,00 exceto no DARF eletrônico emitido por meio do SIAFI (Lei nº 10.833/2003, art. 31 §3º); \nPara IR \nLEI Nº 9.430, DE 27 DE DEZEMBRO DE 1996; Dispensa de Retenção de Imposto de Renda; Art. 67. Fica dispensada a retenção de imposto de renda, de valor igual ou inferior a R$ 10,00 (dez reais);`
            : ""
      } else {
        this.nota.valorInss = 0
        this.nota.valorIr = 0
        this.nota.valorCsll = 0
        this.nota.valorCofins = 0
        this.nota.valorPis = 0
        this.textoPorcentagensServico = ""
      }
      this.reRenderComponents += 1
    },
    //SETAR O JSON QUE É ENVIADO PARA A API PARA SER PROCESSADO
    async setJsonNota () {
      // let discriminacao = `${this.contrato.controle} com ${this.contrato.garantia
      //   } meses de garantia. CONTRATO ${this.contrato.id}. PARCELA ${this.fatura.parcela
      //   }. ${this.contrato.forma_pagamento.toUpperCase()} ${await this.$formartData.formatar(
      //     new Date(),
      //   )}. TRIBUTOS APROXIMADOS de ${this.tributos.toFixed(
      //     2,
      //   )}. ${this.textoPorcentagensServico}`
      let date = this.$formartData.removeTimeZone(new Date())
      date = date.split(".")[0]
      this.jsonNota.id_contrato = this.contrato.id
      this.jsonNota.json = {
        identificacaoRps: {
          numero: parseInt(this.nota.rps_novo),
          serie: 1,
          tipo: 1,
          ambiente: process.env.NODE_ENV,
          passphrase: "1234",
          protocolo: "",
          dataEmissao: date
        },
        prestador: {
          cnpjPrestador: `${await this.$removerMascara.remove(
            `${this.empresa.cnpj}`
          )}`,
          ccmPrestador: `${await this.$removerMascara.remove(
            `${this.empresa.inscricao_municipal}`
          )}`,
          razaoSocialPrestador: `${this.empresa.razao_social}`,
          nomeFantasiaPrestador: `${this.empresa.nome_fantasia}`,
          municipio: `${this.empresa.municipio}`,
          uf: `${this.empresa.uf}`,
          naturezaOperacao: "1",
          optanteSimplesNacional: `${this.empresa.optante_simples_nacional ? 1 : 2
            }`,
          incentivadorCultural: `${this.empresa.incentivador_cultural ? 1 : 2}`
        },
        servico: {
          valorServicos: parseFloat(this.nota.valor_bruto).toFixed(2),
          valorDeducoes: 0.0,
          valorPis: parseFloat(this.nota.valorPis).toFixed(2),
          valorCofins: parseFloat(this.nota.valorCofins).toFixed(2),
          valorInss: parseFloat(this.nota.valorInss).toFixed(2),
          valorIr: parseFloat(this.nota.valorIr).toFixed(2),
          valorCsll: parseFloat(this.nota.valorCsll).toFixed(2),
          issRetido: this.nota.iss_retido ? 1 : 2,
          valorIss: parseFloat(this.desconto).toFixed(2),
          valorIssRetido: this.desconto.toFixed(2),
          outrasRetencoes: 0.0,
          baseCalculo: parseFloat(this.nota.valor_bruto).toFixed(2),
          aliquota: parseFloat(this.parametros.iss).toFixed(2),
          valorLiquidoNfse: parseFloat(this.nota.valor_liquido).toFixed(2),
          descontoCondicionado: 0.0,
          itemListaServico: 713,
          discriminacao: this.nota.obs,
          codigoTributacaoMunicipio: 713
        },
        tomador: {
          tipoPessoa: `${this.cliente.tipo_pessoa}`,
          cnpjTomador: `${await this.$removerMascara.remove(
            `${this.cliente.cpf_cnpj}`
          )}`,
          ccmTomador: `${this.cliente.inscricao_municipal
            ? await this.$removerMascara.remove(
              `${this.cliente.inscricao_municipal}`
            )
            : ""
            }`,
          razaoSocialTomador: `${this.cliente.razao_social
            ? this.cliente.razao_social
            : this.cliente.nome_fantasia
            }`,
          nomeFantasiaTomador: `${this.cliente.nome_fantasia ? this.cliente.nome_fantasia : ""
            }`,
          endereco: `${this.enderecoFaturamento.logradouro
            ? this.enderecoFaturamento.logradouro.substring(0, 100)
            : ""
            } ${this.enderecoFaturamento.complemento
              ? this.enderecoFaturamento.complemento.substring(0, 100)
              : ""
            }`,
          numero: `${this.enderecoFaturamento.numero
            ? this.enderecoFaturamento.numero
            : ""
            }`,
          bairro: `${this.enderecoFaturamento.nomeBairro
            ? this.enderecoFaturamento.nomeBairro
            : ""
            }`,
          municipio: `${this.enderecoFaturamento.nomeCidade
            ? this.enderecoFaturamento.nomeCidade
            : ""
            }`,
          uf: `${this.enderecoFaturamento.uf ? this.enderecoFaturamento.uf : ""
            }`,
          cep: `${this.enderecoFaturamento.cep ? this.enderecoFaturamento.cep : ""
            }`
        }
      }
    },

    //FUNÇÕES PARA SALVAR/ALTERAR VALORES
    //VALIDAR OS CAMPOS QUE PRECISAM SER PREENCHIDOS
    async submitForm () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.enviarRps()
        } else {
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    //RRENVIO DO RPS PARA A API
    async enviarRps () {
      await this.$vs.loading({
        container: "#loadingNota" + this.fatura.id,
        scale: 0.6
      })
      this.blockActions = await !this.blockActions
      try {
        await this.setJsonNota()
        await this.removeValoresTributacao()
        await this.$http.post("redirectSalvarNota", {
          nota: this.nota,
          json: this.jsonNota,
          datas: this.datasVencimento,
          contrato: this.contrato,
          tipo: 2
        })
        await this.$emit("update")
        this.$vs.notify({
          title: "RPS reenviado com sucesso!",
          text: "Consulte o RPS nas opções da nota cadastrada!",
          color: "success"
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.blockActions = !this.blockActions
        this.close()
        await this.$vs.loading.close(
          "#loadingNota" + this.fatura.id + " > .con-vs-loading"
        )
      }
    },
    //DESCONTO DO ISS
    async descontoValor (evento) {
      if (evento == "change") {
        if (this.nota.iss_retido) {
          let descontoValor = parseFloat(
            (this.nota.valor_bruto * this.parametros.iss).toFixed(2)
          )
          this.nota.valor_liquido = parseFloat(
            (this.nota.valor_liquido - descontoValor).toFixed(2)
          )
          this.desconto = descontoValor
          await this.montarObs()
        } else {
          this.nota.valor_liquido = this.nota.valor_liquido + this.desconto
          await this.montarObs()
        }
        await this.setDatasVencimento()
        this.reRenderComponents += 1
      }
    },
    async removeValoresTributacao () {
      delete this.nota.valorCofins
      delete this.nota.valorCsll
      delete this.nota.valorInss
      delete this.nota.valorIr
      delete this.nota.valorPis
    },
    async close () {
      this.nota = Object.assign(this.nota, this._beforeEditingCache)
      this.modal = false
    },

    //GET VALORES PARA A MONTAGEM DO JSON E DA NOTA
    async getEnderecoFaturamento () {
      try {
        this.enderecoFaturamento = await this.$http.get(
          "getEnderecoFaturamento/" + this.cliente.id
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getCliente () {
      try {
        this.cliente = await this.$http.get(
          "cliente/" + this.contrato.id_cliente
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async montarObs () {
      let discriminacao = `${this.contrato.controle} com ${this.contrato.garantia
        } meses de garantia. CONTRATO ${this.contrato.id}. FATURA ${this.fatura.parcela
        }. ${this.contrato.forma_pagamento.toUpperCase()}`
      let parcelaTexto = ""
      for (let data of this.datasVencimento) {
        parcelaTexto +=
          (await this.$formartData.formatar(new Date(data.data))) +
          ": " +
          this.$currency(
            this.nota.valor_liquido / this.datasVencimento.length
          ) +
          ". "
      }
      this.nota.obs = `${discriminacao}. PARCELAS: ${parcelaTexto} TRIBUTOS APROXIMADOS de ${(
        parseFloat(this.nota.valor_bruto) *
        (17 / 100)
      ).toFixed(2)}. ${this.textoPorcentagensServico}`
    },
    async getEmpresa () {
      try {
        this.empresa = await this.$http.get(
          `empresa/` + this.contrato.id_empresa
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    }
  },
  async mounted () {
    await this.openModal()
  },
  components: {
    ...components,
    Tomador,
    Prestador
  }
};
</script>
<style lang="scss">
.scroll-reenviar-rps {
  height: 80vh !important;
}
</style>
