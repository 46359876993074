<template>
  <div class="w-full">
    <div>
      <vs-row vs-w="12" vs-align="flex-start" vs-justify="center">
        <vs-col vs-w="12" vs-align="center" vs-justify="center">
          <div id="simple-calendar-app">
            <div class="vx-card no-scroll-content">
              <calendar-view
                ref="calendar"
                :displayPeriodUom="calendarView"
                :show-date="showDate"
                :events="[]"
                enableDragDrop
                eventBorderHeight="0px"
                eventContentHeight="1.65rem"
                class="theme-default"
                :starting-day-of-week="1"
                @click-date="select"
              >
                <div slot="header" class="mb-4">
                  <div class="vx-row no-gutter">
                    <!-- Current Month -->
                    <div
                      class="vx-col sm:w-3/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"
                    >
                      <div class="flex items-center">
                        <feather-icon
                          :icon="
                            $vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'
                          "
                          @click="updateMonth(-1)"
                          svgClasses="w-5 h-5 m-1"
                          class="cursor-pointer bg-primary text-white rounded-full"
                        />

                        <span
                          class="mx-3 text-xl font-medium whitespace-no-wrap"
                          >{{ showDate | moment("MMM - YYYY") }}</span
                        >

                        <feather-icon
                          :icon="
                            $vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'
                          "
                          @click="updateMonth(1)"
                          svgClasses="w-5 h-5 m-1"
                          class="cursor-pointer bg-primary text-white rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </calendar-view>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <component v-bind:is="showModalForca" :key="reRenderModal" :data="dia">
      </component>
    </div>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
import moduleCalendar from "@/store/calendar/moduleCalendar.js"
import modalForca from "./modalForca.vue"

require("vue-simple-calendar/static/css/default.css")

import Datepicker from "vuejs-datepicker"

export default {
  props: {
  },
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
    modalForca
  },
  data () {
    return {
      showModalForca: null,
      showCardForca: null,
      reRenderModal: 0,
      reRenderCard: 0,
      forcaTurnos: null,
      showDate: new Date(),
      disabledFrom: false,
      id: 0,
      calendarView: "month",
      dia: new Date(),
    }
  },
  methods: {
    async select (d) {
      this.dia = await d
      this.showModalForca = await "modalForca"
      this.reRenderModal = (await this.reRenderModal) + 1
    },

    updateMonth (val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
    },
  },
  async created () {
    this.$store.registerModule("calendar", moduleCalendar)
  },
  beforeDestroy () {
    this.$store.unregisterModule("calendar")
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";
.cv-week,
.cv-weeks {
  height: 24rem;
  //width: 800px !important;
}
.theme-default .cv-event {
  font-size: 0.9rem;
  border-radius: 0%;
  background-color: rgba(202, 202, 202, 0.233);
  color: black !important;
}
</style>
