<template>
  <vs-row
    vs-type="flex"
    vs-justify="flex-end"
    vs-align="flex-end"
    vs-w="12"
    class="mt-3"
  >
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
      <div class="mx-3">
        <vs-button
          @click="$emit('generate-multiples-qrcode')"
          icon="print"
          size="small"
          color="success"
          type="relief"
        >
          Imprimir todos
        </vs-button>
      </div>
      <div class="mx-1">
        <vs-checkbox
          v-if="!onlyActives"
          v-model="listOpts.showDeleted"
          @change="onChangeShowDeleted($event.type)"
          color="success"
          class="py-2"
        >
          Mostrar dispositivos excluídos
        </vs-checkbox>
      </div>
    </vs-col>
    <vs-col vs-w="12" vs-tpe="flex" vs-align="center" vs-justify="center">
      <VuePerfectScrollbar
        class="scroll-manage-devices p-2"
        :settings="settings"
      >
        <div class="w-full" v-if="devices.length > 0">
          <vs-table :data="devices" stripe>
            <template slot="thead">
              <vs-th class="center-th" sort-key="sequencia" style="width: 10%">
                Sequência
              </vs-th>
              <vs-th style="width: 25%" class="center-th">
                Tipo do dispositivo
              </vs-th>
              <vs-th style="width: 20%" class="center-th"> Área </vs-th>
              <vs-th style="width: 10%" class="center-th" v-if="onlyPests">Consumido</vs-th>
              <vs-th style="width: 10%" class="center-th">Qtd. Pragas</vs-th>
              <vs-th style="width: 25%" class="center-th">Ações</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                class="w-full list-border"
                :data="device"
                :key="index"
                v-for="(device, index) in data"
              >
                <vs-td class="text-center">
                  <span class="text-md">
                    <b> {{ device.sequencia }} </b>
                  </span>
                </vs-td>
                <vs-td class="text-center">
                  <span class="text-md">
                    <b> {{ device.dispositivoTipo }} {{ device.deConsumo ? "(De consumo)" : "" }} </b>
                  </span>
                </vs-td>
                <vs-td class="text-center">
                  <span class="text-md">
                    <b> {{ device.area }} </b>
                  </span>
                </vs-td>
                <vs-td class="text-center" v-if="onlyPests">
                  <span class="text-md">
                    <b>
                      {{
                        device.consumido == null
                          ? "-"
                          : device.consumido
                          ? "Sim"
                          : "Não"
                      }}
                    </b>
                  </span>
                </vs-td>
                <vs-td class="text-center">
                  <span class="text-md">
                    <b> {{ device.qtd_pragas ? device.qtd_pragas : "-" }} </b>
                  </span>
                </vs-td>
                <vs-td class="text-center">
                  <div class="w-full">
                    <actions-devices
                      :device="device"
                      :disable-actions="disableActions"
                      :only-pests="onlyPests"
                      @generate-qrcode="$emit('generate-qrcode', device)"
                      @edit="$emit('edit', device)"
                      @device-pests="$emit('device-pests', device)"
                      @remove="$emit('remove', device)"
                      @restore="$emit('restore', device)"
                      @save-pests="$emit('save-pests', device)"
                    ></actions-devices>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="w-full" v-else>
          <span
            class="pt-2"
            style="color: grey !important; font-weight: 1000 !important"
          >
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
            </vs-col>
            <vs-col
              class="mt-2"
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <span>NÃO HÁ DISPOSTIVOS CADASTRADOS</span>
            </vs-col>
          </span>
        </div>
      </VuePerfectScrollbar>
    </vs-col>
  </vs-row>
</template>
<script>
import components from "@/components/default/exports.js";
import ActionsDevices from "./actionsDevices.vue";

export default {
  props: {
    devices: Array,
    listOpts: Object,
    onlyActives: Boolean,
    onlyPests: Boolean,
    disableActions: Boolean,
  },

  components: { ...components, ActionsDevices },

  data() {
    return {
      settings: {
        maxScrollbarLength: 200,
        wheelSpeed: 0.6,
        height: 1000,
      },
    };
  },

  methods: {
    onChangeShowDeleted(event) {
      if (event == "change") {
        this.$emit("list");
      }
    },
  },
};
</script>
<style lang="scss">
.scroll-manage-devices {
  max-height: 60vh !important;
}

thead tr th.center-th {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
