<template>
  <div id="loader_manage_blueprint" class="vs-con-loading__container">
    <vs-row
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      :key="reRenderFileInput"
    >
      <vs-col vs-w="12">
        <image-picker
          @embed-file="embedFile"
          @readed-data="readedData"
          title="Faça um novo Upload da Planta para começar a editar"
          embed-ref="url"
          image-data-ref="source"
          class="mx-3"
          v-show="!isEditing"
        >
        </image-picker>
      </vs-col>
      <vs-col vs-w="12" v-if="embedSrc.url" :key="keyEmbedFile">
        <blueprint-canvas
          @save="save"
          @cancel="cancelEditing"
          :image-url="embedSrc.url"
          :image-data="imageData"
          :number-of-devices="blueprint.qtd_dispositivos"
          :nome-dos-dispositivos="blueprint.nomeDosDispositivos"
        >
        </blueprint-canvas>
      </vs-col>
    </vs-row>

    <vs-row
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      v-if="!embedSrc.url && previewImg.url"
    >
      <vs-divider>
        <h4 style="color: #b4aa99">Pré-visualização da Planta</h4>
      </vs-divider>

      <vs-col vs-w="6" vs-type="flex" vs-align="center" vs-justify="center">
        <image-preview
          title="Planta Original"
          :embed-url="previewImg.url"
        ></image-preview>
      </vs-col>
      <vs-col vs-w="6" vs-type="flex" vs-align="center" vs-justify="center">
        <image-preview
          title="Planta Editada"
          :embed-url="previewImg.edited_url"
        ></image-preview>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import ImagePicker from "./imagePicker.vue";
import ImagePreview from "./imagePreview.vue";
import BlueprintCanvas from "./canvas/blueprint-canvas.vue";

export default {
  props: {
    client: Object,
    contract: Object,
    address: Object,
  },

  components: {
    ImagePicker,
    ImagePreview,
    BlueprintCanvas,
  },

  data() {
    return {
      reRenderFileInput: 0,
      blueprint: {
        id: null,
        qtd_dispositivos: 0,
        arquivo_url: null,
        arquivo_editado_url: null,
        nomeDosDispositivos: null,
      },
      loaderContainer: "#loader_manage_blueprint",
      embedSrc: {
        url: null,
        edited_url: null,
      },
      imageData: {
        source: null,
        edited_source: null,
      },
      previewImg: {
        url: null,
        edited_url: null,
      },
      isEditing: false,
      keyEmbedFile: 0,
    };
  },

  async mounted() {
    await this.getBlueprint();
  },

  methods: {
    embedFile({ ref, url }) {
      this.embedSrc[ref] = url;
      this.keyEmbedFile += 1;
      this.isEditing = true;
    },

    readedData({ ref, data }) {
      this.imageData[ref] = data;
    },

    async getBlueprint(activeLoad = true) {
      activeLoad
        ? await this.$vs.loading({
            container: this.loaderContainer,
            scale: 0.6,
          })
        : 0;
      try {
        const dto = this.getDto();
        this.blueprint = await this.$http.post("get-blueprint", dto);
        if (this.blueprint) {
          this.previewImg = {
            url: this.blueprint.arquivo_url,
            edited_url: this.blueprint.arquivo_editado_url,
          };
        }
      } catch (err) {
        console.log(err);
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        activeLoad
          ? await this.$vs.loading.close(
              `${this.loaderContainer} > .con-vs-loading`
            )
          : 0;
      }
    },

    async save() {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6,
      });
      try {
        const dto = this.getDto();
        dto.filesBase64 = this.imageData;
        await this.$http.post("save-blueprint", dto);
        await this.getBlueprint(false);
        this.cancelEditing();
        this.reRenderFileInput++;
        this.$vs.notify({
          title: "Sucesso!",
          text: "Planta salva com sucesso",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
      } catch (err) {
        console.log(err);
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    },

    getDto() {
      return {
        id: this.blueprint ? this.blueprint.id : null,
        id_cliente: this.client.id,
        id_contrato: this.contract.id,
        id_endereco: this.address.id_endereco,
        filesBase64: null,
      };
    },

    cancelEditing() {
      this.isEditing = false;
      this.embedSrc = {};
      this.imageData = {};
    },
  },
};
</script>

<style scoped>
.pdf-embed {
  height: 100%;
  width: 100%;
}
</style>