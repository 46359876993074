var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.result)?_c('vx-card',[_c('div',{staticClass:"w-full vs-con-loading__container",attrs:{"id":"faturamento"}},[_c('div',[_c('vs-row',{staticClass:"mb-3",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"4","vs-type":"flex","vs-justify":"flex-start","vs-align":"flex-start"}},[_c('h5',{staticStyle:{"color":"black"}})]),_c('vs-col',{attrs:{"vs-w":"4","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('h5',{staticClass:"font-semibold",style:(_vm.result.validation ? 'color: black' : 'color:red')},[_vm._v(" "+_vm._s(_vm.result.validation == false ? "Não é Possivel Faturar o Contrato" : "Todos os campos preenchidos")+" ")])]),_c('vs-col',{attrs:{"vs-w":"4","vs-type":"flex","vs-justify":"flex-end","vs-align":"flex-end"}},[_c('menuFaturamento',{attrs:{"result":_vm.result,"contrato":_vm.contrato},on:{"update":_vm.update}})],1)],1),_c('div',{staticClass:"px-5"},[_c('vs-row',{staticClass:"mb-5",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"12","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.result.validation == false ? "Verifique os campos obrigatórios e tente novamente" : "Contrato pronto para ser faturado")+" ")])])],1),_c('vx-card',{staticClass:"mb-3",attrs:{"no-shadow":"","card-border":""}},[_c('vs-row',{staticClass:"mt-3",attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center"}},[_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.tecnicos_iniciais == 0 ||
                  _vm.result.tecnicos_iniciais == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v(" Técnicos Iniciais ")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.horas_iniciais == 0 || _vm.result.horas_iniciais == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Horas Iniciais")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.tecnicos_manutencao == 0 ||
                  _vm.result.tecnicos_manutencao == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Técnicos Manutenção")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.horas_manutencao == 0 ||
                  _vm.result.horas_manutencao == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Horas Manutenção")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.data_vencimento == 0 ||
                  _vm.result.data_vencimento == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Vencimento")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.data_contrato == 0 || _vm.result.data_contrato == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Data Contrato")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.id_colaborador == 0 || _vm.result.id_colaborador == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Vendedor")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.id_controle == 0 || _vm.result.id_controle == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Controle")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.aplicacoes == 0 || _vm.result.aplicacoes == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Aplicações")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.periodo_manutencao == 0 ||
                  _vm.result.periodo_manutencao == null
                    ? false
                    : true,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Dias entre Manutenções")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":(_vm.result.entrada > 0 &&
                    _vm.result.id_forma_pagamento_entrada > 0) ||
                  (_vm.result.parcelas > 0 &&
                    _vm.result.id_forma_pagamento_parcela > 0 &&
                    _vm.result.intervalo_parcela > 0)
                    ? true
                    : false,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Forma de Pagamento")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.faturamento > 0 ? true : false,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Endereco de faturamento")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.nome_cliente,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v(_vm._s(_vm.result.tipo_pessoa == 1 ? "Cliente (Nome Fantasia)" : "Cliente (Razão Social)")+" ")])])],1),_c('vs-col',{staticClass:"my-2",attrs:{"vs-w":"3","vs-align":"center","vs-justify":"flex-start","vs-type":"flex"}},[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.result.cpf_cnpj,"disabled":"true"}},[_c('span',{staticClass:"font-bold",staticStyle:{"font-size":"12.5px"}},[_vm._v("Cliente (CPF ou CNPJ) ")])])],1)],1)],1)],1)],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }