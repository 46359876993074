<template>
  <div>
    <vs-popup
      class=""
      :title="diaSemanaNome + ', ' + dia + ' de ' + mes"
      :active.sync="forcaModal"
    >
      <div
        style="min-height: 75vh"
        id="head_forca"
        class="vs-con-loading__container"
      >
        <VuePerfectScrollbar class="scroll-modal-forca" :settings="settings">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <div
              v-if="forcaDisponivel"
              style="display: inline-block"
              class="w-full"
            >
              <div v-if="forcaDisponivel != 'feriado'">
                <div v-if="diaSemana == 6 || diaSemana == 0">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <span style="color: #c96b00" class="font-semibold my-5">
                      Este dia cai no fim de semana
                    </span>
                  </vs-col>
                  <vs-divider class="my-0" border-style=""></vs-divider>
                </div>
                <vs-collapse type="" accordion class="my-0 py-0">
                  <span :key="index" v-for="(data, index) in forcaDisponivel">
                    <vs-collapse-item
                      icon-arrow-down
                      style="background-color: #f2f2f2; border-radius: 4px"
                      class="my-1"
                    >
                      <div slot="header">
                        <div class="mx-0 my-0 py-0">
                          <vs-row vs-type="flex" vs-w="12">
                            <div>
                              <strong
                                ><span style="font-size: 16px">{{
                                  data.nome
                                }}</span
                                >:</strong
                              >
                              <br />
                              <span
                                style="font-size: 14px; color: green"
                                v-if="data.status == 1"
                              >
                                {{ data.forca + " horas manutenção" }}
                              </span>
                              <span style="font-size: 14px; color: red" v-else>
                                {{ data.forca + " " + data.status }}
                              </span>
                              <!-- <span style="font-size: 14px; color: #f44336" v-if="data.status == 2">
                                                            {{data.forca + ' horas manutenção'}}
                                                        </span>
                                                        <span style="font-size: 14px; color: #C0564B" v-if="data.status == 0">
                                                            {{'Forca manutenção esgotada'}}
                                                        </span>  -->
                              ||
                              <span
                                style="font-size: 14px; color: green"
                                v-if="data.statusInicial == 1"
                              >
                                {{ data.forcaInicial + " horas iniciais" }}
                              </span>
                              <span style="font-size: 14px; color: red" v-else>
                                {{
                                  data.forcaInicial + " " + data.statusInicial
                                }}
                              </span>
                              <!-- <span style="font-size: 14px; color: #f44336" v-if="data.statusInicial == 2">
                                                            {{data.forcaInicial + ' horas iniciais'}}
                                                        </span>
                                                        <span style="font-size: 14px; color: #C0564B" v-if="data.statusInicial == 0">
                                                            {{'Forca inicial esgotada'}}
                                                        </span> -->
                              ||
                              <span
                                style="font-size: 14px; color: green"
                                v-if="data.statusCarros == 1"
                              >
                                {{ data.carros + " carros dispon." }}
                              </span>
                              <span style="font-size: 14px; color: red" v-else>
                                {{ data.carros + " " + data.statusCarros }}
                              </span>
                            </div>
                          </vs-row>
                        </div>
                      </div>
                      <span :key="index2" v-for="(data2, index2) in data.horas">
                        <vs-col vs-w="12" class="my-2">
                          <h6>
                            <strong>
                              <span style="font-size: 18px"
                                >as {{ data2.hora }}h</span
                              >:
                              <br />
                            </strong>
                            <div>
                              <vs-col vs-w="5">
                                <span v-if="data.status == 1">
                                  <span
                                    class="mr-2"
                                    style="font-size: 14px; color: green"
                                    v-if="data2.status == 1"
                                  >
                                    <span style="font-size: 16px">
                                      <b>
                                        {{
                                          data.forca >= data2.forca
                                            ? data2.forca
                                            : data.forca
                                        }}
                                      </b></span
                                    >
                                    hrs manutenção
                                  </span>
                                  <span
                                    style="font-size: 14px; color: red"
                                    v-else
                                  >
                                    <span style="font-size: 16px">
                                      <b>
                                        {{ data2.forca }}
                                      </b></span
                                    >
                                    {{ data2.status }}
                                  </span>
                                </span>
                                <span v-else>
                                  *<span style="font-size: 12px; color: red">
                                    {{ data.status }}
                                  </span>
                                </span>
                                <!-- <span style="font-size: 14px; color: #f44336" v-if="data2.status == 2">
                                                                <span style="font-size: 16px;"><b>{{data2.forca * -1}}</b></span> horas manutenção acima
                                                            </span>
                                                            <span style="font-size: 14px; color: #C0564B" v-if="data2.status == 0">
                                                                {{'esgotado'}}
                                                            </span> -->
                              </vs-col>
                              <vs-col vs-w="4">
                                <span v-if="data.statusInicial == 1">
                                  <span
                                    class="mr-2"
                                    style="font-size: 14px; color: green"
                                    v-if="data2.statusInicial == 1"
                                  >
                                    <span style="font-size: 16px">
                                      <b>
                                        {{
                                          data.forcaInicial >=
                                          data2.forcaInicial
                                            ? data2.forcaInicial
                                            : data.forcaInicial
                                        }}
                                      </b></span
                                    >
                                    horas iniciais
                                  </span>
                                  <span
                                    style="font-size: 14px; color: red"
                                    v-else
                                  >
                                    <span style="font-size: 16px">
                                      <b>
                                        {{ data2.forcaInicial }}
                                      </b></span
                                    >
                                    {{ data2.statusInicial }}
                                  </span>
                                </span>
                                <span v-else>
                                  *<span style="font-size: 12px; color: red">
                                    {{ data.statusInicial }}
                                  </span>
                                </span>
                                <!-- <span style="font-size: 14px; color: #f44336" v-if="data2.statusInicial == 2">
                                                                <span style="font-size: 16px;"><b>{{data2.forca * -1}}</b></span> horas iniciais acima
                                                            </span>
                                                            <span style="font-size: 14px; color: #C0564B" v-if="data2.statusInicial == 0">
                                                                {{'esgotado'}}
                                                            </span> -->
                              </vs-col>
                              <vs-col vs-w="3">
                                ̣<span v-if="data.statusCarros == 1">
                                  <span
                                    class=""
                                    style="font-size: 14px; color: green"
                                    v-if="data2.statusCarros == 1"
                                  >
                                    <span style="font-size: 16px">
                                      <b>
                                        {{
                                          data.carros >= data2.carros
                                            ? data2.carros
                                            : data.carros
                                        }}
                                      </b></span
                                    >
                                    carros
                                  </span>
                                  <span
                                    style="font-size: 14px; color: red"
                                    v-else
                                  >
                                    <span style="font-size: 16px">
                                      <b>
                                        {{ data2.carros }}
                                      </b></span
                                    >
                                    {{ data2.statusCarros }}
                                  </span>
                                </span>
                                <span v-else>
                                  *<span style="font-size: 12px; color: red">
                                    {{ data.statusCarros }}
                                  </span>
                                </span>
                                <!-- <span style="font-size: 14px; color: #f44336" v-if="data2.statusInicial == 2">
                                                                <span style="font-size: 16px;"><b>{{data2.forca * -1}}</b></span> horas iniciais acima
                                                            </span>
                                                            <span style="font-size: 14px; color: #C0564B" v-if="data2.statusInicial == 0">
                                                                {{'esgotado'}}
                                                            </span> -->
                              </vs-col>
                            </div>
                          </h6>
                        </vs-col>
                      </span>
                    </vs-collapse-item>
                  </span>
                </vs-collapse>
              </div>
              <div class="con-colors my-5" v-else>
                <ul>
                  <span
                    class="px-3 pt-2"
                    style="color: grey !important; font-weight: 1000 !important"
                    ><vs-icon
                      size="large"
                      icon="sentiment_dissatisfied"
                    ></vs-icon
                  ></span>
                </ul>
                <vs-col
                  vs-w="12"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                >
                  <span style="color: red" class="font-semibold my-5">
                    Este dia é feriado!
                  </span>
                </vs-col>
              </div>
            </div>
          </vs-col>
        </VuePerfectScrollbar>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    data: null,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
        height: 1000,
      },
      dias: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      meses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      forcaModal: false,
      forcaDisponivel: null,
      dia: null,
      mes: null,
      diaSemanaNome: null,
      diaSemana: null,
    };
  },
  methods: {
    close() {
      this.forcaModal = false;
    },
    async getDates() {
      let data = new Date(this.data);
      this.diaSemanaNome = this.dias[data.getDay()];
      this.diaSemana = data.getDay();
      this.dia = data.getDate();
      this.mes = this.meses[data.getMonth()];
    },
    async getForcaDisponivel() {
      try {
        this.forcaDisponivel = await this.$http.post("getForcaDisponivel", {
          data_competencia: this.data,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getHorasDisponiveis() {
      await this.forcaDisponivel.forEach(async (turno) => {
        let total = 0;
        await turno.horas.forEach(async (hora) => {
          if (hora.status == true) {
            await total++;
          }
        });
        turno.horasDisponiveis = total;
      });
    },
  },
  async mounted() {
    await this.getDates();
    await this.$vs.loading({ container: "#head_forca", scale: 0.6 });
    this.forcaModal = await true;
    await this.getForcaDisponivel();
    //await this.getHorasDisponiveis()
    await this.$vs.loading.close("#head_forca > .con-vs-loading");
  },
  components: { ...components },
};
</script>
<style >
.scroll-modal-forca {
  height: 75vh;
}
</style>
