<template>
  <div>
    <vs-button
      class="px-0 py-0 m-0"
      icon="remove_red_eye"
      color="primary"
      @click="openModal"
      size="small"
      type="filled"
      style="color: black"
    ></vs-button>

    <vs-popup :title="'SERVIÇO  N° ' + os.id" :active.sync="osModalNaoExec">
      <vs-row vs-type="flex" vs-align="center" id="remarcar" class="vs-con-loading__container">
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-3">
          <vs-image style="width: 300px" :src="osNaoExec.foto" />
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-3">
          <div class="w-full p-0">
            <h6>{{ osNaoExec.frase }} ({{ osNaoExec.data }})</h6>
            <span>{{ osNaoExec.obs }}</span>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    os: {
      required: true
    }
  },
  data() {
    return {
      osModalNaoExec: false,
      osNaoExec: {}
    };
  },
  methods: {
    async openModal() {
      this.osModalNaoExec = true;
      await this.getNaoExecData();
    },
    async getNaoExecData() {
      try {
        this.osNaoExec = await this.$http.post("/getNaoExecDataRetorno", {
          id_retorno: this.os.id_retorno
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async close() {
      this.osModalNaoExec = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.os {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #f44336;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
