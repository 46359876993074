<template>
  <div>
    <vs-popup
      :background-color-popup="colorx"
      title="RESUMO DA IMPRESSÃO"
      :active.sync="resumoModal"
      class="vs-con-loading__container"
      id="loading"
    >
      <div class="w-full">
        <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <h5 style="color: #ffca00">
              {{ contrato.estagio }}
              {{
                contrato.renovacao > 0 && contrato.id_relacional > 0
                  ? " de Renovação "
                  : ""
              }}
              {{ contrato.tipo_faturamento }} Nº. {{ contrato.id }}
            </h5>
          </vs-col>
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <VuePerfectScrollbar class="scroll-pdf" :settings="settings">
              <resumoCard
                :cliente="cliente"
                :enderecos="enderecos"
                :areas="areas"
                :controle="controle"
                :pagamento="pagamento"
                :plano="contrato"
                :contrato="contrato"
                :vendedor="vendedor"
                :orcamentista="orcamentista"
                :servicosEndereco="servicosEndereco"
                :clausulas="clausulas"
              ></resumoCard>
            </VuePerfectScrollbar>
          </vs-col>

          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              class="mt-5 mx-3"
              color="danger"
              type="relief"
              @click="gerarPdf()"
              >IMPRIMIR PDF</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>

    <vue-html2pdf
      :show-layout="false"
      :preview-modal="false"
      :paginate-elements-by-height="12000"
      :filename="filename"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      @hasStartedDownload="hasStartedDownload()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <component
          v-bind:is="comp"
          :key="reRenderPdf"
          v-if="
            contrato.id_estagio == 2 &&
            contrato.controle.toUpperCase().trim().search('SANITIZ') == -1
          "
          :id_segmento="contrato.id_segmento"
        />
        <component
          v-bind:is="pdf"
          :key="reRenderPdf"
          :vendedor="vendedor"
          :orcamentista="orcamentista"
          :cliente="cliente"
          :enderecos="enderecos"
          :areas="areas"
          :controle="controle"
          :pagamento="pagamento"
          :contrato="contrato"
          :plano="plano"
          :estagio="contrato.estagio"
          :servicosEndereco="servicosEndereco"
          :clausulas="clausulas"
          :empresa="empresa"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import capa from "./components/capas/capa.vue";
import VueHtml2pdf from "vue-html2pdf";
import printDoc from "./components/printDoc";
import resumoCard from "./components/resumoCard";
import components from "@/components/default/exports.js";
export default {
  props: {
    cliente: {
      require: true,
    },
    contrato: {
      require: true,
    },
    empresa: null,
    pagamento: null,
  },
  data() {
    return {
      comp: "capa",
      enderecos: {},
      areas: {},
      controle: {},
      filename: "doc",
      plano: {},
      colorx: "#030e1d",
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],
      servicosEndereco: [],
      clausulas: [],
      pdf: null,
      reRenderPdf: 0,
      visualizarPDF: false,
      resumoModal: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      vendedor: {},
      orcamentista: {},
    };
  },
  components: {
    ...components,
    VueHtml2pdf,
    printDoc,
    resumoCard,
    capa,
  },
  methods: {
    async gerarPdf() {
      await this.$refs.html2Pdf.generatePdf();
    },
    async hasStartedDownload() {
      await this.$vs.loading({ container: "#loading", scale: 0.6 });
    },
    async hasGenerated(event) {
      event;
      await this.$vs.loading.close("#loading > .con-vs-loading");
    },

    async getCronograma() {
      try {
        this.enderecos = await this.$http.post("getEnderecosParaAssociar/", {
          id_cliente: this.contrato.id_cliente,
          id_contrato: this.contrato.id,
        });
        for (let i in this.enderecos) {
          await this.servicosEndereco.push(
            await this.getServicosEndereco(this.enderecos[i].id_endereco)
          );
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getServicosEndereco(id_endereco) {
      try {
        const res = await this.$http.post("getServicosEndereco", {
          id_endereco: id_endereco,
          id_contrato: this.contrato.id,
        });
        let servicos = res.servicos;
        servicos.forEach(async (servico) => {
          if (servico.id_turno > 0) {
            servico.nomeTurno = await this.turnos[servico.id_turno].nome;
          }
        });
        return await servicos;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getControle() {
      try {
        this.controle = await this.$http.get(
          "controle/" + this.contrato.id_controle
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getAreas() {
      try {
        this.areas = await this.$http.get(
          "getAreasSelecionadas/" + this.contrato.id
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getClausulas() {
      try {
        this.clausulas = await this.$http.post(`getClausulasControles`, {
          id_controle: this.contrato.id_controle,
          id_tipo_faturamento: this.contrato.id_tipo_faturamento,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getVendedor() {
      try {
        this.vendedor = await this.$http.get(
          `colaborador/` + this.contrato.id_colaborador
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getOrcamentista() {
      try {
        this.orcamentista = await this.$http.get(
          `colaborador/` + this.contrato.id_orcamentista
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async configPdf() {
      this.filename = this.contrato.estagio + "-" + this.contrato.id;
      this.plano = this.contrato;
      this.pdf = await "printDoc";
      this.reRenderPdf += await 1;
      this.resumoModal = await true;
    },
  },
  async mounted() {
    console.log(this.contrato);
    console.log(
      this.contrato.id_estagio == 2 &&
        this.contrato.controle.toUpperCase().trim().search("SANITIZ") == -1
    );
    this.$vs.loading();
    await this.getClausulas();
    await this.getCronograma();
    await this.getControle();
    await this.getVendedor();
    await this.getOrcamentista();
    await this.getAreas();
    await this.configPdf();
    this.$vs.loading.close();
  },
};
</script>
<style lang="css">
.vs-popup--content {
  overflow: hidden;
}
.scroll-pdf {
  max-height: 65vh !important;
}
</style>
