<template>
  <div class="w-full">
    <vs-popup :title="'Editar Informações do Serviço'" :active.sync="modalOs">
      <VuePerfectScrollbar class="scroll-editar-obs" :settings="settings">
        <div class="w-full vs-con-loading__container" id="obsServico">
          <vs-row
            vs-type="flex"
            class="mb-3"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <div class="px-3">
                <vs-checkbox color="success" v-model="osSelecionado.fog" class
                  >FOG</vs-checkbox
                >
              </div>
              <div class="px-3">
                <vs-checkbox
                  color="success"
                  v-model="osSelecionado.escada"
                  class
                  >ESCADA</vs-checkbox
                >
              </div>
              <div class="px-3">
                <vs-input-number
                  color="success"
                  v-validate="'required|numeric'"
                  label="Carros."
                  min="0"
                  :disabled="true"
                  v-model="osSelecionado.carros"
                  name="carros"
                  @change="update()"
                />
              </div>
              <div class="px-3">
                <span style="font-size: 12px">
                  Confirmacao:
                  <b>{{ osSelecionado.pessoa_confirmacao }}</b>
                </span>
              </div>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            class="mt-1 mb-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="12"
            >
              <vs-textarea
                color="dark"
                class="mx-1 w-fulll"
                type="text"
                height="115"
                label="Observação"
                v-model="osSelecionado.obs"
              ></vs-textarea>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            class="mt-1 mb-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="12"
            >
              <vs-textarea
                color="dark"
                class="mx-1 w-fulll"
                style="text-align: justify !important"
                height="115"
                type="text"
                label="Observação Próximo Serviço"
                v-model="osSelecionado.obs_proximo_servico"
              ></vs-textarea>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            class="mt-1 mb-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="12"
            >
              <vs-textarea
                color="dark"
                class="mx-1 w-fulll"
                style="text-align: justify !important"
                height="115"
                type="text"
                label="Observação Interna"
                v-model="osSelecionado.obs_interna"
              ></vs-textarea>
            </vs-col>
          </vs-row>
          <vs-button
            class="w-full mt-3"
            color="warning"
            icon="edit"
            size="sm"
            type="relief"
            @click="editarObs()"
            >Editar</vs-button
          >
        </div>
      </VuePerfectScrollbar>
    </vs-popup>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    osSelecionado: {},
    contrato: null
  },
  data () {
    return {
      modalOs: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1500
      }
    }
  },
  methods: {
    async openModal () {
      this.modalOs = true
    },
    async editarObs () {
      await this.$vs.loading({ container: "#obsServico", scale: 0.6 })
      try {
        let obj = {
          obs: this.osSelecionado.obs,
          obs_proximo_servico: this.osSelecionado.obs_proximo_servico,
          obs_interna: this.osSelecionado.obs_interna,
          escada: this.osSelecionado.escada,
          fog: this.osSelecionado.fog
        }
        await this.$http.put(`os/${this.osSelecionado.id}`, obj)
        await this.$vs.notify(this.$notify.Success)
        await this.$emit("emitirUpdate")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.modalOs = await false
        await this.$vs.loading.close("#obsServico > .con-vs-loading")
      }
    }
  },
  async mounted () {
    await this.openModal()
  },
  components: {
    ...components
  }
};
</script>

<style>
#status {
  border-radius: 20px !important;
}
.vs-input-success #status {
  border: 1px solid rgba(var(--vs-success), 1) !important;
}
.vs-input-danger #status {
  border: 1px solid rgba(var(--vs-danger), 1) !important;
}
.scroll-editar-obs {
  max-height: 80vh !important;
}
</style>
